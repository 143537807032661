import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';

@Component({
  selector: 'app-app-status',
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.scss']
})
export class AppStatusComponent implements OnInit{

  updateReady: boolean=false; 
  message:string = "";
  isEnabled:boolean = this.swUpdate.isEnabled; 

  constructor(private swUpdate:SwUpdate){}

  ngOnInit(){
    this.swUpdate.versionUpdates.subscribe({
      next: (e:VersionEvent) => {
        switch(e.type){
          case 'VERSION_DETECTED': this.message = "DETEKOVANA nova verzia"; break; 
          case 'VERSION_INSTALLATION_FAILED': this.message = "ZLYHALO instalovanie nova verzia"; break; 
          case 'VERSION_READY': this.message = "PRIPRAVENA nova verzia"; this.updateReady=true; break; 
          case 'NO_NEW_VERSION_DETECTED': this.message = "NIEJE  nova verzia"; break; 
        }
      }
    })
  }

  async check(){
    // this.swUpdate.checkForUpdate().then( x => this.updateReady = x)
    this.updateReady = await this.swUpdate.checkForUpdate();

  }

  reload(){
    document.location.reload();
  }

}
