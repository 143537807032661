<title>Your Plans | MyEnergy</title>
<div class="row">
  <div class="col-12 mt-4">
    <h3>Your Plans</h3>
  </div>
</div>

<div class="text-center mt-3" [hidden]="loadingFinished">
  <div class="spinner-border text-info" role="status"></div>
</div>

<div
  *ngFor="let plan of planList; index as planIndex"
  class="card bg-light mt-3"
>
  <div class="card-header" id="{{ plan.id }}">
    <div class="row">
      <div class="col-10 mt-1">
        <h5>{{ plan.name }}</h5>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary col"
          (click)="prefillOrder(plan.id)"
        >
          Prefill Order
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <div class="card-text">
        <div class="row mt-2">
          <div class="col-6">
            <p class="card-title">{{ plan.description }}</p>
          </div>
          <div class="col-4 text-right">
            <h6>
              Price for next 14 days:
              {{ planForecastPrices[planIndex] | number }} €
            </h6>
          </div>
          <div class="col-2">
            <p>Updated: {{ plan.updated_at }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
