<title>Order List | MyEnergy</title>

<div class="pageTemplate">
  <div class="row my-4">
    <div class="col">
      <h2 class="text-center my-4">Your Orders</h2>

      <div class="row mb-3">
        <div class="col text-left">
          <span class="mr-3">Range from today: </span>
          <select
            class="form-select"
            [(ngModel)]="listSize"
            (change)="initializeView()"
          >
            <option selected [ngValue]="7">One Week</option>
            <option [ngValue]="14">Two Weeks</option>
            <option [ngValue]="31">One Month</option>
            <option [ngValue]="62">Two Months</option>
          </select>
        </div>
        <div class="col text-right">
          <button
            type="button"
            class="btn btn-primary"
            (click)="exportToFile()"
          >
            Export to CSV file
          </button>
        </div>
      </div>

      <div class="text-center mt-3" [hidden]="loadingFinished">
        <div class="spinner-border text-info" role="status"></div>
      </div>

      <table
        class="table table-hover table-bordered"
        [hidden]="!loadingFinished"
      >
        <thead class="thead-light">
          <tr style="text-align: center">
            <th colspan="2" class="table-header">Day</th>
            <th class="table-header">Month</th>
            <th class="table-header">Year</th>
            <th class="table-header">Starting Hour</th>
            <th class="table-header">MWh</th>
            <th class="table-header">(kWh)</th>
            <th colspan="2" class="table-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="text-align: center"
            *ngFor="let order of filteredOrderList; index as orderIndex"
          >
            <!-- <td [ngStyle]="{'color': dateList[orderIndex].getHours() === 0? '#000000' : '#B4B4B3'}">{{ dateList[orderIndex].getDate() }}</td>
          <td [ngStyle]="{'color': dateList[orderIndex].getHours() === 0? '#000000' : '#B4B4B3'}">{{ daysofWeek[dateList[orderIndex].getDay()] }}</td>
          <td [ngStyle]="{'color': dateList[orderIndex].getHours() === 0? '#000000' : '#B4B4B3'}">{{ monthNames[dateList[orderIndex].getMonth()] }}</td>
          <td [ngStyle]="{'color': dateList[orderIndex].getHours()=== 0? '#000000' : '#B4B4B3'}">{{ dateList[orderIndex].getFullYear() }}</td> -->
            <td style="border-top: 3px black">
              {{ dateList[orderIndex].getDate() }}
            </td>
            <td>{{ daysofWeek[dateList[orderIndex].getDay()] }}</td>
            <td>{{ monthNames[dateList[orderIndex].getMonth()] }}</td>
            <td>{{ dateList[orderIndex].getFullYear() }}</td>
            <td>{{ dailyHours[dateList[orderIndex].getHours()] }}</td>
            <td colspan="6" [hidden]="order" style="color: #b4b4b3">
              No Order made
            </td>
            <td [hidden]="!order">{{ order?.consumption! / 1000.0 }}</td>
            <td [hidden]="!order">({{ order?.consumption! }})</td>
            <td [hidden]="!order">
              <button
                type="button"
                class="btn btn-warning col p-0 m-0"
                (click)="editOrder(order?.id!)"
              >
                Edit
              </button>
            </td>
            <td [hidden]="!order">
              <button
                type="button"
                class="btn btn-danger col p-0 m-0"
                (click)="deleteOrder(order?.id!)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
