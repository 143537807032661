import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './user';
import { UserToken } from './user-token';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userToken: UserToken | undefined;
  isLoggenIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  baseUrl: string = environment.baseUrlAPI;
  loginUrl: string = this.baseUrl + '/login';
  masterUserRegisterUrl: string = this.baseUrl + '/registrations';
  checkEmailMasterClientURL :string = this.baseUrl + '/registrations/check-email'
  usersUrl: string = this.baseUrl + '/users';
  clientsUrl: string = this.baseUrl + '/clients';
  checkPendingUserURl: string = this.baseUrl + '/users/check-pending-user';
  updatePendingUserURl: string = this.baseUrl + '/users/update-pending-user';
  isTokenValid: string = this.baseUrl + '/users/isTokenValid';
  registered: any = null;

  forgotPasswordURL:string=this.baseUrl+'/users/forgot-password';
  resetPasswordURL:string=this.baseUrl+'/users/reset-password'


  constructor(private hc: HttpClient) {
    let userTokenString = localStorage.getItem('userToken');

    // set isLoggenIn only if token is not null and is not empty and is not undefined
    if (
      userTokenString != null &&
      userTokenString != '' &&
      userTokenString != undefined
    ) {
      this.userToken = JSON.parse(userTokenString);
      this.isLoggenIn.next(true);
    }

    let registered = localStorage.getItem('registered');

    if (
      registered != null &&
      registered != '' &&
      registered != undefined
    ) {
      this.registered = JSON.parse(registered);
    }
  }

  // Login na URL
  login(user: User): Observable<UserToken> {
    //console.log('UserService: ', user);
    return this.hc.post<UserToken>(this.loginUrl, user).pipe(
      tap((userToken: UserToken) => {
        if (user)
          if (
            userToken != null &&
            userToken.token != '' &&
            userToken.token != undefined
          ) {
            // set isLoggenIn only if token is not null and is not empty and is not undefined
            localStorage.setItem('userToken', JSON.stringify(userToken));
            this.userToken = userToken;
            this.isLoggenIn.next(true);
          }
      })
    );
  }

  masterUserRegistration(user: any): Observable<any> {
    return this.hc.post(this.masterUserRegisterUrl, user);
  }
  forgotPassword(user: any): Observable<any> {
    let payload={
      email: user.login
    }
    return this.hc.post(this.forgotPasswordURL, payload);
  }


  resetPassword(payload:any): Observable<any> {
    return this.hc.post(this.resetPasswordURL, payload);
  }


  checkEmailMasterClient(user: any): Observable<any> {
    return this.hc.post(this.checkEmailMasterClientURL, user);
  }
  addUser(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': '111222',
    });
    return this.hc.post(this.usersUrl, payload, { headers });
  }
  addClients(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': '111222',
    });
    return this.hc.post(this.clientsUrl, payload, { headers });
  }
  checkPendingUser(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': payload.token,
    });
    return this.hc.post(this.checkPendingUserURl, payload, { headers });
  }
  updatePendingUser(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': payload.token,
    });
    return this.hc.post(this.updatePendingUserURl, payload, { headers });
  }
  validateToken(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': payload.token,
    });
    return this.hc.get(this.isTokenValid, { headers });
  }
  // updatePassword(user: any): Observable<any> {
  //   console.log('UserService: ', user);
  //   return this.hc.put(this.updatePasswordUrl, user);
  // }

  // login(user: User): Observable<UserToken> {
  //   const prihlasovaciToken: string = 'PrihlasovaciToken';
  //   console.log('UserService: prihlasuje sa uzivatel !!: ', user);
  //   if (
  //     !(
  //       user.login == 'lofaj' ||
  //       user.login == 'belej' ||
  //       user.login == 'straka' ||
  //       user.login == 'visitor'
  //     )
  //   ) {
  //     return throwError('Invalid login');
  //   }
  //   const ut: UserToken = {
  //     login: user.login, token: prihlasovaciToken,
  //     "id": -1,
  //     "client_id": -1,
  //     "email": "",
  //     "password": "",
  //     "fullName": "",
  //     "role": "",
  //     "lastLogin": "",
  //     "created_at": "",
  //     "updated_at": ""
  //   };
  //   localStorage.setItem('userToken', JSON.stringify(ut));
  //   this.userToken = ut;
  //   this.isLoggenIn.next(true);
  //   return new BehaviorSubject<UserToken>(ut);
  // }

  getRegistered(): any {
    this.registered = null;

    let registered = localStorage.getItem('registered');
    if (registered) {
      this.registered = JSON.parse(registered);
    }

    return this.registered;
  }

  logout() {
    window.localStorage.removeItem('userToken');
    this.userToken = undefined;
    this.isLoggenIn.next(false);
  }
}
