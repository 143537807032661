import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitLocationsService {
  baseUrl: string = environment.baseUrlAPI;
  businessUnits :any = this.baseUrl+'/clientbusinessunits'
  locations :any = this.baseUrl+'/clientlocations'
  loggedMasterUser:any

  constructor(private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
   }
   getBusinessUnits(): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.businessUnits}/client/${this.loggedMasterUser.client_id}`, { headers });
   }
   addBusinessUnits(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(`${this.businessUnits}`,payload, { headers });
   }
   updateBusinessUnits(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.put(`${this.businessUnits}/${payload.id}`,payload, { headers });
   }
   deleteBusinessUnits(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.delete(`${this.businessUnits}/${payload.id}`, { headers });
   }

   getLocations(): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.locations}/client/${this.loggedMasterUser.client_id}`, { headers });
   }
   addLocation(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(`${this.locations}`,payload, { headers });
   }
   updateLocation(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.put(`${this.locations}/${payload.id}`,payload, { headers });
   }
   deleteLocation(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.delete(`${this.locations}/${payload.id}`, { headers });
   }
}
