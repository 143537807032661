import { Component, ViewChild } from '@angular/core';
import { User } from '../../user';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/user/user.service';
import { Client } from '../../client';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from './../../../translation.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @ViewChild('form') form: FormGroup;
  userList: User[];
  newUser: User;
  accessRights: any = [];
  emailPattern = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  newUserMenuOpen: boolean = false;

  clientList: Client[] = [];
  userDetails: any = {
    firstName: 'Test',
    lastName: 'Test',
    email: 'Test@gmail.com',
    userRole: 'User',
  };
  MasterClientUser: any;
  loadingFinished: boolean = true;

  loggedMasterUser: any;
  options = ['Option 1', 'Option 2', 'Option 3'];
  selectControl = new FormControl();
  clientData:any
  emailError: string = '';
  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private translationService: TranslationService
  ) {
    //this.setLang();
  }

  setLang(){
    let selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";
    this.translate.use(selectedLang);
  }

  ngOnInit() {
    let user_id = this.route.snapshot.paramMap.get('id');
    //this.setLang();
    this.reset();
    this.loggedMasterUser = localStorage.getItem('userToken');
    
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      //console.log(this.loggedMasterUser.id);
      //console.log(this.loggedMasterUser.client_id);
      //console.log(user_id);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data)=>{
        this.clientData=data;
        //console.log("Client data:", this.clientData);
      })
    }

    if ( user_id ){
      this.adminService.getUser(parseInt(user_id)).subscribe((data)=>{
        this.userDetails=data;
        //console.log("User data:", this.userDetails);
      })
    }

    this.initializeView();
  }

  getDisplayText() {
    if (!this.selectControl.value) {
      return '';
    } else if (this.selectControl.value.length > 5) {
      return 'more than 5';
    } else if (this.selectControl.value.length > 1) {
      return 'multiple select names';
    } else if (this.selectControl.value.length === this.options.length) {
      return 'all';
    } else {
      return this.selectControl.value.join(', ');
    }
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  selectAll(selected: boolean) {
    if (selected) {
      this.selectControl.setValue(this.options);
    } else {
      this.selectControl.setValue([]);
    }
  }

  initializeView() {
    this.loadingFinished = false;
    this.adminService.getUsers().subscribe({
      next: (data) => {
        if (this.loggedMasterUser.role == 'master user') {
          data = data.filter(
            (user: any) => user.id === this.loggedMasterUser.id
          );
          this.MasterClientUser = data[0];
          this.loadingFinished = true;
        }
      },
    });
  }

  validateEmail() {
    const emailInput = this.userDetails.email;
    if (!emailInput) {
      this.emailError = '*Email is required';
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(emailInput)) {
        this.emailError = '*Please enter a valid email address';
      } else {
        this.emailError = ''; // Reset error message if email is valid
      }
    }
  }

  reset() {
    this.accessRights = [
      {
        sourceTable: 'Client',
        attribute: ['Region'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['SK', 'HU', 'CZ'],
      },
      {
        sourceTable: 'Client plan',
        attribute: ['TELED'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Montel data',
        attribute: ['Region'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['SK', 'HU', 'CZ'],
      },
      {
        sourceTable: 'Client consumption plan',
        attribute: ['Client Plan 1'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Client consumption plan',
        attribute: ['Actual consumption'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: [],
        filterValue: [],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['Region'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['SK', 'HU', 'CZ'],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['Client Plan 1'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['TELED'],
        crud: {
          c: false,
          r: false,
          u: false,
          d: false,
        },
        filterColumn: ['TELED'],
      },
    ];
    this.userDetails = {
      firstName: '',
      lastName: '',
      email: '',
      userRole: '',
    };
  }

  toggleNewUserMenu() {
    if (this.userDetails.firstName !== '' && this.newUserMenuOpen) {
      this.accessRights = [
        {
          sourceTable: 'Client',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK, HU, CZ'],
        },
        {
          sourceTable: 'Client plan',
          attribute: ['Short Name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Short name'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Montel data',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK, HU, CZ'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['All', 'Actual consumption'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: [],
          filterValue: [],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['All', 'Region'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client short name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client short name'],
          filterValue: ['TELED'],
        },
      ];
      this.userDetails = {
        firstName: '',
        lastName: '',
        email: '',
        userRole: '',
      };
    } else if (!this.newUserMenuOpen) {
      this.newUserMenuOpen = true;

      this.accessRights = [
        {
          sourceTable: 'Client',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK, HU, CZ'],
        },
        {
          sourceTable: 'Client plan',
          attribute: ['Short Name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Short name'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Montel data',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK, HU, CZ'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['All', 'Actual consumption'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: [],
          filterValue: [],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['All', 'Region'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client short name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client short name'],
          filterValue: ['TELED'],
        },
      ];
      this.userDetails = {
        firstName: '',
        lastName: '',
        email: '',
        userRole: '',
      };
    } else {
      this.newUserMenuOpen = false;
    }
  }

  constructUserList(data: User[]) {
    this.userList = [];
    data.forEach((userData) => {
      let user: User = {
        id: userData.id,
        client_id: userData.client_id,
        login: userData.login,
        email: userData.email,
        token: userData.token,
        firstName: userData.firstName,
        lastName: userData.lastName,
        fullName: userData.fullName,
        role: userData.role,
        lastLogin: userData.lastLogin,
        created_at: userData.created_at?.split('T')[0],
        updated_at: userData.updated_at?.split('T')[0],
      };
      if (!user.created_at) {
        user.created_at = '[Unknown]';
      }
      if (!user.updated_at) {
        user.updated_at = '[Unknown]';
      }
      if (!user.role) {
        user.role = 'user';
      }
      this.userList.push(user);
    });
  }

  retrieveClientIds(data: Client[]) {
    this.clientList = [];
    data.forEach((clientData) => {
      let client: Client = {
        id: clientData.id,
        name: clientData.name,
        address: clientData.address,
        region: clientData.region,
        webpage: clientData.webpage,
        phone: clientData.phone,
        status: clientData.status,
        created_at: clientData.created_at?.split('T')[0],
        updated_at: clientData.updated_at?.split('T')[0],
      };
      if (client.created_at == undefined) {
        client.created_at = '[Unknown]';
      }
      if (client.updated_at == undefined) {
        client.updated_at = '[Unknown]';
      }
      this.clientList.push(client);
    });
  }

  createUser() {
    // let payload = {
    //   id: null,
    //   name: this.userDetails.firstName,
    //   client_id: this.loggedMasterUser.client_id,
    //   login: this.userDetails.email,
    //   email: this.userDetails.email,
    //   password: `${this.userDetails.firstName}@123`,
    //   fullName: this.userDetails.firstName+" "+this.userDetails.lastName,
    //   token: '111222',
    //   role: 'user',
    //   created_at: null,
    //   updated_at: null,
    // };
    let payload = {
      users: [
        {
          client_id: this.loggedMasterUser.client_id,
          login: this.userDetails.email,
          email: this.userDetails.email,
          fullName:
            this.userDetails.firstName + ' ' + this.userDetails.lastName,
          role: this.userDetails.userRole,
        },
      ],
      userId: this.loggedMasterUser.id,
    };
    if (!this.form.valid) {
      this.toastr.error(
        'Please correct all errors before submitting the form.',
        'Error'
      );
      return;
    }
    this.adminService.newPendingUsers(payload).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('User has been created', 'Success');
          this.reset();
          this.routeChecker('admin/users')
        }
        // this.newUserMenuOpen = false;
      },
      error: (err) => {
        this.toastr.error('An error occurred during user creation', 'Error');
      }
    });
  }

  editClient(userId: number) {
    let user = this.userList.find((user) => user.id === userId);
    // console.log('Editing plan ' + user?.fullName);
    // let pageCode = JSON.stringify(user).toString();
    // let page = window.open();
    // page?.document.write(pageCode);
    this.newUserMenuOpen = true;
    this.userDetails = {
      firstName: 'Test',
      lastName: 'Test',
      email: 'Test@gmail.com',
      userRole: 'User',
    };
    this.accessRights = [
      {
        sourceTable: 'Client',
        attribute: ['All', 'Region', 'Address'],
        crud: {
          c: true,
          r: true,
          u: false,
          d: false,
        },
        filterColumn: ['Region'],
        filterValue: ['SK', 'HU', 'CZ'],
      },
      {
        sourceTable: 'Client plan',
        attribute: ['Short Name'],
        crud: {
          c: true,
          r: true,
          u: true,
          d: true,
        },
        filterColumn: ['Short name'],
        filterValue: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Montel data',
        attribute: ['All', 'Region', 'Address'],
        crud: {
          c: false,
          r: true,
        },
        filterColumn: ['Region'],
        filterValue: ['SK', 'HU', 'CZ'],
      },
      {
        sourceTable: 'Client consumption plan',
        attribute: ['Client Plan'],
        crud: {
          c: false,
          r: true,
        },
        filterColumn: ['Client Plan'],
        filterValue: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Client consumption plan',
        attribute: ['All', 'Actual consumption'],
        crud: {
          c: false,
          r: true,
        },
        filterColumn: [],
        filterValue: [],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['All', 'Region'],
        crud: {
          c: false,
          r: true,
        },
        filterColumn: ['Region'],
        filterValue: ['SK'],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['Client Plan'],
        crud: {
          r: true,
        },
        filterColumn: ['Client Plan'],
        filterValue: ['Overhead', 'Kitchen'],
      },
      {
        sourceTable: 'Purchase Electricity',
        attribute: ['Client short name'],
        crud: {
          r: true,
        },
        filterColumn: ['Client short name'],
        filterValue: ['TELED'],
      },
    ];
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
}
