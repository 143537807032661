import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl: string = environment.baseUrlAPI;
  ordersUrl: string = this.baseUrl+'/orders';

  constructor(private hc: HttpClient) {}

  postNewOrder(data: any): Observable<any> {
    return this.hc.post<any>(this.ordersUrl, data);
  }

  getOrders(): Observable<any> {
    return this.hc.get<any>(this.ordersUrl);
  }

  getOrder(orderId: number): Observable<any> {
    return this.hc.get<any>(`${this.ordersUrl}/${orderId}`);
  }

  deleteOrder(orderId: number): Observable<any> {
    let url = `${this.ordersUrl}/${orderId}`;
    //console.log(url);
    return this.hc.delete<any>(url);
  }

}
