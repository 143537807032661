<title>MyEnergy Dashboard: Digitalization of electricity consumption</title>
<meta
  name="description"
  content="The MyEnergy Dashboard is a centralized control centre to monitor, optimize and analyze your energy consumption cost.  "
/>

<div class="row">
  <div class="col-12">
    <div class="card bg-light mt-3">
      <div class="card-header">
        <div class="row">
          <h4 class="col-10">
            {{ "registrationPage.clientDetails.title" | translate }}
          </h4>
          <div class="col text-right m-2" [hidden]="newClientMenuOpen">
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-caret-down-square"
              viewBox="0 0 16 16"
              (click)="toggleNewClientMenu()"
            >
              <path
                d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"
              />
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"
              />
            </svg> -->
          </div>
          <!-- <div class="col text-right m-2" [hidden]="!newClientMenuOpen">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-caret-up-square"
              viewBox="0 0 16 16"
              (click)="toggleNewClientMenu()"
            >
              <path
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
              />
              <path
                d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z"
              />
            </svg>
          </div> -->
          <div class="col">
            <button class="btn btn-primary btn-md" (click)="editClientEnable()">
              {{ "admin-clients.updateDetails" | translate }}
            </button>
          </div>
        </div>
      </div>

      <form #form="ngForm" ngNativeValidate>
        <div
          [hidden]="!newClientMenuOpen"
          class="card-body text-secondary pb-2"
        >
          <div class="row">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.shortNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                name="clientshortName"
                [readonly]="readonly"
                #clientShortName="ngModel"
                [(ngModel)]="newMasterClient.clientshortName"
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.officialNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="clientofficialName"
                #clientOfficialName="ngModel"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.clientOfficialName"
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.officialAddressPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                [readonly]="readonly"
                name="clientOfficialAddress"
                [(ngModel)]="newMasterClient.clientOfficialAddress"
              />
            </div>
            <div class="col-6">
              <select
                id="inputState"
                class="form-control"
                name="countryOfRegisteredSeat"
                [disabled]="readonly"
                [(ngModel)]="newMasterClient.countryOfRegisteredSeat"
              >
                <option value="">
                  {{
                    "registrationPage.clientDetails.countryOfRegisteredSeat"
                      | translate
                  }}
                </option>
                <option value="Slovakia">Slovakia</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Hungary">Hungary</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.websitePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="webpage"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.webpage"
              />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <h5>{{ "registrationPage.registrationBy" | translate }}</h5>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.firstNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                name="firstName"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.firstName"
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.lastNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="lastName"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.lastName"
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.phonePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                name="phone"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.phone"
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.emailPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="email"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.email"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.passwordPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="password"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.passwrod"
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.confirmPasswordPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                name="confirmPassword"
                [readonly]="readonly"
                [(ngModel)]="newMasterClient.confirmPassword"
              />
            </div>
            <div class="col-6 mt-2">
              <input
                type="text"
                class="form-control"
                [placeholder]="'admin-clients.status' | translate"
                minlength="3"
                maxlength="100"
                required="true"
                [readonly]="readonly"
                name="status"
                [(ngModel)]="newMasterClient.status"
              />
            </div>
          </div>

          <div class="row mt-3 text-center">
            <div class="col">
              <button class="btn btn-primary btn-lg mb-2 mr-2" type="submit">
                {{ "registrationPage.submitButton" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 mt-4">
    <h3>{{ "admin-clients.allClients" | translate }}</h3>
  </div>
</div>

<div class="text-center mt-3" [hidden]="loadingFinished">
  <div class="spinner-border text-info" role="status"></div>
</div>

<div
  *ngFor="let client of clientList; index as clientIndex"
  class="card bg-light mb-0 my-3"
>
  <div class="card-header" id="{{ client.id }}">
    <div class="row">
      <div class="col-8">
        <h5>{{ client.name }}, {{ client.address }}</h5>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="btn btn-warning col"
          (click)="editClient(client.id!)"
        >
          {{ "admin-clients.editBtn" | translate }}
        </button>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="btn btn-danger col"
          (click)="deleteClient(client.id!)"
        >
          {{ "admin-clients.deleteBtn" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <div class="card-text">
        <div class="row">
          <div class="col-8">
            <p class="card-title">
              Web: {{ client.webpage }},
              {{ "admin-clients.phone" | translate }} {{ client.phone }},
              {{ "admin-clients.region" | translate }}
              {{ client.region }}
            </p>
          </div>
          <div class="col-2">
            <p class="card-title">
              {{ "admin-clients.created" | translate }} {{ client.created_at }}
            </p>
          </div>
          <div class="col-2">
            <p class="card-title">
              {{ "admin-clients.updated" | translate }} {{ client.updated_at }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
