import { Injectable, OnInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService implements OnInit {
  baseUrl: string = environment.baseUrlAPI;
  usersUrl: string = this.baseUrl+'/users';
  pendingUsersUrl:string =this.baseUrl+'/users/create-pending-users'
  clientsUrl: string = this.baseUrl+'/clients';
  loggedMasterUser:any

  constructor(private userService: UserService, private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
  }

  ngOnInit() {
    
  }
  newUser(data: any): Observable<any> {
    // return this.hc.post<any>(this.usersUrl, data);
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(this.usersUrl, data, { headers });
  }
  newPendingUsers(data:any): Observable<any> {
    // return this.hc.post<any>(this.usersUrl, data);
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(this.pendingUsersUrl, data, { headers });
  }

  getUsers(): Observable<any> {
    return this.hc.get<any>(this.usersUrl);
  }

  getUser(id: number): Observable<any> {
    return this.hc.get<any>(`${this.usersUrl}/${id}`);
  }

  updateUser(id: string, data: any): Observable<any> {
    return this.hc.put<any>(`${this.usersUrl}/${id}`, data);
  }

  deleteClientUser(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.delete<any>(`${this.usersUrl}/${id}`);
  }

  newClient(data: any): Observable<any> {
    return this.hc.post<any>(this.clientsUrl, data);
  }

  getClients(): Observable<any> {
    return this.hc.get<any>(this.clientsUrl);
  }

  getClient(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get<any>(`${this.clientsUrl}/${id}`, { headers });
  }

  updateClient(id: string, data: any): Observable<any> {
    return this.hc.put<any>(`${this.clientsUrl}/${id}`, data);
  }

  deleteClient(id: number): Observable<any> {
    return this.hc.delete<any>(`${this.clientsUrl}/${id}`);
  }
}
