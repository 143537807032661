import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  @ViewChild('form') form: FormGroup;

  newClientUser: any;

  loadingFinished: boolean = true;

  emailPattern = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';

  passwordStrength = '';
  emailTakenError = false;

  text = '';
  token:any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.newClientUser = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      masterClientName: '',
      role:''
    };
    this.route.queryParams.subscribe((params:any) => {
      this.token = params['token'];
    });
  }
  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
  resetPassword(){
    if(this.newClientUser.password !== this.newClientUser.confirmPassword) return
    this.userService.resetPassword({token:this.token,password:this.newClientUser.password}).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Password reset successfully', 'Success');
          this.routeChecker('login')
        }
      },
      error: (err) => {
        this.toastr.error('An error occurred during password reset', 'Error');
      }
    });
  }
  checkPasswordStrength(password: string): void {
    let strength = 'Weak';
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(
      password
    );
    const hasMixedCase =
      password !== password.toLowerCase() ||
      password !== password.toUpperCase();

    if (password.length >= 8 && (hasNumber || hasSpecialChar) && hasMixedCase) {
      strength = 'Strong';
    } else if (
      password.length >= 8 &&
      (hasNumber || hasSpecialChar || hasMixedCase)
    ) {
      strength = 'Moderate';
    }
    this.passwordStrength = strength;
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }
}
