<h1>Tu je ukazka exportu EXCEL pre teba</h1>

Tu som uz urobil ukazku pre teba ako sa robi export EXCEL. mozes urobit export
objednavky.

<div class="exportExcel">
  <p>Save Excel File</p>
  <button class="btn btn-warning" (click)="handleExport()">Save Excel!</button>
</div>
Tu som uz urobil ukazku pre teba ako sa robi export EXCEL. mozes urobit import
consumption.

<div class="input-group col-6">
  <div class="custom-file">
    <input
      type="file"
      name="file"
      class="custom-file-input"
      id="inputGroupFile"
      required
      (change)="handleImport($event)"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
    <label class="custom-file-label" for="inputGroupFile">Choose file</label>
  </div>
</div>

<div>
  <ngx-uplot
    [data]="dataActualPrices"
    [options]="optionsActualPrices"
    [legend]="true"
  >
  </ngx-uplot>
</div>

<p>Toto je skuska texty pod grafom ci bude dostupny</p>
<p><button (click)="getDataFromServer()">Get Data</button></p>
