import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  version: Observable<String>;
  versionUrl: string = 'assets/version/version.txt';

  constructor(private hc: HttpClient) { 
    this.version = this.hc.get(this.versionUrl, { responseType: 'text' });
  }
}
