<title>MyEnergy Dashboard: Online access to EU electricity market data</title>
<meta
  name="description"
  content="The MyEnergy Dashboard is a centralized control centre to monitor, optimize and analyze your energy consumption cost. Digitalisation of their actual and historical power consumption data, tailored to their specific activities."
/>

<div class="pageTemplate">
  <div class="container">
    <div class="headingBlock">
      <div class="headingBlock__title site__title">
        {{ "dashboard.welcome" | translate }}
        <span>{{ getDisplayName() }} </span
        >{{ "dashboard.topHeading" | translate }}
      </div>
      <p>
        {{ "dashboard.topParagraphFirst" | translate }}
        <span>{{ "dashboard.electicConsumption" | translate }}</span>
        {{ "dashboard.topParagraphSecond" | translate }}
      </p>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="whiteCard">
          <h4 class="whiteCard__title">
            {{ "dashboard.leftTitle" | translate
            }}<span class="tooltipBlock"
              >ⓘ
              <div class="tooltip">
                {{ "dashboard.leftTitleTooltip" | translate }}
              </div></span
            >
          </h4>
          <div class="start-line-left"></div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="whiteCard">
          <h4 class="whiteCard__title">
            {{ "dashboard.rightTitle" | translate
            }}<span class="tooltipBlock"
              >ⓘ

              <div class="tooltip">
                {{ "dashboard.rightTitleTooltip" | translate }}
              </div>
            </span>
          </h4>
          <div class="start-line-right"></div>
        </div>
      </div>
    </div>
  </div>
</div>
