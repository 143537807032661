import { Component } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent {
  public content: string = 'contentToConvert';

  // public captureScreen() {
  //   let data = document.getElementById(this.content);
  //   if (data) {
  //     html2canvas(data).then(canvas => {
 
  //       let imgWidth = 208;
  //       let pageHeight = 295;
  
  //       const contentDataURL = canvas.toDataURL('image/png');
  //       let pdf = new jsPDF('p', 'mm', 'a4'); 
  
  //       let topMargin = 10; 
  //       let bottomMargin = 15; 
  //       let leftMargin = 15; 
  //       let rightMargin = 15; 
  
  //       let widthWithMargin = imgWidth - leftMargin - rightMargin;
  //       let heightWithMargin = pageHeight - topMargin - bottomMargin;
  
  //       pdf.addImage(contentDataURL, 'PNG', leftMargin, topMargin, widthWithMargin, heightWithMargin);
  
  //       pdf.save('MyEnergy_TermsofService.pdf');
  //     });
  //   } else {
  //     console.error('Element with id "contentToConvert" not found');
  //   }
  // }
  public captureScreen() {
    let data = document.getElementById(this.content);
    if (data) {
      // Get the print button and hide it
      let printButton = (data.querySelector('.button') as HTMLElement);
      if (printButton) {
        printButton.style.display = 'none';
      }
  
      html2canvas(data).then(canvas => {
        // Few necessary setting options
        let imgWidth = 208;
        let pageHeight = 295;
  
        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
  
        let topMargin = 10; // Set the value of the top margin you want
        let bottomMargin = 15; // Set the value of the bottom margin you want
        let leftMargin = 5; // Set the value of the left margin you want
        let rightMargin = 5; // Set the value of the right margin you want
  
        let widthWithMargin = imgWidth - leftMargin - rightMargin;
        let heightWithMargin = pageHeight - topMargin - bottomMargin;
  
        pdf.addImage(contentDataURL, 'PNG', leftMargin, topMargin, widthWithMargin, heightWithMargin);
  
        pdf.save('MyEnergy_TermsofService.pdf');
  
        // Show the print button again after generating the PDF
        if (printButton) {
          printButton.style.display = 'block';
        }
      });
    } else {
      console.error('Element with id "contentToConvert" not found');
    }
  }

  
}
