import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {
  baseUrl: string = environment.baseUrlAPI;
  forecastsUrl: string = this.baseUrl+'/forecasts';

  constructor(private hc: HttpClient) {}

  newForecast(data: any): Observable<any> {
    //console.log('Forecast saved to ' + this.forecastsUrl);
    return this.hc.post<any>(this.forecastsUrl, data);
  }

  getForecasts(): Observable<any> {
    return this.hc.get<any>(this.forecastsUrl);
  }
}
