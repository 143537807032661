<footer class="footer">
  <div class="container">
    <div class="footer__top">
      <div class="row">
        <div class="col-lg-4">
          <div class="footer__top-logo">
            <img
              src="../assets/logo/logo-white.png"
              class="d-inline-block align-top"
              alt=""
              style="height: 55px"
            />
            <div>My<span>Energy</span></div>
          </div>
          <div class="footer__block">
            <div class="footer__iconsLogo">
              <img
                src="../assets/images/eit-logo.svg"
                class="align-top"
                alt=""
              />
            </div>
            <div class="footer__iconsLogo">
              <img
                src="../assets/images/eu-logo.svg"
                class="align-top"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6">
              <div class="footer__top-menu">
                <h6 class="footer__top-title">
                  {{ "footer.topMenu.title" | translate }}
                </h6>
                <ul class="footer__top-menuLinks">
                  <li>
                    <a (click)="routeChecker('contactUs')">{{
                      "footer.topMenu.menuLinks.contactUs" | translate
                    }}</a>
                  </li>
                  <li>
                    <a (click)="routeChecker('termsOfService')">{{
                      "footer.topMenu.menuLinks.termsOfService" | translate
                    }}</a>
                  </li>
                  <li>
                    <a href="https://novitech.sk/en/1-gdpr-statement/ ">{{
                      "footer.topMenu.menuLinks.privacPolicy" | translate
                    }}</a>
                  </li>
                </ul>
                <h6
                  class="footer__top-title mt-3"
                  style="margin-top: 8px !important"
                >
                  {{ "footer.topMenu.followUs" | translate }}
                </h6>
                <ul class="footer__socialLinks">
                  <li>
                    <a
                      href="https://www.linkedin.com/groups/12989415/"
                      target="_blank"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                        /></svg
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="footer__top-contact">
                <h6 class="footer__top-title">
                  {{ "footer.topContact.title" | translate }}
                </h6>
                <p style="margin-top: 15px">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                    /></svg
                  ><a>
                    <p>
                      Novitech a.s.
                      <br />
                      Moyzesova 58, 04001
                      <br />
                      Košice, Slovak Republic
                    </p>
                    <p>VAT: SK2020483828</p>
                  </a>
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path
                      d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
                    /></svg
                  ><a href="http://www.novitech.eu" target="_blank"
                    >www.novitech.eu</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="row">
        <div class="col-lg-12 text-center">
          <p class="text-center">
            {{ "footer.bottom.copyright1" | translate }}
            {{ version }}
            {{ "footer.bottom.copyright2" | translate }}
          </p>
        </div>
        <!-- <div class="col-lg-6">
                <ul class="footer__links">
                  <li><a>Privacy policy</a></li>
                  <li><a>Teams and Condition</a></li>
                  <li><a>Contact Us</a></li>
                </ul>
              </div> -->
      </div>
    </div>
  </div>
</footer>
