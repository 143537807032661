import { Component } from '@angular/core';
import { FooterService } from './footer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  version: any;

  constructor(private fs: FooterService,private router:Router){
    this.version = fs.version.subscribe(
          data => {
            const str = data.split(/\s+/);
            this.version = str[0] + ' at ' + str[1] + ' ' + str[2] + ', ';

          }
        ) ;
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }
}
