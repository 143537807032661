<title>MyEnergy: Customer Support</title>
<meta
  name="description"
  content="Looking for more information, having any questions or inquires about MyEnergy services? We are here to help you! Our experienced team is committed to provide you with a personalized support and guide you to minimizing the purchase costs of the volume of power."
/>

<!-- Conatct Form -->

<section class="contactModule">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="contactModule__form">
          <div class="contactModule__form-inner">
            <div class="" *ngIf="!showMessage">
              <h3 class="contactModule__form-title site__title">
                {{ "contactUs.contact" | translate }}
                <span>{{ "contactUs.us" | translate }}</span>
              </h3>
              <form
                (ngSubmit)="contactUs()"
                #form="ngForm"
                class="row justify-content-center"
              >
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="firstname">{{
                      "contactUs.firstName" | translate
                    }}</label>
                    <!-- <input
                      type="text"
                      class="form-control"
                      id="fName"
                      placeholder="Enter your first name"
                    /> -->
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      class="mb-2 form-control"
                      [placeholder]="
                        'contactUs.placeholder.firstName' | translate
                      "
                      (keydown)="space($event)"
                      #firstname="ngModel"
                      required
                      [(ngModel)]="newContactInfo.firstname"
                      (ngModelChange)="isFirstNameValid($event)"
                    />
                    <div
                      *ngIf="firstname.touched && firstNameValid != ''"
                      class="col-lg-12 invalid-input"
                    >
                      {{ firstNameValid | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="lastname">{{
                      "contactUs.lastName" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname"
                      name="lastname"
                      [placeholder]="
                        'contactUs.placeholder.lastName' | translate
                      "
                      (keydown)="space($event)"
                      #lastname="ngModel"
                      required
                      [(ngModel)]="newContactInfo.lastname"
                      (ngModelChange)="isLastNameValid($event)"
                    />
                    <div
                      *ngIf="lastname.touched && lastNameValid != ''"
                      class="col-lg-12 invalid-input"
                    >
                      {{ lastNameValid | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="email">{{
                      "contactUs.email" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      required
                      [placeholder]="'contactUs.placeholder.email' | translate"
                      (keydown)="space($event)"
                      #email="ngModel"
                      [(ngModel)]="newContactInfo.email"
                      (ngModelChange)="isEmailValid($event)"
                    />
                    <div
                      *ngIf="email.touched && emailValid != ''"
                      class="col-lg-12 invalid-input"
                    >
                      {{ emailValid | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="phone">{{
                      "contactUs.phone" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      required
                      pattern="^\+*[\d \-]*"
                      [placeholder]="'contactUs.placeholder.phone' | translate"
                      (keydown)="space($event)"
                      #phone="ngModel"
                      [(ngModel)]="newContactInfo.phone"
                      (ngModelChange)="isPhoneValid($event)"
                      triggers="manual"
                    />
                    <div
                      *ngIf="phone.touched && phoneValid != ''"
                      class="col-lg-12 invalid-input"
                    >
                      {{ phoneValid | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-10">
                  <div class="form-group">
                    <label for="message">{{
                      "contactUs.message" | translate
                    }}</label>
                    <textarea
                      class="form-control textarea1"
                      id="message"
                      name="message"
                      rows="3"
                      maxlength="{{ messageMaxLength }}"
                      required
                      [placeholder]="
                        'contactUs.placeholder.message' | translate
                      "
                      (keydown)="space($event)"
                      #message="ngModel"
                      [(ngModel)]="newContactInfo.message"
                      (ngModelChange)="isMessageValid($event)"
                    ></textarea>
                    <div
                      *ngIf="messageValid != ''"
                      class="col-lg-12 invalid-input"
                    >
                      {{ messageValid | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-10">
                  <button type="submit" class="btn button" type="submit">
                    {{ "contactUs.submit" | translate }}
                  </button>
                </div>
              </form>
            </div>
            <div class="thank_you_message" *ngIf="showMessage">
              <h4>
                {{ "contactUs.thank" | translate }}
                <span> {{ "contactUs.you" | translate }} </span>
              </h4>
              <h6>
                {{ "contactUs.forReachingOut" | translate }}
                {{ "contactUs.messageSubmitted" | translate }} <br />
                {{ "contactUs.teamReview" | translate }}
              </h6>
              <button (click)="showMessage = false" class="btn button">
                {{ "contactUs.ok" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
