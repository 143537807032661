import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
//  selector: 'input,select'
  selector: '[appHightLighter]'
})
export class HightLighterDirective {
  constructor(private element:ElementRef) { 

  }

  // input text="type" [class.edit]="true" appHightLighter="'red'"
  @Input('appHightLighter')
  color:string="green"; 

  // input text="type" [class.edit]="true"
  @HostBinding('class.edit') 
  edit:boolean = true;

  // input text="type" (focus)="focus()"
  previousColor:string="";

  @HostListener('focus') focus(){
    this.highLight(this.color);
    this.edit = true; 
  } 

  @HostListener('blur') blur(){
    this.element.nativeElement.style.backgroundColor=null; 
    this.edit = false; 
  } 

  highLight(color:string){
    let e = this.element.nativeElement;
    this.previousColor=e.style.backgroundColor; 
    e.style.backgroundColor = color || "yellow" ;
    
  }

}
