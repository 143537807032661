import { Component, ViewChild } from '@angular/core';
import { AnalysisService } from 'src/app/analysis/analysis.service';
import { formatDate } from '@angular/common';
import { DailyPrice } from '../daily-price';
import { lastValueFrom } from 'rxjs';
import { EChartsOption } from 'echarts';

import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from './../../translation.service';

@Component({
  selector: 'app-forecast1month',
  templateUrl: './forecast1month.component.html',
  styleUrls: ['./forecast1month.component.scss'],
})

export class Forecast1monthComponent {
  timestamps: number[] = [];
  allDataDownloaded: boolean = false;
  values: Array<Array<[number, number]>> = [[], [], [], []];
  dateFrom: string;
  dateUntil: string;
  plotColors: string[] = ['#2F8F9D', '#3BACB6', '#82DBD8', '#B3E8E5'];
  isLoading = true;
  chartOption: EChartsOption;

  //Daily Prices Construction
  daysofWeek: string[] = ['sun', 'mon', 'tue', 'wen', 'thu', 'fri', 'sat'];
  months: string[] = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  unit: string = 'Eur/MWh';
  dailyPrices: DailyPrice[] = new Array(30);
  dailyPricesLoaded: boolean = false;
  region: string = 'sk';

  constructor(
    private analysisService: AnalysisService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private translationService: TranslationService
  ) {
    this.setInitialDate(null);
    //this.setLang();

  }

  setLang(){
    let selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";
    this.translate.use(selectedLang);
  }

  @ViewChild('graf') graf: any;

  ngAfterViewInit() {
    // console.log('som v ngAfterViewInit', this.graf.nativeElement);
    this.gatherPlotData();
  }

  setInitialDate( initDay:string|null) {
    let day: any = null;

    if ( initDay !== null){
      day = formatDate(new Date(initDay), 'yyyy-MM-dd', 'en') + " 00:00:00";
    } else{
      day = formatDate(new Date(), 'yyyy-MM-dd', 'en') + " 00:00:00";
    }
    
    let date = new Date(day);
    date.setDate(date.getDate());
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate() + 30);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
  }

  resetPlotData() {
    this.timestamps = [];
    this.values = [[], [], [], []];
    this.dailyPrices = new Array(7);
    this.dailyPricesLoaded = false;
    this.isLoading = true;
  }

  generateDailyPrices() {
    for (let index = 0; index < 31; index++) {
      let dailyPrice = <DailyPrice>{};
      let priceDate = this.stringToDate(this.dateFrom, index);
      let timestamp = priceDate.getTime()/1000;

      dailyPrice.month = this.months[priceDate.getMonth()];
      dailyPrice.day = priceDate.getDate();
      dailyPrice.dayOfWeek = this.daysofWeek[priceDate.getDay()];
      dailyPrice.avgPrices = [];

      dailyPrice = this.calculateAveragePriceForDay(
        dailyPrice,
        this.dateFrom,
        index,
        timestamp
      );
      this.dailyPrices[index] = dailyPrice;
    }

    //console.log("dailyPrices", this.dailyPrices);
    this.dailyPricesLoaded = true;
  }


  calculateAveragePriceForDay(
    dailyPrice: DailyPrice,
    date: string,
    addDays: number,
    timestamp: number
  ): DailyPrice {

    let sumOfValuesA = 0;
    let sumOfValuesB = 0;
    let sumOfValuesC = 0;
    let sumOfValuesD = 0;

    dailyPrice.avgPrices[0] = (sumOfValuesA).toFixed(2);
    dailyPrice.avgPrices[1] = (sumOfValuesB).toFixed(2);
    dailyPrice.avgPrices[2] = (sumOfValuesC).toFixed(2);
    dailyPrice.avgPrices[3] = (sumOfValuesD).toFixed(2);

    let startIndex = this.values[0].findIndex(
      (item) => item[0] > timestamp
    ); 
    
    if ( startIndex >= 0){
      let endIndex = this.values[0].findIndex(
        (item) => item[0] > (timestamp + 86400 - 1)  // 24 hodin
      )-1; 

      if ( endIndex < 0 ) endIndex = this.values[0].length-1;

      let pocet = 0
      for (let index = startIndex; index <= endIndex; index++) {
        let valueA = 0;
        let valueB = 0;
        let valueC = 0;
        let valueD = 0;

        if ( this.values[0][index] ) valueA = this.values[0][index][1];
        if ( this.values[1][index] ) valueB = this.values[1][index][1];
        if ( this.values[2][index] )  valueC = this.values[2][index][1];
        if ( this.values[3][index] )  valueD = this.values[3][index][1];
      
        sumOfValuesA += valueA;
        sumOfValuesB += valueB;
        sumOfValuesC += valueC;
        sumOfValuesD += valueD;
        pocet++;
      }

      dailyPrice.avgPrices[0] = (sumOfValuesA / pocet).toFixed(2);
      dailyPrice.avgPrices[1] = (sumOfValuesB / pocet).toFixed(2);
      dailyPrice.avgPrices[2] = (sumOfValuesC / pocet).toFixed(2);
      dailyPrice.avgPrices[3] = (sumOfValuesD / pocet).toFixed(2);
    }


    return dailyPrice;
  }

  processData(jsonData: any){
    let attrib: string = "price";
    let index = 0;

    //console.log("jsonData",jsonData)

    if(jsonData instanceof Array){
      jsonData.forEach((element1: any) => {
        if(element1 instanceof Array){
          element1.forEach((element: any) => {
          
            let time = this.dateStringToUnix(element?.year + "." + element?.month + "." + element?.day + " " + element?.hour + ":00:00");

            if ( time >= 0 ){
                if (!this.timestamps.includes(time)) {
                  this.timestamps.push(time);
                }
      
                index = 0;
                for ( let i = 0; i < 20; i++){
                  attrib = "price";

                  if ( i > 0 ) attrib = attrib.concat(i.toString());
                  
                  if ( element[attrib] && element[attrib] !== null ) {
                    if ( ! this.values[0] ) this.values[0] = [];
                    if ( this.values[0] ){
                      this.values[index].push( [time, element[attrib] / 100]);
                      index++;
                      if ( index > 3) break;
                    }
                  };      
                }
            }
          });
        }
      });
    }
  }

  gatherPlotData() {
    this.setInitialDate(this.dateFrom);
    //console.log(this.dateFrom, this.dateUntil, this.region);
    this.resetPlotData();

    Promise.all([
      lastValueFrom(this.analysisService.getPricesForecastData(this.dateFrom, this.dateUntil, this.region, false))
    ]).then((forecastData) => {
      if (forecastData ) this.processData(forecastData);

      //console.log("values", this.values);

      this.allDataDownloaded = true;
      this.generateDailyPrices();
      this.updateChartOption();
      this.isLoading = false;
      this.dailyPricesLoaded = true;
    });
  }

  setRegion() {
    this.analysisService.setRegion(this.region);
    this.resetPlotData();
    this.gatherPlotData();
  }

  updateChartOption() {
    // Convert dateFrom and dateUntil to Unix timestamps in milliseconds
    let   date = new Date();   
    const tz   = date.getTimezoneOffset() * 60000;

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          if (Array.isArray(params) && params.length > 0) {
            let date = new Date(params[0].value[0]);          // The value[0] should be in milliseconds

            return `${formatDate(date, 'dd.MM.yyyy HH:mm', 'en')}<br/>
              ${params
                .map(
                  (param: any) =>
                    `${param.seriesName}: ${param.value[1]} Eur/MWh`
                )
                .join('<br/>')}`;
          }
          return '';
        },
      },
      legend: {
        data: ['Forecast 1', 'Forecast 2', 'Forecast 3', 'Forecast 4'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
        },
      ],
      xAxis: {
        name: 'Date / Time',
        type: 'time',
        //min: dateFromUnix, // Convert dateFrom to milliseconds
        //max: dateUntilUnix, // Convert dateUntil to milliseconds
        axisLabel: {
          formatter: (value: number) =>
            formatDate(new Date(value), 'dd.MM.yyyy HH:mm', 'en'), // Use value directly
          rotate:35, // Set the rotation angle
        },
      },
      yAxis: {
        type: 'value',
        name: 'Eur / MWh',
      },
      series: [
        {
          name: 'Forecast 1',
          type: 'line',
          symbol:'none',
          data: this.values[0].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: this.plotColors[0], width: 2 },
          itemStyle: { color: this.plotColors[0] },
          smooth:true
        },
        {
          name: 'Forecast 2',
          type: 'line',
          symbol:'none',
          data: this.values[1].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: this.plotColors[1], width: 2 },
          itemStyle: { color: this.plotColors[1] },
          smooth:true
        },
        {
          name: 'Forecast 3',
          type: 'line',
          symbol:'none',
          data: this.values[2].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: this.plotColors[2], width: 2 },
          itemStyle: { color: this.plotColors[2] },
          smooth:true
        },
        {
          name: 'Forecast 4',
          type: 'line',
          symbol:'none',
          data: this.values[3].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: this.plotColors[3], width: 2 },
          itemStyle: { color: this.plotColors[3] },
          smooth:true
        }
      ],
    };
  }

  dateStringToUnix(dateText: string): number {
    const date   = new Date(dateText);
    const userTZ = date.getTimezoneOffset() * 60000;

    //return Math.floor( ( date.getTime() + userTZ ) / 1000);
    return Math.floor( ( date.getTime() ) / 1000);
  }

  dateToUnix(
    dateText: string,
    plusDays: number = 0,
    plusHours: number = 0
  ): number {
    let date = new Date(dateText);
    date.setDate(date.getDate() + plusDays);
    date.setHours(date.getHours() + plusHours);
    const timeInMillisecond = date.getTime();
    const unix = Math.floor(timeInMillisecond / 1000);
    // console.log(`Date ${date} to ${unix}`);
    return unix;
  }

  stringToDate(dateText: string, plusDays: number = 0): Date {
    let date = new Date(dateText);
    date.setDate(date.getDate() + plusDays);
    return date;
  }
}
