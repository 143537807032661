<title>MyEnergy Register: Sign Up for energy control sevice market</title>
<meta
  name="description"
  content="Ready to be in charge of your energy consumption and reduce your costs? Sign up today to revolutionize energy management through powerful tools and up-to-date insights with the help of MyEnergy."
/>

<section class="custom_login_section">
  <div class="login_card_wrapper">
    <div class="custom_logo_section">
      <div class="logo-section">
        <img
          class="img-responsive"
          src="../../assets/logo/logo-green.png"
          alt=""
        />
      </div>
      <h3 class="logo_title">
        I Control <br />
        <span> MyEnergy </span>
      </h3>
    </div>
    <div class="signupCard_title" *ngIf="!showMessage">
      <div class="row">
        <h4 class="col">
          {{ "registrationPage.clientRegistrationTitle" | translate }}
        </h4>
        <div class="col text-right m-2" [hidden]="newClientMenuOpen"></div>
      </div>
    </div>

    <form
      (ngSubmit)="createClientMasterUser()"
      #form="ngForm"
      *ngIf="!showMessage"
    >
      <div [hidden]="!newClientMenuOpen" class="">
        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>{{ "registrationPage.clientDetails.title" | translate }}</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.shortNamePlaceholder'
                    | translate
                "
                minlength="4"
                maxlength="6"
                required="true"
                id="clientshortName"
                name="clientshortName"
                (keydown)="space($event)"
                #clientShortName="ngModel"
                [(ngModel)]="newMasterClient.clientshortName"
                (ngModelChange)="isShortNameValid($event)"
              />
              <div
                *ngIf="clientShortName.touched && shortNameInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errSNY"
                [style.left.px]="errSNX"
              >
                {{ shortNameInvalid | translate }}
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.officialNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                id="clientofficialName"
                name="clientofficialName"
                (keydown)="space($event)"
                #clientOfficialName="ngModel"
                [(ngModel)]="newMasterClient.clientOfficialName"
                (ngModelChange)="isOfficialNameValid($event)"
              />
              <div
                *ngIf="clientOfficialName.touched && officialNameInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errONY"
                [style.left.px]="errONX"
              >
                {{ officialNameInvalid | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.officialAddressPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                id="clientOfficialAddress"
                name="clientOfficialAddress"
                (keydown)="space($event)"
                #clientOfficialAddress="ngModel"
                [(ngModel)]="newMasterClient.clientOfficialAddress"
                (ngModelChange)="isOfficialAddressValid($event)"
              />
              <div
                *ngIf="
                  clientOfficialAddress.touched && officialAddressInvalid != ''
                "
                class="col-lg-12 invalid-input"
                [style.top.px]="errOAY"
                [style.left.px]="errOAX"
              >
                {{ officialAddressInvalid | translate }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <select
                id="inputState"
                class="form-control"
                name="countryOfRegisteredSeat"
                required="true"
                [(ngModel)]="newMasterClient.countryOfRegisteredSeat"
              >
                <option value="">
                  {{
                    "registrationPage.clientDetails.countryOfRegisteredSeat"
                      | translate
                  }}
                </option>
                <option value="sk">Slovakia</option>
                <option value="cz">Czech Republic</option>
                <option value="hu">Hungary</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.websitePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                id="webpage"
                name="webpage"
                #webpage="ngModel"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.webpage"
                (ngModelChange)="isWebpageValid($event)"
              />
              <div
                *ngIf="webpage.touched && webpageInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errWY"
                [style.left.px]="errWX"
              >
                {{ webpageInvalid | translate }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                [placeholder]="
                  'registrationPage.clientDetails.avgElectricityPlaceholder'
                    | translate
                "
                min="0"
                name="AvgElectricity"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.AvgElectricity"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>{{ "registrationPage.registrationBy" | translate }}</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.firstNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                id="firstName"
                name="firstName"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.firstName"
                #firstName="ngModel"
                (ngModelChange)="isFNValid($event)"
              />
              <div
                *ngIf="firstName.touched && fnInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errFNY"
                [style.left.px]="errFNX"
              >
                {{ fnInvalid | translate }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.lastNamePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                id="lastName"
                name="lastName"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.lastName"
                #lastName="ngModel"
                (ngModelChange)="isLNValid($event)"
              />
              <div
                *ngIf="lastName.touched && lnInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errLNY"
                [style.left.px]="errLNX"
              >
                {{ lnInvalid | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.phonePlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="50"
                pattern="^\+*[\d \-]*"
                required="true"
                id="phone"
                name="phone"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.phone"
                #phone="ngModel"
                (ngModelChange)="isPhoneValid($event)"
              />
              <div
                *ngIf="phone.touched && phoneInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errPhoneY"
                [style.left.px]="errPhoneX"
              >
                {{ phoneInvalid | translate }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.emailPlaceholder'
                    | translate
                "
                minlength="3"
                maxlength="100"
                required="true"
                id="email"
                name="email"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.email"
                #emailWithPattern="ngModel"
                (ngModelChange)="isEmailValid($event)"
              />
              <div
                *ngIf="emailWithPattern.touched && emailError != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errEmailY"
                [style.left.px]="errEmailX"
              >
                {{ emailError | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                [placeholder]="
                  'registrationPage.personalDetails.passwordPlaceholder'
                    | translate
                "
                minlength="8"
                maxlength="100"
                required="true"
                id="password"
                name="password"
                (keydown)="space($event)"
                [(ngModel)]="newMasterClient.password"
                #password="ngModel"
                (ngModelChange)="isPasswordValid($event)"
              />
              <div
                *ngIf="password.touched && psswdInvalid != ''"
                class="col-lg-12 invalid-input"
                [style.top.px]="errPsswdY"
                [style.left.px]="errPsswdX"
              >
                {{ psswdInvalid | translate }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <input
              type="password"
              class="form-control"
              [placeholder]="
                'registrationPage.personalDetails.confirmPasswordPlaceholder'
                  | translate
              "
              minlength="8"
              maxlength="100"
              required="true"
              id="confirmPassword"
              name="confirmPassword"
              (keydown)="space($event)"
              #confirmPassword="ngModel"
              [(ngModel)]="newMasterClient.confirmPassword"
              (ngModelChange)="isConfirmPasswordValid($event)"
            />
            <div
              *ngIf="confirmPassword.touched && confirmPsswdInvalid != ''"
              class="col-lg-12 invalid-input"
              [style.top.px]="errCPsswdY"
              [style.left.px]="errCPsswdX"
            >
              {{ confirmPsswdInvalid | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>
                {{ "registrationPage.termsAgreement" | translate }}
              </h5>
            </div>
          </div>
        </div>
        <div class="card" style="width: full">
          <div class="card-body privacyBlock">
            <div class="termsBlock" id="contentToConvert">
              <div class="termsBlock__head">
                <h2 class="termsBlock__title">
                  MyEnergy
                  <span
                    >{{ "termsOfService.termsOf" | translate }}
                    <!-- {{ "termsOfService.service" | translate }} -->
                  </span>
                </h2>
                <button class="button button--border" (click)="captureScreen()">
                  {{ "termsOfService.printBtn" | translate }}
                </button>
              </div>
              <h6>
                {{ "termsOfService.introductionandOverview.title" | translate }}
              </h6>
              <p>
                {{
                  "termsOfService.introductionandOverview.contentA" | translate
                }}
                <br />
                <br />
                {{
                  "termsOfService.introductionandOverview.contentB" | translate
                }}
              </p>
              <h6>
                {{ "termsOfService.serviceDescription.title" | translate }}
              </h6>
              <p>
                {{ "termsOfService.serviceDescription.content" | translate }}
              </p>
              <ul>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.A" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.B" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.C" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.D" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.E" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.F" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.G" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.H" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceDescription.details.I" | translate
                  }}
                </li>
              </ul>
              <h6>
                {{ "termsOfService.serviceLevelsandMetrics.title" | translate }}
              </h6>
              <ul>
                <li>
                  {{
                    "termsOfService.serviceLevelsandMetrics.details.A"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceLevelsandMetrics.details.B"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.serviceLevelsandMetrics.details.C"
                      | translate
                  }}
                </li>
              </ul>
              <h6>
                {{
                  "termsOfService.responsibilitiesAndObligations.title"
                    | translate
                }}
              </h6>
              <p>
                {{
                  "termsOfService.responsibilitiesAndObligations.content"
                    | translate
                }}
              </p>
              <ul>
                <li>
                  {{
                    "termsOfService.responsibilitiesAndObligations.details.A"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.responsibilitiesAndObligations.details.B"
                      | translate
                  }}
                </li>
              </ul>
              <p>
                {{
                  "termsOfService.responsibilitiesAndObligations.client_responsibilities.title"
                    | translate
                }}
              </p>
              <ul>
                <li>
                  {{
                    "termsOfService.responsibilitiesAndObligations.client_responsibilities.A"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.responsibilitiesAndObligations.client_responsibilities.B"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.responsibilitiesAndObligations.client_responsibilities.C"
                      | translate
                  }}
                </li>
              </ul>
              <h6>
                {{ "termsOfService.communicationProtocol.title" | translate }}
              </h6>
              <ul>
                <li>
                  {{
                    "termsOfService.communicationProtocol.details.A" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.communicationProtocol.details.B" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.communicationProtocol.details.C" | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.communicationProtocol.details.D" | translate
                  }}
                </li>
              </ul>
              <h6>
                {{ "termsOfService.problemResolution.title" | translate }}
              </h6>
              <p>
                {{ "termsOfService.problemResolution.content" | translate }}
              </p>
              <ul>
                <li>
                  {{ "termsOfService.problemResolution.details.1" | translate }}
                </li>
                <li>
                  {{ "termsOfService.problemResolution.details.2" | translate }}
                </li>
                <li>
                  {{ "termsOfService.problemResolution.details.3" | translate }}
                </li>
              </ul>
              <h6>
                {{
                  "termsOfService.contractDurationTermination.title" | translate
                }}
              </h6>
              <ul>
                <li>
                  {{
                    "termsOfService.contractDurationTermination.details.A"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.contractDurationTermination.details.B"
                      | translate
                  }}
                </li>
              </ul>
              <h6>
                {{ "termsOfService.confidentialitySecurity.title" | translate }}
              </h6>
              <ul>
                <li>
                  {{
                    "termsOfService.confidentialitySecurity.details.A"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.confidentialitySecurity.details.B"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "termsOfService.confidentialitySecurity.details.C"
                      | translate
                  }}
                </li>
              </ul>
              <h6>{{ "termsOfService.costAndPayment.title" | translate }}</h6>
              <div class="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>
                        {{
                          "termsOfService.costAndPayment.columnA" | translate
                        }}
                      </th>
                      <th>
                        {{
                          "termsOfService.costAndPayment.columnB" | translate
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          "termsOfService.costAndPayment.details.A" | translate
                        }}
                      </td>
                      <td>
                        <b>{{
                          "termsOfService.costAndPayment.prices.A" | translate
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "termsOfService.costAndPayment.details.B" | translate
                        }}
                      </td>
                      <td>
                        <b>{{
                          "termsOfService.costAndPayment.prices.B" | translate
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "termsOfService.costAndPayment.details.C" | translate
                        }}
                      </td>
                      <td>
                        <b>{{
                          "termsOfService.costAndPayment.prices.C" | translate
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "termsOfService.costAndPayment.details.D" | translate
                        }}
                      </td>
                      <td>
                        <b>{{
                          "termsOfService.costAndPayment.prices.D" | translate
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          "termsOfService.costAndPayment.details.E" | translate
                        }}
                      </td>
                      <td>
                        <b>{{
                          "termsOfService.costAndPayment.prices.E" | translate
                        }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6>
                {{ "termsOfService.disputeResolution.title" | translate }}
              </h6>
              <p>
                {{ "termsOfService.disputeResolution.content" | translate }}
              </p>
              <h6>{{ "termsOfService.amendmentReview.title" | translate }}</h6>
              <p>{{ "termsOfService.amendmentReview.content" | translate }}</p>
            </div>
          </div>
        </div>

        <div class="form-group form-check mt-2">
          <mat-radio-group
            aria-label="Select an option"
            class="radioBtns"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="radioBtnValue"
          >
            <mat-radio-button value="standard" name="clientAgreement">{{
              "registrationPage.agree" | translate
            }}</mat-radio-button>
            <mat-radio-button value="specific" name="specificConditions">
              {{
                "registrationPage.specificConditionsPlaceholder" | translate
              }}</mat-radio-button
            >
          </mat-radio-group>

          <p class="validate_info" *ngIf="termsCondition">
            {{ "registrationPage.termsConditionError" | translate }}
          </p>
        </div>
        <div class="re-captcha_info">
          <re-captcha
            (resolved)="resolved($event)"
            siteKey="6LepIHwpAAAAAB27JXqHOU97uJVv35UzQ-Zpk1qp"
          ></re-captcha>
        </div>

        <div class="row">
          <div class="col text-right">
            <button class="subBtn" type="submit">
              {{ "registrationPage.submitButton" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="thank_you_message" *ngIf="showMessage">
      <h4>
        {{ "contactUs.thank" | translate }}
        <span> {{ "contactUs.you" | translate }} </span>
      </h4>
      <h6>
        {{ "registrationPage.thankYouMessage.message1" | translate }}
        <br />
        {{ "registrationPage.thankYouMessage.message2" | translate }}
      </h6>

      <button (click)="redirectToHome()" class="subBtn">
        {{ "registrationPage.thankYouMessage.okButton" | translate }}
      </button>
    </div>
  </div>
</section>
