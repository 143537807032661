// src/app/app.module.ts
import { NgModule, LOCALE_ID, isDevMode, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { UserModule } from './user/user.module';
import { RouterModule, Routes } from '@angular/router';
import { registerLocaleData, CommonModule, NgIf } from '@angular/common';
import locale from '@angular/common/locales/sk';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';
import { NgxUplotModule } from 'ngx-uplot';
import { routers } from './app.module.router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { RequestComponent } from './helpdesk/request/request.component';
import { ResponseComponent } from './helpdesk/response/response.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ApprovalPageComponent } from './approval-page/approval-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RequestAPresentationComponent } from './request-a-presentation/request-a-presentation.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslationService } from './translation.service';
import { HappenedYesterdayComponent } from './happened-yesterday/happened-yesterday.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { HappenedWeekComponent } from './happened-yesterday/happened-week/happened-week.component';

import { MatButtonModule } from '@angular/material/button';
import { HappenedMonthComponent } from './happened-yesterday/happened-month/happened-month.component';
import { DataExportServicesComponent } from './data-export-services/data-export-services.component';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BusinessUnitLocationsComponent } from './business-unit-locations/business-unit-locations.component';

import { OrgChartModule } from 'angular13-organization-chart';

registerLocaleData(locale);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/i18n/', '.json');
}

export class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}
// export function initializeApp(translationService: TranslationService) {
//   return () => translationService.initializeLanguage();
// }
export function initializeApp(translationService: TranslationService) {
  return () => {
    return translationService.initializeLanguage().then((lang) => {
    });
  };
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    NewsPageComponent,
    ContactUsComponent,
    TermsOfServiceComponent,
    HelpdeskComponent,
    RequestComponent,
    ResponseComponent,
    ApprovalPageComponent,
    LandingPageComponent,
    RequestAPresentationComponent,
    ActivityLogsComponent,
    HappenedYesterdayComponent,
    HappenedWeekComponent,
    HappenedMonthComponent,
    DataExportServicesComponent,
    BusinessUnitLocationsComponent
  ],
  imports: [
    BrowserModule,
    UserModule,
    OrgChartModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    NgxUplotModule,
    MatTabsModule,
    MatCheckboxModule,
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    RouterModule.forRoot(routers),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIf,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sk' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslationService],
      multi: true
    },
    RequestComponent,
    ResponseComponent,
    TranslateService,
    HeaderComponent
  ],
  bootstrap: [AppComponent],
  exports: [DashboardComponent, RouterModule, HeaderComponent],
})
export class AppModule {}
