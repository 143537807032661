import { UserService } from './user/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'myEnergy';
  titleDlhe = 'myEnergy long title please change it';
  isLoggedIn:boolean = false;
  showHeaderBar: boolean = true ;

  //loggedIn$ = userService.isLoggenIn; Pouziva sa potom v template cez !(loggedIn$|async)

  constructor(private userService:UserService, private headerService: HeaderService,private router:Router){
    userService.isLoggenIn.subscribe({
      next: (isLoggedIn:boolean) => {this.isLoggedIn=isLoggedIn}
    })

    headerService.showHeaderBar.subscribe({
      next: (showHeaderBar:boolean) => {this.showHeaderBar = showHeaderBar}
    })
  }
  ngOnInit(){
    // console.log("1 ",this.router.url.split('/').slice(2).join('/'))
    // const currentUrl = this.router.url.split('/').slice(2).join('/');
    // setTimeout(()=>{

    //   // console.log("header ",this.router.url.split('/').slice(2).join('/'))
    //   console.log("app ",this.router.url.split('/').slice(2).join('/'))
    //   if(this.router.url.split('/').slice(2).join('/')!== 'home'){
    //     console.log("checked")
    //   }
    // },0)
    // this.router.navigate([`${sessionStorage.getItem('language')}/${currentUrl}`]);
  }
  // ngOnDestroy(): void {
  //   sessionStorage.removeItem('language')
  // }
}
