import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  isLightboxOpen = false;
  // Initialize currentImage with a default value to avoid TypeScript errors
  currentImage: { src: string; description: string } = {
    src: '',
    description: '',
  };
  constructor(private router:Router){}

  openLightbox(imageSrc: string, description: string) {
    this.isLightboxOpen = true;
    this.currentImage = { src: imageSrc, description: description };
    document.body.style.overflow = 'hidden';
  }

  closeLightbox() {
    this.isLightboxOpen = false;
    document.body.style.overflow = '';
  }

  @ViewChild('containerAspects') containerAspects: any;

  scrollToContainer() {
    if (this.containerAspects) {
      this.containerAspects.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }
}
