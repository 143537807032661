<title>MyEnergy: Control Your energy consumption costs</title>
<meta name="description" content="Maximize energy efficiency and optimize costs with MyEnergy's comprehensive monitoring and automation services. Our customized platform for industrial customers provides digitized data insights, customized consumption plans and real-time market insights. Digitalisation of their actual and historical power consumption data, tailored to their specific activities. Minimizing the purchase costs of the volume of power required for the scheduled activities of the manufacturing facilities.">

<div class="container-main">
  <div class="content-myenergy">
    <div class="block1">
      <img src="../../assets/images/logo6.webp" alt="Photo" onclick="openLightbox('../../assets/images/logo6.webp')">
      <p>{{'landingPage.block1.text' | translate}}</p>
      <ul>
        <li>{{'landingPage.block1.list.1' | translate}}</li>
        <li>{{'landingPage.block1.list.2' | translate}}</li>
      </ul>
      <p>{{'landingPage.block1.buying_text' | translate}}</p>
      <a (click)="routeChecker('sign-up')">
        <button>{{'landingPage.block1.register_button' | translate}}</button>
      </a>
    </div>
    <div class="block2">
      <div class="photo">
        <img src="../../assets/images/Schema3.webp" alt="Thumbnail" (click)="openLightbox('../../assets/images/myenergy.jpg', 'MyEnergy Business Model')">
        <p>{{'landingPage.block2.myenergy_model' | translate}}</p>
      </div>

      <div class="photo">
        <img src="../../assets/images/grafik.webp" alt="Photo 2" (click)="openLightbox('../../assets/images/myenergy-graf-new.jpg', 'Hourly Price Trends')">
        <p>{{'landingPage.block2.hourly_price_trends' | translate}}</p>
      </div>
    </div>

    <div class="block-with-arrow">
      <button (click)="scrollToContainer()">
        <svg aria-hidden="true" class="arrow-icon" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"></path>
        </svg>
      </button>
    </div>
  </div>
</div>
<div class="container-aspects" #containerAspects>
  <div class="Business">
    <svg aria-hidden="true" class="aspects-icon" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M496 224c-79.59 0-144 64.41-144 144s64.41 144 144 144 144-64.41 144-144-64.41-144-144-144zm64 150.29c0 5.34-4.37 9.71-9.71 9.71h-60.57c-5.34 0-9.71-4.37-9.71-9.71v-76.57c0-5.34 4.37-9.71 9.71-9.71h12.57c5.34 0 9.71 4.37 9.71 9.71V352h38.29c5.34 0 9.71 4.37 9.71 9.71v12.58zM496 192c5.4 0 10.72.33 16 .81V144c0-25.6-22.4-48-48-48h-80V48c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h395.12c28.6-20.09 63.35-32 100.88-32zM320 96H192V64h128v32zm6.82 224H208c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h291.43C327.1 423.96 320 396.82 320 368c0-16.66 2.48-32.72 6.82-48z"></path>
    </svg>
    <h3>{{'landingPage.container-aspects.business.heading' | translate}}</h3>
    <p>{{'landingPage.container-aspects.business.text' | translate}}</p>
  </div>
  <div class="Policy">
    <svg aria-hidden="true" class="aspects-icon" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM144 32h96c8.8 0 16 7.2 16 16s-7.2 16-16 16h-96c-8.8 0-16-7.2-16-16s7.2-16 16-16zm48 128c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H102.4C90 416 80 407.4 80 396.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z"></path>
    </svg>
    <h3>{{'landingPage.container-aspects.policy.heading' | translate}}</h3>
    <p>{{'landingPage.container-aspects.policy.text' | translate}}</p>
  </div>
  <div class="Innovation">
    <svg aria-hidden="true" class="aspects-icon" viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path>
    </svg>
    <h3>{{'landingPage.container-aspects.innovation.heading' | translate}}</h3>
    <p>{{'landingPage.container-aspects.innovation.text' | translate}}</p>
  </div>
</div>

<div class="container-portfolio">
  <div class="content-portfolio">
    <h2>{{'landingPage.container-portfolio.heading' | translate}}</h2>
    <div class="portfolio-block">
      <div class="portfolio-block1">
        <ul style="padding-left: 0px;">
          <li>{{'landingPage.container-portfolio.block1.1' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block1.2' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block1.3' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block1.4' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block1.5' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block1.6' | translate}}</li>
        </ul>
      </div>

      <div class="portfolio-block2">
        <ul>
          <li>{{'landingPage.container-portfolio.block2.1' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block2.2' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block2.3' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block2.4' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block2.5' | translate}}</li>
          <li>{{'landingPage.container-portfolio.block2.6' | translate}}</li>
        </ul>
      </div>
    </div>
    <a (click)="routeChecker('sign-up')">
      <button>{{'landingPage.container-portfolio.register_button' | translate}}</button>
    </a>
    <a (click)="routeChecker('dashboard')">
      <button class="login-button">{{'landingPage.container-portfolio.dashboard_button' | translate}} </button>
    </a>
  </div>
</div>

<div class="lightbox" *ngIf="isLightboxOpen" (click)="closeLightbox()">
  <img [src]="currentImage.src" alt="Full Size Image">
  <p>{{ currentImage.description }}</p>
  <button class="close-button" (click)="closeLightbox()">&times;</button>
</div>
