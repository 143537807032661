import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HeaderService {
  showHeaderBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() {     
  }

  setShowHeaderBar(value: boolean){
    this.showHeaderBar.next(value);
  }
}
