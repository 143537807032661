import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  numberAttribute,
} from '@angular/core';
import { DashboardEntry, DashboardModel } from './dashboard-model';
import { DashboardService } from './dashboard.service';
import { UserToken } from '../user/user-token';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';

interface DashboardData {
  dashboard: {
    dashboardModelLeft: any[]; // You can replace `any` with a more specific type if you have one
    dashboardModelRight: any[]; // You can replace `any` with a more specific type if you have one
  };
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  user: UserToken;
  dashboardModelLeft: DashboardEntry[];
  dashboardModelRight: DashboardEntry[];
  pocitadlo: number = 0;
  lang="en"
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private http: HttpClient,
    private router:Router
  ) {
    // this.user = this.dashboardService?.userToken!;
    let user = localStorage.getItem('userToken');
    if(user) this.user=JSON.parse(user)
  }

  ngOnInit() {
    let lang=sessionStorage.getItem('language');
    this.http.get(`assets/langs/i18n/${lang}.json`).subscribe((data) => {
      const typedData = data as DashboardData;
      this.dashboardModelLeft = typedData.dashboard.dashboardModelLeft;
      this.dashboardModelRight = typedData.dashboard.dashboardModelRight;
      this.setupDashboardModel();
    });
  }

  ngAfterViewInit() {
    // generate template according to DashboardModel
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      let lang = event.lang;
      this.http.get(`assets/langs/i18n/${lang}.json`).subscribe((data) => {
        const typedData = data as DashboardData;
        this.dashboardModelLeft = typedData.dashboard.dashboardModelLeft;
        this.dashboardModelRight = typedData.dashboard.dashboardModelRight;
        this.setupDashboardModel();
      });
    });
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  getDisplayName() {
    if (this.user && (this.user.fullName || this.user.login)) {
      let name = this.user.fullName || this.user.login;
      return name.charAt(0).toUpperCase() + name.slice(1);
    } else {
      return 'Visitor';
    }
  }

  setupDashboardModel() {
    var uroven: number = 0;
    var classa: string = 'start-line-left';

    var elParent = this.elementRef.nativeElement.querySelector('.' + classa);
    if (elParent) {
      this.clearDashboard(elParent);
      classa = 'L';
      this.genDashboard(elParent, this.dashboardModelLeft, classa);
    }

    classa = 'start-line-right';
    elParent = this.elementRef.nativeElement.querySelector('.' + classa);
    if (elParent) {
      this.clearDashboard(elParent);
      classa = 'R';
      this.genDashboard(elParent, this.dashboardModelRight, classa);
    }
  }

clearDashboard(elParent: any) {
  while (elParent.firstChild) {
    if (!elParent.firstChild.classList.contains('whiteCard__title')) {
      elParent.removeChild(elParent.firstChild);
    } else {
      break;
    }
  }
}

  genDashboard(
    elParent: ElementRef | any,
    dashboardEntry: DashboardEntry[] | any,
    classa: string
  ) {
    var chapter: string = '';

    var elChild: ElementRef | any = this.genAccordion(classa);
    this.renderer.appendChild(elParent, elChild);

    elParent = elChild;

    //console.log("**",classa, dashboardEntry);

    for (var i = 0; i < dashboardEntry.length; i++) {
      this.pocitadlo++;
      //console.log("**",classa + (i+1).toString());

      if (this.pocitadlo > 100) {
        return;
      }

      //console.log("Generuje sa karta pre objekt ", classa + (i+1).toString(), dashboardEntry[i]);
      var elChild: ElementRef | any = this.genCard(
        elParent,
        dashboardEntry[i],
        classa + (i + 1).toString(),
        chapter + (i + 1).toString() + '.'
      );
      this.renderer.appendChild(elParent, elChild);
    }
  }

  genCard(
    elParent: ElementRef | any,
    dashboardEntry: DashboardEntry,
    classa: string,
    chapter: string
  ): ElementRef | any {
    var card = this.renderer.createElement('div');

    var disableCard: boolean = false;
    var classes: string = dashboardEntry?.class;

    //console.log( chapter, classes);

    if (classes) {
      // adding classes to card from model
      var splittingClasses: string[] = classes.split(' ');

      for (var i = 0; i < splittingClasses.length; i++) {
        if (splittingClasses[i] === 'disableCard') {
          disableCard = true;
          //console.log("classes disableCard");
        }
        this.renderer.addClass(card, splittingClasses[i]);
      }
    } else {
      this.renderer.addClass(card, 'card');
    }

    if (!disableCard) {
      if (dashboardEntry.forUsersOnly) {
        //  disable card when user is not logged in
        if (!this.user?.token) {
          this.renderer.addClass(card, 'disableCard');
          disableCard = true;
          //console.log("forUsersOnly disableCard");
          //console.log(this.userToken);
        }
      }
    }

    if (!disableCard) {
      if (dashboardEntry?.userRole && !(dashboardEntry.userRole === '')) {
        // if userRole is defined in model
        if (!this.user?.role || this.user.role === '') {
          this.renderer.addClass(card, 'disableCard');
          disableCard = true;
          //console.log("role1 disableCard");
        } else {
          if (
            !(dashboardEntry.userRole === this.user.role) &&
            !(this.user.role === 'admin')
          ) {
            this.renderer.addClass(card, 'disableCard');
            disableCard = true;
            //console.log(this.user.role);
            //console.log(dashboardEntry.userRole);
            //console.log("role2 disableCard");
          }
        }
      }
    }

    this.renderer.appendChild(elParent, card);

    var cardHeader: ElementRef | any = this.genCardHeader(
      dashboardEntry,
      classa,
      chapter,
      disableCard
    );
    this.renderer.appendChild(card, cardHeader);

    var cardBody: ElementRef | any = this.genCardDiv(
      elParent,
      dashboardEntry,
      classa,
      chapter
    );
    this.renderer.appendChild(card, cardBody);

    return card;
  }

  genCardHeader(
    dashboardEntry: DashboardEntry,
    classa: string,
    chapter: string,
    disabled: boolean
  ): ElementRef | any {
    var cardHeader = this.renderer.createElement('div');
    this.renderer.addClass(cardHeader, 'card-header');
    this.renderer.setAttribute(cardHeader, 'id', 'heading-' + classa);

    var cardTitle: ElementRef | any = this.genCardTitle(
      dashboardEntry,
      classa,
      chapter,
      disabled
    );
    this.renderer.appendChild(cardHeader, cardTitle);

    var cardA: ElementRef | any = this.genCardA(dashboardEntry, classa);
    this.renderer.appendChild(cardHeader, cardA);

    return cardHeader;
  }

  genCardTitle(
    dashboardEntry: DashboardEntry,
    classa: string,
    chapter: string,
    disabled: boolean
  ): ElementRef | any {
    var cardTitle: ElementRef | any = this.renderer.createElement('div');
    this.renderer.addClass(cardTitle, 'card-title');

    if (!disabled) {
      //console.log("1. restricted", disabled, dashboardEntry?.status);
      if ( dashboardEntry?.status && (dashboardEntry.status === 'restricted')) {
        //console.log("2. restricted", dashboardEntry);
        this.renderer.addClass(cardTitle, 'card-restricted');
      }
    }


    if (dashboardEntry?.url) {
      var cardA: ElementRef | any = this.renderer.createElement('a');

      if (!disabled) {
        // this.renderer.setAttribute(cardA, 'href', dashboardEntry.url);
        this.renderer.listen(cardA, 'click', () => this.routeChecker(dashboardEntry?.url || 'home'));
      }

      this.renderer.appendChild(cardTitle, cardA);

      var text = this.renderer.createText(
        chapter + ' ' + dashboardEntry?.title
      );
      this.renderer.appendChild(cardA, text);
    } else {
      var text = this.renderer.createText(
        chapter + ' ' + dashboardEntry?.title
      );
      this.renderer.appendChild(cardTitle, text);
    }

    var infoChar = this.renderer.createElement('span');
    text = this.renderer.createText('ⓘ');
    this.renderer.appendChild(infoChar, text);
    this.renderer.appendChild(cardTitle, infoChar);

    var tooltip = this.renderer.createElement('div');
    this.renderer.addClass(tooltip, 'tooltip');
    text = this.renderer.createText(dashboardEntry.tooltip);
    this.renderer.appendChild(tooltip, text);
    this.renderer.appendChild(infoChar, tooltip);

    return cardTitle;
  }

  genCardA(dashboardEntry: DashboardEntry, classa: string): ElementRef | any {
    var cardA: ElementRef | any = this.renderer.createElement('a');
    this.renderer.addClass(cardA, 'collapsed');
    this.renderer.setAttribute(cardA, 'role', 'button');
    this.renderer.setAttribute(cardA, 'data-toggle', 'collapse');
    this.renderer.setAttribute(cardA, 'href', '#collapse-' + classa);
    this.renderer.setAttribute(cardA, 'aria-expanded', 'false');
    this.renderer.setAttribute(cardA, 'aria-controls', 'collapse-' + classa);
    this.renderer.setAttribute(
      cardA,
      'style',
      'font-size: 0.6rem; color: black; transform: rotate z 45deg'
    );

    if (dashboardEntry?.children) {
      var cardArrow = this.renderer.createText('▼');
      this.renderer.appendChild(cardA, cardArrow);

      //var cardASvg = this.genCardASvg(dashboardEntry, classa);
      //var cardASvg = this.renderer.createText("a");
      //this.renderer.appendChild(cardA, cardASvg);
    }

    return cardA;
  }

  genCardASvg(
    dashboardEntry: DashboardEntry,
    classa: string
  ): ElementRef | any {
    var cardASvg: ElementRef | any = this.renderer.createElement('svg');
    this.renderer.setAttribute(cardASvg, 'height', '10px');
    this.renderer.setAttribute(cardASvg, 'width', '10px');
    this.renderer.setAttribute(cardASvg, 'xmlns', 'http://www.w3.org/2000/svg');
    this.renderer.setAttribute(cardASvg, 'viewBox', '0 0 448 512');

    var cardSPath = this.genSPath(dashboardEntry, classa);
    this.renderer.appendChild(cardASvg, cardSPath);

    return cardASvg;
  }

  genSPath(dashboardEntry: DashboardEntry, classa: string): ElementRef | any {
    var cardSPath: ElementRef | any = this.renderer.createElement('path');
    this.renderer.setAttribute(
      cardSPath,
      'd',
      'M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'
    );
    //var cardSPath = this.renderer.createText("˄");
    //var cardSPath = this.renderer.createText("▼");

    return cardSPath;
  }

  //<svg fill="#000000" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_224_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394 l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393 C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"></path> </g></svg>

  genCardDiv(
    elParent: ElementRef | any,
    dashboardEntry: DashboardEntry,
    classa: string,
    chapter: string
  ): ElementRef | any {
    var cardEl: ElementRef | any = this.renderer.createElement('div');
    this.renderer.addClass(cardEl, 'collapse');
    this.renderer.addClass(cardEl, 'hide');
    //this.renderer.addClass(cardEl,'show');
    this.renderer.setAttribute(cardEl, 'id', 'collapse-' + classa);

    if (elParent?.id) {
      this.renderer.setAttribute(cardEl, 'data-parent', '#' + elParent?.id);
    }

    this.renderer.setAttribute(cardEl, 'aria-labelledby', 'heading-' + classa);

    if (dashboardEntry?.children) {
      var cardBody = this.genCardBody(
        elParent,
        dashboardEntry,
        classa,
        chapter
      );
      this.renderer.appendChild(cardEl, cardBody);
    }

    return cardEl;
  }

  genCardBody(
    elParent: ElementRef | any,
    dashboardEntry: DashboardEntry,
    classa: string,
    chapter: string
  ): ElementRef | any {
    var cardBody: ElementRef | any = this.renderer.createElement('div');
    this.renderer.addClass(cardBody, 'card-body');

    if (dashboardEntry?.children) {
      var element: ElementRef | any = this.genAccordion(classa);
      this.renderer.appendChild(cardBody, element);

      for (var i = 0; i < dashboardEntry.children.length; i++) {
        //console.log("**",classa + (i+1).toString() );

        if (this.pocitadlo > 100) {
          return;
        }

        //console.log("Generuje sa karta pre objekt ", classa + (i+1).toString(), dashboardEntry.children[i]);
        var elChild: ElementRef | any = this.genCard(
          element,
          dashboardEntry.children[i],
          classa + (i + 1).toString(),
          chapter + (i + 1).toString() + '.'
        );
        this.renderer.appendChild(element, elChild);
      }
    } else {
    }

    return cardBody;
  }

  genAccordion(classa: string): ElementRef | any {
    var accordion: ElementRef | any = this.renderer.createElement('div');
    this.renderer.setAttribute(accordion, 'id', 'accordion' + classa);
    return accordion;
  }
}
