<title>MyEnergy Helpdesk: Support When You Need It</title>
<meta
  name="description"
  content="Do you need assistance with the services of MyEnergy? We will provide you with professional support and guidance any time you need us, thanks to our committed help desk. MyEnergy's trained team is here to guide you during every step of the way, in case you are looking for answers about platofrm usage, require troubleshooting assistance or keen to explore additional features."
/>
<div class="container-fluid">
  <section class="client_request_section">
    <div class="client_request_section__form">
      <div class="client_request_section__form-inner">
        <div class="request_card_wrapper">
          <div class="request_logo_section">
            <div class="request_title site__title">
              <!-- <span>{{requestTitle}}</span> -->
              <span *ngIf="!requestId">{{
                "helpDesk.response.title" | translate
              }}</span>
              <span *ngIf="requestId"
                >{{ "helpDesk.response.titleId" | translate }} &nbsp;</span
              >
              <span class="green-text">{{ requestId }}</span>
            </div>
            <div *ngIf="submitForm == false" class="row mb-5">
              <div class="col-12 text-center">
                {{ "helpDesk.response.note" | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="submitForm == false">
            <div class="row mb-3">
              <div class="col-3">
                {{ "helpDesk.response.subject" | translate }}
              </div>
              <div class="col-9 rounded">
                {{ requestSubject }}
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">My Energy Service</div>
              <div class="col-9 rounded">
                {{ myEnergyService }}
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                {{ "helpDesk.response.priority" | translate }}
              </div>

              <div class="col-9 rounded">
                {{ priority }}
              </div>
            </div>
            <div class="description">
              <div class="row mb-3">
                <div class="col-3">
                  {{ "helpDesk.response.description" | translate }}
                </div>
                <textarea
                  class="col-9 informFieldText rounded"
                  rows="4"
                  value="{{ description }}"
                  readonly
                >
                </textarea>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                {{ "helpDesk.response.phone" | translate }}
              </div>

              <div class="col-9 rounded">{{ phoneState }}{{ phoneNumber }}</div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                {{ "helpDesk.response.email" | translate }}
              </div>
              <div class="col-9 rounded">
                {{ email }}
              </div>
            </div>

            <div class="row mb-3" *ngIf="attachment">
              <div class="col-3">
                {{ "helpDesk.response.attachment" | translate }}
              </div>
              <div class="col-9 rounded">
                {{ attachment.name }}
              </div>
            </div>
          </div>
          <div *ngIf="submitForm == true" class="row mt-5 mb-2">
            <div class="col-12 sent-12">
              {{ "helpDesk.response.note1" | translate }}
              {{ requestId }}
              {{ "helpDesk.response.note2" | translate }}
              {{ email }}.
            </div>
          </div>

          <div *ngIf="submitForm == true" class="row">
            <div class="col-12 sent-12">
              {{ "helpDesk.response.note3" | translate }}
            </div>
          </div>

          <div class="btn-sb">
            <div *ngIf="submitForm == false" class="row mt-5 mb-5">
              <form
                class="col-4"
                action="#"
                method="get"
                ngForm
                (ngSubmit)="onSubmit()"
                #theForm="ngForm"
              >
                <button
                  class="col-6 btn requestEdit {{ buttonClass }}"
                  type="submit"
                  (click)="onEdit()"
                >
                  {{ "helpDesk.response.editBtn" | translate }}
                </button>

                <button
                  class="col-6 btn requestSubmit {{ buttonClass }}"
                  type="submit"
                  (click)="btnSubmitClick($event)"
                >
                  {{ "helpDesk.response.submitBtn" | translate }}
                </button>
              </form>
            </div>

            <div *ngIf="submitForm == true" class="row mt-5 mb-5">
              <form
                class="col-2"
                action="#"
                method="get"
                ngForm
                (ngSubmit)="onClose($event)"
                (click)="onClose($event)"
                #theForm="ngForm"
              >
                <button class="col-12 btn requestEdit" type="submit">
                  {{ "helpDesk.response.closeBtn" | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="request_footer_wrapper">
  <p></p>
</div>
