import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { UserToken } from '../user/user-token';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  userToken: UserToken;
  
  constructor( private userService: UserService) { 
    this.userToken = this.userService?.userToken!;
  }
}
