<title>MyEnergy : Data Export Services</title>
<meta
  name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast."
/>

<div
  class="text-center mt-3"
  *ngIf="isLoading"
  style="position: absolute; z-index: 10;width: 100%;
  background-color: rgba(185, 185, 185, 0.397);
  height: 125vh;"
>
  <div style="width: 50px;height: 50px;margin-top: 15%;" class="spinner-border text-info" role="status"></div>
  <div class=" text-info" role="status">Creating the export file, please wait...</div>
</div>
<div class="pageTemplate">




  <div *ngIf="clientData">
    <div class="headingBlock">
      <div class="headingBlock__title site__title text-center m-3">
        <!-- {{ "priceToday.title" | translate }} -->
        Data Export Services
        <span style="color: #00bf63">
          {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}</span
        >
      </div>
    </div>
    <div class="col-12 fliter">Choose a Timescale :</div>
    <div
      class="row stickyHeader"
      style="padding-left: 25px; align-items: center"
    >
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="input-group text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >{{ "priceToday.from" | translate }}:</label
            >
          </div>
          <input
            type="date"
            [(ngModel)]="dateFrom"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
          />
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="input-group text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >{{ "priceToday.until" | translate }}:</label
            >
          </div>
          <input
            type="date"
            [(ngModel)]="dateUntil"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
          />
        </div>
      </div>

      <div *ngIf="selectedPlans" class="col-12 col-sm-6 col-xl-3 fliter"></div>
      <div *ngIf="selectedPlans" class="col-12 col-sm-6 col-xl-3 fliter">
        No. of Selected Plans : {{ selectedPlans }}
      </div>
    </div>

    <body class="graf img-fluid" #graf></body>
    <div class="col-12 fliter">Choose the Plans :</div>
    <div class="requestList">
      <div class="container-fluid">
        <div class="userListingFlex">
          <div class="userListing">
            <div class="userListing__card">
              <h5>{{ "plans.dayPlans" | translate }}</h5>

              <div class="text-center mt-3" *ngIf="loadingDay">
                <div class="spinner-border text-info" role="status"></div>
              </div>

              <div
                *ngIf="errorDay != ''"
                class="text-center mt-3 network-error"
              >
                {{ errorDay | translate }}
              </div>

              <div
                style="color: #038846; font-weight: 500"
                *ngIf="
                  !loadingDay &&
                  dayPlansDataSource!.data!.length === 0 &&
                  errorDay === ''
                "
                class="text-center mt-3"
              >
                {{ "plans.noPlans" | translate }}
              </div>

              <div
                class="userListing__table"
                *ngIf="dayPlansDataSource!.data!.length > 0"
              >
                <table
                  mat-table
                  [dataSource]="dayPlansDataSource"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                        (change)="
                          $event ? toggleAllRows2() : null; checkSelectedPlans()
                        "
                        [checked]="selection2.hasValue() && isAllSelected2()"
                        [indeterminate]="
                          selection2.hasValue() && !isAllSelected2()
                        "
                        [aria-label]="checkboxLabel2()"
                      >
                      </mat-checkbox>
                      Select All
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="
                          $event ? selection2.toggle(row) : null;
                          checkSelectedPlans()
                        "
                        [checked]="selection2.isSelected(row)"
                        [aria-label]="checkboxLabel2(row)"
                      >
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.name" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ capitalizeFirstLetter(element.name) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.startDate" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.startDate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.createdAt" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.created_at }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.status" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ "plans.statusValue." + element.status | translate }}
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumnsDayPlans"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsDayPlans"
                  ></tr>
                </table>
              </div>
              <mat-paginator
                #dayPaginator
                [showFirstLastButtons]="true"
                [length]="paginatorValues[0][3]"
                [pageIndex]="paginatorValues[0][1]"
                (page)="handlePage($event, 0)"
              ></mat-paginator>
              <p
                *ngIf="dayPlanDisclaimer"
                style="color: #038846; font-weight: 500"
              >
                {{ "plans.notPriceMsg" | translate }}
              </p>
            </div>
          </div>

          <div class="userListing">
            <div class="userListing__card">
              <h5>{{ "plans.intraDayPlans" | translate }}</h5>

              <div class="text-center mt-3" *ngIf="loadingIntraday">
                <div class="spinner-border text-info" role="status"></div>
              </div>

              <div
                *ngIf="errorIntraday != ''"
                class="text-center mt-3 network-error"
              >
                {{ errorIntraday | translate }}
              </div>

              <div
                style="color: #038846; font-weight: 500"
                *ngIf="
                  !loadingIntraday &&
                  intradayPlansDataSource!.data!.length === 0 &&
                  errorIntraday === ''
                "
                class="text-center mt-3"
              >
                {{ "plans.noPlans" | translate }}
              </div>

              <div
                class="userListing__table"
                *ngIf="intradayPlansDataSource.data.length > 0"
              >
                <table
                  mat-table
                  [dataSource]="intradayPlansDataSource"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                        (change)="
                          $event ? toggleAllRows() : null; checkSelectedPlans()
                        "
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        "
                        [aria-label]="checkboxLabel()"
                      >
                      </mat-checkbox>
                      Select All
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="
                          $event ? selection.toggle(row) : null;
                          checkSelectedPlans()
                        "
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)"
                      >
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.name" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ capitalizeFirstLetter(element.name) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.startDate" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.startDate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.createdAt" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.created_at }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.status" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ "plans.statusValue." + element.status | translate }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
              <mat-paginator
                #intradayPaginator
                [showFirstLastButtons]="true"
                [length]="paginatorValues[1][3]"
                [pageIndex]="paginatorValues[1][1]"
                (page)="handlePage($event, 1)"
              ></mat-paginator>
              <p
                *ngIf="intradayPlanDisclaimer"
                style="color: #038846; font-weight: 500"
              >
                {{ "plans.notPriceMsg" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row stickyHeader"
      style="justify-content: space-evenly; align-items: center"
    >
      <div class="col-12 col-sm-6 col-xl-3">
        <button
          (click)="openExportDailog()"
          mat-fab
          extended
          class="w-100"
          [ngStyle]="{ 'background-color': isLoading ? 'grey' : 'green' }"
        >
          <mat-icon> archive</mat-icon>
          <!-- <mat-icon> vertical_align_bottom</mat-icon> -->
          Export
        </button>
      </div>
    </div>
  </div>
  <ng-template #dialogTemplate2>
    <h1
      mat-dialog-title
      style="
        font-weight: 600;
        font-size: 22px;
        border-bottom: 1px solid rgb(201, 201, 201);
      "
    >
      Please select where do you want to export
    </h1>
    <mat-dialog-content class="dialog-content">
      <div class="form-group">
        <select
          size="2"
          class="btn form-control px-2 text-left"
          id="myEnergyDropDwn"
          name="myEnergyDropDwn"
          type="button"
          aria-haspopup="true"
          aria-expanded="false"
          [(ngModel)]="exportType"
        >
          <option selected value="csv" style="margin-bottom: 5px">
            Receive by E-mail
          </option>
          <option value="xlsx">Download to this Device</option>
        </select>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions
      style="display: flex; justify-content: center; align-content: center"
    >
      <button class="theme-btn-yellow" mat-dialog-close (click)="exportPlans()">
        {{ "plans.exportBtn" | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
