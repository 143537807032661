import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { UserService } from "../user/user.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RequestAPresentationService } from './request-a-presentation.service';
import { HeaderService } from '../header.service';
import { EChartsOption } from 'echarts';
import { AnalysisService } from '../analysis/analysis.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-request-a-presentation',
  templateUrl: './request-a-presentation.component.html',
  styleUrls: ['./request-a-presentation.component.scss']
})
export class RequestAPresentationComponent implements OnInit {
  selectedLanguage: any | null;

  @ViewChild('containerAspects') containerAspects: any;
  @ViewChild('containerContact') containerContact: any;
  @ViewChild('containerAbout') containerAbout: any;
  @ViewChild('container2Portfolio') container2Portfolio: any;
  @ViewChild('form') form: FormGroup;
  @ViewChild('containerMain') containerMain: ElementRef;

  newContactInfo: any;
  showMessage = false;

  nameValid = "";
  companyValid = "";
  emailValid = "";
  phoneValid = "";
  counter = 0;
  messageMaxLength = 1500;

  timestamps: number[] = [];
  values: Array<Array<[number, number]>> = [[], [], []];
  pricesDownloaded: boolean[] = [false, false, false];
  allDataDownloaded: boolean = false;
  dateFrom: string;
  dateUntil: string;
  spotPrice: number = 0;
  forecastPrice: number = 0;
  trendRising: boolean;
  region: string = 'sk';

  isLoading = true;
  chartOption: EChartsOption;

  showButton = false;
  scrollThreshold = 100;

  constructor(
    public userService: UserService,
    private router: Router,
    private _eref: ElementRef,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private contactUsService: RequestAPresentationService,
    private headerService: HeaderService,
    private analysisService: AnalysisService
  ) {
    this.setInitialDates();
  }

  ngOnInit() {
    this.newContactInfo = {
      name: '',
      company: '',
      email: '',
      phone: ""
    };

    this.nullFormItems();
    this.gatherPlotData();

    this.scrollThreshold = this.containerContact?.nativeElement?.offsetHeight;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.showButton = scrollPosition > this.scrollThreshold;

    const buttonElement = document.querySelector('.scroll-to-top-btn');
    if (buttonElement) {
      if (this.showButton) {
        buttonElement.classList.add('show');
      } else {
        buttonElement.classList.remove('show');
      }
    }
  }

  routeChecker(url: string) {
    const selectedLang = sessionStorage.getItem('language');
    const newUrl = `${selectedLang}/${url}`;
    const appBaseUrl = this.router.serializeUrl(this.router.createUrlTree([newUrl]));

    window.open(appBaseUrl, '_blank');
  }

  changeLanguage(event: any): void {
    const selectedLangValue = event.value.value;
    const currentUrl = this.router.url.split('/').slice(2).join('/');
    this.router.navigate([`${this.selectedLanguage.value}/${currentUrl}`]);
    if (this.selectedLanguage) {
      this.translateService.use(this.selectedLanguage.value);
      sessionStorage.setItem('language', this.selectedLanguage.value);
    }
  }

  nullFormItems() {
    this.newContactInfo = {
      name: '',
      company: '',
      email: '',
      phone: ""
    };
  }

  contactUs() {

    let valid = this.form.valid && this.nameValid === "" && this.companyValid === "" &&
      this.emailValid === "" && this.phoneValid === "" ;

    if (!valid) {
      this.translateService.get('contactUs.validator.toastrError').subscribe((data1: string) => {
        this.translateService.get('contactUs.validator.toastrError2').subscribe((data2: string) => {
          this.toastr.error(data2, data1);
        });
      });
      return;
    }

    const contactInfo = { ...this.form.value };

    this.contactUsService.contactUs(contactInfo).subscribe(
      (data) => {
        if (data) {
          this.translateService.get('transfer.success.htmlOK').subscribe((data1: string) => {
            this.translateService.get('transfer.success.success').subscribe((data2: string) => {
              this.toastr.success(data2, data1);
            });
          });

          this.showMessage = true;
        }
      },
      (error) => {
        console.error('Error occurred:', error);
        this.translateService.get('transfer.error.htmlError').subscribe((data1: string) => {
          this.translateService.get('transfer.error.error').subscribe((data2: string) => {
            this.toastr.error(data2, data1);
          });
        });
      }
    );
  }

  isNameValid(event: any) {
    this.nameValid = event.length < 2 ? "contactUs.validator.firstName" : "";
    return this.nameValid;
  }

  isCompanyValid(event: any) {
    this.companyValid = event.length < 2 ? "contactUs.validator.firstName" : "";
    return this.companyValid;
  }

  isEmailValid(event: any) {
    this.emailValid = !/^[a-z0-9\._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event) ? "contactUs.validator.email" : "";
    return this.emailValid;
  }

  isPhoneValid(event: any) {
    this.phoneValid = "";
    this.form.controls['phone'].setErrors(null);

    if (event.length < 6) {
      this.phoneValid = "contactUs.validator.phoneLength";
    }

    if (!/^\+*[\d \-]*$/.test(event)) {
      this.phoneValid = "contactUs.validator.phone";
      this.form.controls['phone'].setErrors({ 'incorrect': true });
    }

    return this.phoneValid;
  }

  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  redirectToHomePage() {
    this.headerService.setShowHeaderBar(true);
    this.router.navigate(['/']);
  }

  setInitialDates() {
    let day = formatDate(new Date(), 'yyyy-MM-dd', 'en') + " 00:00:00";
    let date = new Date(day);
    date.setDate(date.getDate() + 5);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate() - 8);
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
  }

  async loadActuals(){
    return await lastValueFrom(this.analysisService.getPricesActualData(this.dateFrom,
      this.dateUntil,
      this.region,
      false
    ));
  }

  async loadBackcast(){
    return await lastValueFrom(this.analysisService.getPricesBackcastData(          this.dateFrom,
      this.dateUntil,
      this.region,
      false
    ));
  }

  async loadForecast(){
    return await lastValueFrom(this.analysisService.getPricesForecastData(          this.dateFrom,
      this.dateUntil,
      this.region,
      false
    ));
  }

  async gatherPlotData() {
    let actualMaxTime = 0;
    let actualMinTime = 0;
    let backcastMaxTime = 0;
    let forecastMaxTime = await this.dateStringToUnix(this.dateUntil + " 24:00:00");

    let actualData: any   = await this.loadActuals();
    let backcastData: any = await this.loadBackcast();
    let forecastData: any = await this.loadForecast();    

    if ( actualData && actualData.length > 0 ){
      if (actualData) await this.processData(actualData, 0, 0, null, null);
      if (this.values[0] && this.values[0][0] && this.values[0][0][0]) actualMinTime = this.values[0][0][0];
      if (this.values[0] && this.values[0].length > 0 && this.values[0][this.values[0].length - 1][0])
        actualMaxTime = this.values[0][this.values[0].length - 1][0];
    } else{
      this.translateService.get('priceToday.validator.toastr.error1').subscribe((data1: string) => {
          this.toastr.error(data1);
      });  
    }

    if (backcastData && backcastData.length > 0){
      await this.processData(backcastData, 1, 0, actualMinTime, actualMaxTime);
    } else{
      this.translateService.get('priceToday.validator.toastr.error3').subscribe((data1: string) => {
          this.toastr.error(data1);
      });  
    }

    if (forecastData && forecastData.length > 0){
      await this.processData(forecastData, 2, null, actualMaxTime, forecastMaxTime);
    } else{
      this.translateService.get('priceToday.validator.toastr.error2').subscribe((data1: string) => {
          this.toastr.error(data1);
      });  
    }

    if (this.values[1] && this.values[1].length > 0 && this.values[1][this.values[1].length - 1][0])
      backcastMaxTime = this.values[1][this.values[1].length - 1][0];

    if (backcastMaxTime < actualMaxTime) {
      await this.processData(forecastData, 1, null, backcastMaxTime, actualMaxTime);
    }

      this.allDataDownloaded = true;
      this.calculateSpotAndForecastPrices();
      this.updateChartOption();
      this.isLoading = false;
  }

  calculateSpotAndForecastPrices() {
    if (this.values[0].length > 0) {
      this.spotPrice = this.values[0][this.values[0].length - 1][1];
    }

    let lastForecastIndex = 0;

    if (this.values[2].length > 0) {
      const latestActualTime =
        this.values[0].length > 0
          ? this.values[0][this.values[0].length - 1][0]
          : 0;

      lastForecastIndex = this.values[2].findIndex(
        (item) => item[0] > latestActualTime
      );

      let pocet = 0;
      let forecastAvgPrice = 0;
      if (lastForecastIndex >= 0) {
        for (let i = lastForecastIndex; i < lastForecastIndex + 3; i++) {
          forecastAvgPrice = forecastAvgPrice + this.values[2][i][1];
          pocet++;
        }

        forecastAvgPrice = forecastAvgPrice / pocet;
      }

      if (forecastAvgPrice) {
        this.forecastPrice = Math.round(forecastAvgPrice * 100) / 100;
      }
    }

    this.trendRising = this.forecastPrice > this.spotPrice;
  }

  async processData(
    jsonData: any,
    valueIndex: number,
    priceIndex: number | null,
    limitMin: number | null,
    limitMax: number | null
  ): Promise<number> {
    let myMaxTime: number = 0;
    let attrib: string = 'price';

    if (jsonData instanceof Array) {
      jsonData.forEach(async (element: any) => {
        let write: boolean = false;
        let time = await this.dateStringToUnix(
          element?.year +
          '.' +
          element?.month +
          '.' +
          element?.day +
          ' ' +
          element?.hour +
          ':00:00'
        )

        if (time >= 0) {
          // Filter data within the specified date range
          write = true;

          if (limitMin !== null && limitMin > time) write = false;
          if (limitMax !== null && limitMax < time) write = false;

          if (write) {
            if (!this.timestamps.includes(time)) {
              this.timestamps.push(time);
              if (time > myMaxTime) myMaxTime = time;
            }

            if (priceIndex === null) {
              // if priceIndex is null, find first not null value
              for (let i = 0; i < 20; i++) {
                attrib = 'price';

                if (i > 0) attrib = attrib.concat(i.toString());
                if (element[attrib] && element[attrib] !== null) break;
              }
            } else {
              if (priceIndex > 0) attrib = 'price' + priceIndex.toString();
            }

            if (element[attrib]) {
              this.values[valueIndex].push([time, element[attrib] / 100]);
            }
          }
        }
      });
    }

    return myMaxTime;
  }

  updateChartOption() {
    let date = new Date();
    const tz = date.getTimezoneOffset() * 60000;

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          if (Array.isArray(params) && params.length > 0) {
            let date = new Date(params[0].value[0] - tz);

            return `${formatDate(date, 'dd.MM.yyyy HH:mm', 'en')}<br/>
              ${params
              .map(
                (param: any) =>
                  `${param.seriesName}: ${param.value[1]} Eur/MWh`
              )
              .join('<br/>')}`;
          }
          return '';
        },
      },
      legend: {
        data: ['Actual', 'Backcast', 'Forecast'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
        },
      ],
      xAxis: {
        name: 'Date / Time',
        type: 'time',
        axisLabel: {
          formatter: (value: number) =>
            formatDate(new Date(value - tz), 'dd.MM.yyyy HH:mm', 'en'),
          rotate: 35,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Eur / MWh',
      },
      series: [
        {
          name: 'Actual',
          type: 'line',
          symbol: 'none',
          data: this.values[0].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: 'black', width: 2 },
          itemStyle: { color: 'black' },
          smooth: true
        },
        {
          name: 'Backcast',
          type: 'line',
          symbol: 'none',
          data: this.values[1].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: '#00bf63', width: 1 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true
        },
        {
          name: 'Forecast',
          type: 'line',
          symbol: 'none',
          data: this.values[2].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: '#0083cf', width: 2 },
          itemStyle: { color: '#0083cf' },
          smooth: true
        },
      ],
    };
  }

  resetPlotData() {
    this.timestamps = [];
    this.values = [[], [], [], []];
    this.pricesDownloaded = [false, false, false];
    this.allDataDownloaded = false;
    this.spotPrice = 0;
    this.forecastPrice = 0;
    this.trendRising = false;
    this.isLoading = true;
  }

  async dateStringToUnix(dateText: string): Promise<number> {
    let regex: any;
    let date: any = new Date(dateText);

    if (isNaN(date.getTime())) {
      regex = /^\d{4}\.\d{1,2}\.\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}/;       // test na format RRRR.MM.DD hh:mm:ss

      if ( regex.test(dateText) ){
        const [datePart, timePart]   = dateText.split(' ');   // oddelíme dátum a čas
        const [year, month, day]     = datePart.split('.');   // rozdelíme dátum na časti
        const [hour, minute, second] = timePart.split(':');   // rozdelíme čas na časti
    
        // Vytvorenie objektu Date s použitím explicitných hodnôt
        date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));            
      }

      if (isNaN(date.getTime())) {
        regex = /^\d{1,2}\.\d{1,2}\.\d{4} \d{1,2}:\d{1,2}:\d{1,2}/;     // test na format DD.MM.RRRR hh:mm:ss
  
        if ( regex.test(dateText) ){
          const [datePart, timePart]   = dateText.split(' '); // oddelíme dátum a čas
          const [day, month, year]     = datePart.split('.'); // rozdelíme dátum na časti
          const [hour, minute, second] = timePart.split(':'); // rozdelíme čas na časti
      
          // Vytvorenie objektu Date s použitím explicitných hodnôt
          date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));            
        }

        if (isNaN(date.getTime())) {
          console.error("Neplatný dátum:", dateText);

          return NaN;
        }        
      }
    }

    let userTZ = date.getTimezoneOffset() * 60000;

    let timestamp = Math.floor((date.getTime() + userTZ) / 1000);
    return timestamp;
  }

  generateChart() {
    this.resetPlotData();
    this.gatherPlotData();
  }

  setRegion() {
    this.analysisService.setRegion(this.region);
    this.resetPlotData();
    this.gatherPlotData();
  }

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
      });
    }, {
      root: null,
      threshold: 1.0
    });

    if (this.containerMain) {
      observer.observe(this.containerMain.nativeElement);
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
