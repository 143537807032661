<section
  class="smallHero"
  style="background-image: url(../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <div class="smallHero__title site__title">
      {{ "plans.list.title" | translate }}
    </div>
  </div>
</section>

<div class="formTemplate requestListPlans">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="formCard">
          <!-- <div class="formCard__head">
            <h4 class="formCard__title">Day <span>Plan</span></h4>
          </div> -->
          <div class="formCard__body">
            <!-- day plan data start -->
            <div class="formCard__Block fixHead">
              <div class="header">
                <h3 class="formCard__body-title" *ngIf="!preview">
                  {{ "plans.list.preview" | translate }}
                  <span style="color: #00bf63">{{ clientData?.name }}</span>
                  {{ "plans.by" | translate }}
                  <span style="color: #00bf63">{{ user?.fullName }}</span>
                </h3>
                <h3 class="formCard__body-title" *ngIf="preview">
                  {{ "plans.list.confirm.sentence1" | translate }}
                  <span style="color: #00bf63">
                    {{ "plans.list.confirm.sentence2" | translate }}
                  </span>
                </h3>
                <a
                  (click)="routeChecker('plans')"
                  class="button theme-btn-green"
                >
                  {{ "plans.list.btnView" | translate }}
                </a>
              </div>
              <label *ngIf="!preview" for="fName">
                {{ "plans.list.selected" | translate }}
              </label>
              <div class="form-group" *ngIf="!preview">
                <!--
                <select
                  class="btn form-control px-2 text-left"
                  id="myEnergyDropDwn"
                  name="myEnergyDropDwn"
                  aria-haspopup="true"
                  aria-expanded="false"
                  (change)="setPlanType($event.target)"
                  [(ngModel)]="planId"
                >
                  <option
                    selected
                    style="display: none"
                    value="consumptionPlan"
                  >
                    {{ "plans.list.selectPlan" | translate }}
                  </option>
                  <option
                    *ngFor="let item of consumptionPlans"
                    class="dropdown-item"
                    [value]="item.id"
                  >
                    {{ item.name }} ({{ item.unit }}), {{ getIntervals(item) }}
                    {{ "plans.interval" | translate }},
                    {{ "plans.createdAt" | translate }}
                    {{ getCreatedAt(item.created_at) }}
                    {{ "plans.by" | translate }}
                    {{ getCreatedByName(item?.created_by) }}
                  </option>
                </select>
                -->
                <div class="form-group">
                  <label for="fName">{{
                    "plans.create.planName" | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="'plans.create.namePlaceholder' | translate"
                    aria-label="PlanName"
                    aria-describedby="basic-addon1"
                    minlength="3"
                    maxlength="50"
                    required="true"
                    [(ngModel)]="selectedPlan.name"
                    [title]="'plans.create.tooltip.name' | translate"
                    readonly
                    style="pointer-events: none"
                  />
                </div>
                <div class="form-group">
                  <label for="fName">{{
                    "plans.create.planDescription" | translate
                  }}</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    [(ngModel)]="selectedPlan.description"
                    minlength="3"
                    maxlength="1000"
                    required="true"
                    [placeholder]="'plans.create.descPlaceholder' | translate"
                    [title]="'plans.create.tooltip.description' | translate"
                    readonly
                    style="pointer-events: none"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div
              *ngIf="selectedPlan?.plan_type === 'day' && !preview"
              class="formCard__Block-head"
            >
              <h4 class="formCard__Block-title">
                {{ "plans.list.dayCons" | translate }} ({{ selectedPlan.unit }})
              </h4>
              <div class="formCard__Block-headField">
                <div class="checkBox">
                  <span style="display: flex; flex-direction: column">
                    <mat-form-field class="datePicker">
                      <mat-date-range-input
                        [rangePicker]="picker"
                        style="position: relative; top: -9px"
                      >
                        <input
                          matStartDate
                          [placeholder]="'plans.startDate' | translate"
                          [(ngModel)]="startDate"
                        />
                        <input
                          matEndDate
                          [placeholder]="'plans.endDate' | translate"
                          [(ngModel)]="endDate"
                          (dateChange)="calculateDays()"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                        style="position: relative; top: -18px"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <mat-error *ngIf="daysExceedError">
                      {{ "plans.list.errorDays" | translate }}
                    </mat-error>
                  </span>

                  <label for="hours">{{
                    "plans.list.dateRange" | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div
              *ngIf="selectedPlan?.plan_type === 'day' && !preview"
              class="formCard__Block-body"
            >
              <div
                class="IntraDayEditForm__table"
                *ngIf="selectedPlan.consumptions?.length !== 0"
              >
                <table
                  mat-table
                  [dataSource]="dataSource"
                  class="mat-elevation-z8"
                >
                  <!-- Login Column -->
                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.day" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element; let i = index"
                      style="font-size: 18px; font-weight: 600"
                    >
                      {{ getDate(element, i, "day") }}
                    </td>
                  </ng-container>

                  <!-- Unit Column -->
                  <ng-container matColumnDef="consumption">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.list.consumption" | translate }} ({{
                        selectedPlan.unit
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <input
                        class="form-control"
                        min="0"
                        max="{{ UPPER_LIMIT }}"
                        [(ngModel)]="element.consumption"
                        type="number"
                      />
                    </td>
                  </ng-container>

                  <!-- Min Column -->
                  <ng-container matColumnDef="minimum">
                    <th mat-header-cell *matHeaderCellDef>
                      Minimum ({{ selectedPlan.unit }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <input
                        class="form-control"
                        min="0"
                        max="{{ UPPER_LIMIT }}"
                        [(ngModel)]="element.min"
                        type="number"
                      />
                    </td>
                  </ng-container>

                  <!-- Max Column -->
                  <ng-container matColumnDef="maximum">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.details.consumptions.max" | translate }} ({{
                        selectedPlan.unit
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <input
                        class="form-control"
                        min="0"
                        max="{{ UPPER_LIMIT }}"
                        [(ngModel)]="element.max"
                        type="number"
                      />
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.actions" | translate }}
                    </th>
                    <td
                      style="overflow: visible"
                      mat-cell
                      *matCellDef="let element"
                    >
                      <div class="actionsBtn">
                        <span class="tooltipBlock tooltipBlock--center">
                          <button
                            [ngStyle]="{
                              opacity: checkDisablity(element) ? '0.5' : '1'
                            }"
                            (click)="copyRowValues(element)"
                          >
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"
                              />
                            </svg>
                          </button>
                          <div class="tooltip">
                            {{ "plans.list.tooltip.copyPlan" | translate }}
                          </div>
                        </span>
                        <span class="tooltipBlock tooltipBlock--center">
                          <button
                            (click)="pasteRowValues(element)"
                            class="borderBtn"
                          >
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M104.6 48H64C28.7 48 0 76.7 0 112V384c0 35.3 28.7 64 64 64h96V400H64c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H80c0 17.7 14.3 32 32 32h72.4C202 108.4 227.6 96 256 96h62c-7.1-27.6-32.2-48-62-48H215.4C211.6 20.9 188.2 0 160 0s-51.6 20.9-55.4 48zM144 56a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM448 464H256c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16l140.1 0L464 243.9V448c0 8.8-7.2 16-16 16zM256 512H448c35.3 0 64-28.7 64-64V243.9c0-12.7-5.1-24.9-14.1-33.9l-67.9-67.9c-9-9-21.2-14.1-33.9-14.1H256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64z"
                              />
                            </svg>
                          </button>
                          <div class="tooltip">
                            {{ "plans.list.tooltip.pastePlan" | translate }}
                          </div>
                        </span>

                        <span class="tooltipBlock tooltipBlock--center">
                          <button
                            (click)="clearRowValues(element)"
                            class="redBtn"
                          >
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                          <div class="tooltip">
                            {{ "plans.list.tooltip.clearPlan" | translate }}
                          </div>
                        </span>
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumnsnIntradayPlan"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsnIntradayPlan"
                  ></tr>
                </table>
              </div>
            </div>
            <!-- day plan data end -->

            <!-- Intraday plan data start -->
            <div
              *ngIf="selectedPlan?.plan_type === 'intraday' && !preview"
              class="formCard__Block-head"
            >
              <h4 class="formCard__Block-title">
                {{ "plans.list.intradayCons" | translate }} ({{
                  selectedPlan.unit
                }})
              </h4>
              <div class="formCard__Block-headField">
                <div class="checkBox">
                  <!-- <mat-form-field class="datePicker">

                    <input matInput [matDatepicker]="picker2" [(ngModel)]="intradayStartDate"
                      style="position: relative; top: 7px" (dateChange)="calculateDay()" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"
                      style="position: relative; top: -9px"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field> -->
                  <span>
                    <mat-form-field class="datePicker">
                      <mat-date-range-input
                        [rangePicker]="picker2"
                        style="position: relative; top: -9px"
                      >
                        <input
                          matStartDate
                          [placeholder]="'plans.startDate' | translate"
                          [(ngModel)]="intradayStartDate"
                        />
                        <input
                          matEndDate
                          [placeholder]="'plans.endDate' | translate"
                          [(ngModel)]="intradayEndDate"
                          (dateChange)="calculateIntraDay()"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                        style="position: relative; top: -18px"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #picker2></mat-date-range-picker>
                    </mat-form-field>
                    <mat-error *ngIf="daysExceedError">
                      {{ "plans.list.errorDays" | translate }}
                    </mat-error>
                  </span>
                  <label for="hours">{{ "plans.startDate" | translate }}</label>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                selectedPlan?.plan_type === 'intraday' &&
                !preview &&
                multiIntraday
              "
              class="formCard__Block-body"
            >
              <mat-tab-group (selectedIndexChange)="onTabChange($event)">
                <mat-tab
                  *ngFor="let element of multiIntraday; index as i"
                  [label]="getDate(element, i, 'intraday')"
                >
                  <div class="IntraDayEditForm__table">
                    <table
                      mat-table
                      [dataSource]="dataSource"
                      class="mat-elevation-z8"
                    >
                      <!-- Login Column -->
                      <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ "plans.details.consumptions.time" | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          style="font-size: 18px; font-weight: 600"
                        >
                          {{ getHourName(element.hour) }}
                        </td>
                      </ng-container>

                      <!-- Email Column -->
                      <ng-container matColumnDef="consumption">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ "plans.details.consumptions.cons" | translate }}
                          ({{ selectedPlan.unit }})
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <input
                            class="form-control"
                            min="0"
                            max="{{ UPPER_LIMIT }}"
                            [(ngModel)]="element.consumption"
                            type="number"
                          />
                        </td>
                      </ng-container>

                      <!-- Interval Column -->
                      <ng-container matColumnDef="minimum">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ "plans.details.consumptions.min" | translate }} ({{
                            selectedPlan.unit
                          }})
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <input
                            class="form-control"
                            min="0"
                            max="{{ UPPER_LIMIT }}"
                            [(ngModel)]="element.min"
                            type="number"
                          />
                        </td>
                      </ng-container>

                      <!-- Role Column -->
                      <ng-container matColumnDef="maximum">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ "plans.details.consumptions.max" | translate }} ({{
                            selectedPlan.unit
                          }})
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <input
                            class="form-control"
                            min="0"
                            max="{{ UPPER_LIMIT }}"
                            [(ngModel)]="element.max"
                            type="number"
                          />
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ "plans.actions" | translate }}
                        </th>
                        <td
                          style="overflow: visible"
                          mat-cell
                          *matCellDef="let element"
                        >
                          <div class="actionsBtn">
                            <span class="tooltipBlock tooltipBlock--center">
                              <button
                                [ngStyle]="{
                                  opacity: checkDisablity(element) ? '0.5' : '1'
                                }"
                                (click)="copyRowValues(element)"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"
                                  />
                                </svg>
                              </button>
                              <div class="tooltip">
                                {{ "plans.list.tooltip.copyPlan" | translate }}
                              </div>
                            </span>
                            <span class="tooltipBlock tooltipBlock--center">
                              <button
                                (click)="pasteRowValues(element)"
                                class="borderBtn"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    d="M104.6 48H64C28.7 48 0 76.7 0 112V384c0 35.3 28.7 64 64 64h96V400H64c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H80c0 17.7 14.3 32 32 32h72.4C202 108.4 227.6 96 256 96h62c-7.1-27.6-32.2-48-62-48H215.4C211.6 20.9 188.2 0 160 0s-51.6 20.9-55.4 48zM144 56a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM448 464H256c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16l140.1 0L464 243.9V448c0 8.8-7.2 16-16 16zM256 512H448c35.3 0 64-28.7 64-64V243.9c0-12.7-5.1-24.9-14.1-33.9l-67.9-67.9c-9-9-21.2-14.1-33.9-14.1H256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64z"
                                  />
                                </svg>
                              </button>
                              <div class="tooltip">
                                {{ "plans.list.tooltip.pastePlan" | translate }}
                              </div>
                            </span>

                            <span class="tooltipBlock tooltipBlock--center">
                              <button
                                (click)="clearRowValues(element)"
                                class="redBtn"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                  />
                                </svg>
                              </button>
                              <div class="tooltip">
                                {{ "plans.list.tooltip.clearPlan" | translate }}
                              </div>
                            </span>
                          </div>
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumnsnIntradayPlan"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumnsnIntradayPlan
                        "
                      ></tr>
                    </table>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>

            <!-- Intraday plan data end -->

            <!-- Day Plan Preview -->
            <div
              class="IntraDayEditForm"
              *ngIf="selectedPlan?.plan_type === 'day' && preview"
            >
              <button class="editBtn btn button subBtn" (click)="goBack()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 72 72"
                  width="64px"
                  height="64px"
                >
                  <path
                    d="M38.406 22.234l11.36 11.36L28.784 54.576l-12.876 4.307c-1.725.577-3.367-1.065-2.791-2.79l4.307-12.876L38.406 22.234zM41.234 19.406l5.234-5.234c1.562-1.562 4.095-1.562 5.657 0l5.703 5.703c1.562 1.562 1.562 4.095 0 5.657l-5.234 5.234L41.234 19.406z"
                  />
                </svg>
                {{ "plans.list.btnEdit" | translate }}
              </button>
              <div class="PlanBox">
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.name" | translate }}</label>
                  <p>{{ selectedPlan.name }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{
                    "plans.details.description" | translate
                  }}</label>
                  <p>{{ selectedPlan.description }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.client" | translate }}</label>
                  <p>
                    {{
                      clientData?.name + " ( " + clientData?.shortName + " ) "
                    }}
                  </p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.date" | translate }}</label>
                  <p>
                    {{ selectedPlan.consumptions[0].day }}/{{
                      selectedPlan.consumptions[0].month
                    }}/{{ selectedPlan.consumptions[0].year }} -
                    {{
                      selectedPlan.consumptions[
                        selectedPlan.consumptions.length - 1
                      ].day
                    }}/{{
                      selectedPlan.consumptions[
                        selectedPlan.consumptions.length - 1
                      ].month
                    }}/{{
                      selectedPlan.consumptions[
                        selectedPlan.consumptions.length - 1
                      ].year
                    }}
                  </p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.unit" | translate }}</label>
                  <p>{{ selectedPlan.unit }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{
                    "plans.details.interval" | translate
                  }}</label>
                  <p>24 hours</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.plCons" | translate }}</label>
                  <p>{{ totalConsumption }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{
                    "plans.details.avgCons" | translate
                  }}</label>
                  <p>{{ avgConsumption }}</p>
                </div>
              </div>
              <div class="IntraDayEditForm__table">
                <h4>{{ "plans.details.consumptions.title" | translate }}</h4>
                <table
                  mat-table
                  [dataSource]="dataSource"
                  class="mat-elevation-z8"
                >
                  <!-- Login Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.day" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element; let i = index"
                      style="font-size: 18px; font-weight: 600"
                    >
                      {{ getDate(element, i, "day") }}
                    </td>
                  </ng-container>

                  <!-- Email Column -->
                  <ng-container matColumnDef="consumption">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.details.consumptions.cons" | translate }} ({{
                        selectedPlan.unit
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.consumption }}
                    </td>
                  </ng-container>

                  <!-- Interval Column -->
                  <ng-container matColumnDef="minimum">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.details.consumptions.min" | translate }} ({{
                        selectedPlan.unit
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.min }}
                    </td>
                  </ng-container>

                  <!-- Role Column -->
                  <ng-container matColumnDef="maximum">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "plans.details.consumptions.max" | translate }} ({{
                        selectedPlan.unit
                      }})
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.max }}
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumnsDayPlan"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsDayPlan"
                  ></tr>
                </table>
              </div>
            </div>
            <!-- Day Plan Preview -->

            <!-- IntraDay Plan Preview -->
            <div
              class="IntraDayEditForm"
              *ngIf="selectedPlan?.plan_type === 'intraday' && preview"
            >
              <button
                class="editBtn btn button subBtn"
                (click)="preview = false; btnText = 'plans.list.btnPreview'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 72 72"
                  width="64px"
                  height="64px"
                >
                  <path
                    d="M38.406 22.234l11.36 11.36L28.784 54.576l-12.876 4.307c-1.725.577-3.367-1.065-2.791-2.79l4.307-12.876L38.406 22.234zM41.234 19.406l5.234-5.234c1.562-1.562 4.095-1.562 5.657 0l5.703 5.703c1.562 1.562 1.562 4.095 0 5.657l-5.234 5.234L41.234 19.406z"
                  />
                </svg>
                {{ "plans.list.btnEdit" | translate }}
              </button>
              <div class="PlanBox">
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.name" | translate }}</label>
                  <p>{{ selectedPlan.name }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{
                    "plans.details.description" | translate
                  }}</label>
                  <p>{{ selectedPlan.description }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.date" | translate }}</label>

                  <p>
                    {{ multiIntraday[0]?.consumptions[0]?.day }}/{{
                      multiIntraday[0]?.consumptions[0]?.month
                    }}/{{ multiIntraday[0]?.consumptions[0]?.year }}
                    <span *ngIf="multiIntraday.length > 1"
                      >-
                      {{
                        multiIntraday[multiIntraday.length - 1]?.consumptions[0]
                          ?.day
                      }}/{{
                        multiIntraday[multiIntraday.length - 1]?.consumptions[0]
                          ?.month
                      }}/{{
                        multiIntraday[multiIntraday.length - 1]?.consumptions[0]
                          ?.year
                      }}</span
                    >
                  </p>
                </div>
                <div class="PlanBox__inner">
                  <label for="">{{ "plans.details.unit" | translate }} </label>
                  <p>{{ selectedPlan.unit }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for=""
                    >{{ "plans.details.interval" | translate }}
                  </label>
                  <p>{{ selectedPlan.interval }} minutes</p>
                </div>
                <div class="PlanBox__inner">
                  <label for=""
                    >{{ "plans.details.plCons" | translate }}
                  </label>
                  <p>{{ totalConsumption }}</p>
                </div>
                <div class="PlanBox__inner">
                  <label for=""
                    >{{ "plans.details.avgCons" | translate }}
                  </label>
                  <p>{{ avgConsumption }}</p>
                </div>
              </div>
              <div class="IntraDayEditForm__table">
                <h4>{{ "plans.details.consumptions.title" | translate }}</h4>

                <mat-tab-group (selectedIndexChange)="onTabChange($event)">
                  <mat-tab
                    *ngFor="let element of multiIntraday; index as i"
                    [label]="getDate(element, i, 'intraday')"
                  >
                    <div class="IntraDayEditForm__table">
                      <table
                        mat-table
                        [dataSource]="dataSource"
                        class="mat-elevation-z8"
                      >
                        <!-- Login Column -->
                        <ng-container matColumnDef="time">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "plans.details.consumptions.time" | translate }}
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            style="font-size: 18px; font-weight: 600"
                          >
                            {{ getHourName(element.hour) }}
                          </td>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="consumption">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "plans.details.consumptions.cons" | translate }}
                            ({{ selectedPlan.unit }})
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.consumption }}
                          </td>
                        </ng-container>

                        <!-- Interval Column -->
                        <ng-container matColumnDef="minimum">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "plans.details.consumptions.min" | translate }}
                            ({{ selectedPlan.unit }})
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.min }}
                          </td>
                        </ng-container>

                        <!-- Role Column -->
                        <ng-container matColumnDef="maximum">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "plans.details.consumptions.max" | translate }}
                            ({{ selectedPlan.unit }})
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.max }}
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumnsnIntradayPlanReview"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="
                            let row;
                            columns: displayedColumnsnIntradayPlanReview
                          "
                        ></tr>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
            <!-- IntraDay Plan Preview -->

            <!-- <div class="errorFormBlock" [hidden]="!error">
              <h4>Error</h4>
            </div> -->
          </div>
          <div class="formCard__footer">
            <button
              type="button"
              class="btn button subBtn"
              (click)="submitPlan()"
            >
              {{ btnText | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogTemplate>
  <h1
    mat-dialog-title
    style="
      font-weight: 600;
      font-size: 22px;
      border-bottom: 1px solid rgb(201, 201, 201);
    "
  >
    {{ "plans.toastr.success" | translate }}!
  </h1>
  <mat-dialog-content class="dialog-content">
    <h5 mat-dialog-title style="font-weight: 500">
      {{ "plans.create.dialog.dialog3" | translate }}
      {{ selectedPlan.name }}
      <br />
      {{ "plans.create.dialog.dialog7" | translate }}
    </h5>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: space-between; align-content: center"
  >
    <button
      class="theme-btn-yellow"
      mat-dialog-close
      (click)="goToConsumptionPlans()"
    >
      OK
    </button>
  </mat-dialog-actions>
</ng-template>
