import { Component, ViewChild } from '@angular/core';
import { AnalysisService } from 'src/app/analysis/analysis.service';
import uPlot from 'uplot';
import { Details, Meta } from 'ngx-uplot/public-api';
import pricesActual from 'src/data/testovaci-cierna-actual.json';
import pricesBackcast from 'src/data/testovaci-siva-backcast.json';
import pricesForecast from 'src/data/testovaci-farebna-forecast.json';

@Component({
  selector: 'app-backcast1day',
  templateUrl: './backcast1day.component.html',
  styleUrls: ['./backcast1day.component.scss'],
})
export class Backcast1dayComponent {
  timestamps: number[] = [];

  constructor(private analysisService: AnalysisService) { }

  @ViewChild('graf') graf: any;

  ngOnInit() {
    //console.log('Toto je on init GRAFU');
  }

  ngAfterViewInit() {
    this.nacitajUdajeGrafu();
  }

  nacitajUdajeGrafu() {
    this.analysisService.getPricesActualDataOld().subscribe({
      next: (res) => {
        //console.log('funkcia nacitajUdajeGrafu: ...Prisli data[0]...', res);
        let jsonData = res.data;
        const values: number[][] = [[], [], [], [], []];
        let mapActual = new Map<number, number>();
        jsonData.forEach((element:any) => {
          let date = element.d.substring(0, 16);
          const timeInMillisecond = new Date(date).getTime();
          const unixTimestamp = Math.floor(timeInMillisecond / 1000);
          mapActual.set(unixTimestamp, element.v);
          if (!this.timestamps.includes(unixTimestamp)) {
            this.timestamps.push(unixTimestamp);
          }
        });
        this.analysisService.getPricesBackcastDataOld().subscribe({
          next: (data) => {
            //console.log('funkcia nacitajUdajeGrafu: ...Prisli data[1]...', data);
            jsonData = data.data;
            let mapBackcast = new Map<number, number>();
            let lastValue = 0;
            jsonData.forEach((element:any) => {
              let date = element.d.substring(0, 16);
              const timeInMillisecond = new Date(date).getTime();
              const unixTimestamp = Math.floor(timeInMillisecond / 1000);
              mapBackcast.set(unixTimestamp, element.v);
              if (!this.timestamps.includes(unixTimestamp)) {
                this.timestamps.push(unixTimestamp);
              }
              lastValue = unixTimestamp;
            });
            mapBackcast.set(lastValue, 0);

            this.analysisService.getPricesForecastDataOld().subscribe({
              next: (result) => {
                //console.log('funkcia nacitajUdajeGrafu: ...Prisli data[2]...', result);
                jsonData = result[0].data;
                let mapForecast1 = new Map<number, number>();
                jsonData.forEach((element:any) => {
                  let date = element.d.substring(0, 16);
                  const timeInMillisecond = new Date(date).getTime();
                  const unixTimestamp = Math.floor(timeInMillisecond / 1000);
                  mapForecast1.set(unixTimestamp, element.v);
                  if (!this.timestamps.includes(unixTimestamp)) {
                    this.timestamps.push(unixTimestamp);
                  }
                });

                jsonData = result[1].data;
                let mapForecast2 = new Map<number, number>();
                jsonData.forEach((element:any) => {
                  let date = element.d.substring(0, 16);
                  const timeInMillisecond = new Date(date).getTime();
                  const unixTimestamp = Math.floor(timeInMillisecond / 1000);
                  mapForecast2.set(unixTimestamp, element.v);
                  if (!this.timestamps.includes(unixTimestamp)) {
                    this.timestamps.push(unixTimestamp);
                  }
                });

                jsonData = result[2].data;
                let mapForecast3 = new Map<number, number>();
                jsonData.forEach((element:any) => {
                  let date = element.d.substring(0, 16);
                  const timeInMillisecond = new Date(date).getTime();
                  const unixTimestamp = Math.floor(timeInMillisecond / 1000);
                  mapForecast3.set(unixTimestamp, element.v);
                  if (!this.timestamps.includes(unixTimestamp)) {
                    this.timestamps.push(unixTimestamp);
                  }
                });

                this.timestamps.sort();

                this.timestamps.forEach((element) => {
                  values[0].push(mapActual.get(element)!);
                  values[1].push(mapBackcast.get(element)!);
                  values[2].push(mapForecast1.get(element)!);
                  values[3].push(mapForecast2.get(element)!);
                  values[4].push(mapForecast3.get(element)!);
                });

                let data = [
                  new Float32Array(this.timestamps),
                  new Float32Array(values[1]),
                  new Float32Array(values[3]),
                  new Float32Array(values[4]),
                  new Float32Array(values[2]),
                  new Float32Array(values[0]),
                ];
                const opts = {
                  width: 1000,
                  height: 350,
                  title: 'Spot price',
                  series: [
                    {},
                    {
                      label: "Backcast",
                      width: 1,
                      stroke: 'rgb(196,216,224)',
                      fill: 'rgb(196,216,224)',
                    },
                    {
                      label: "Forecast",
                      width: 3,
                      stroke: 'rgb(169,139,254)',
                    },
                    {
                      label: "Forecast",
                      width: 2,
                      stroke: 'rgb(168,190,255)',
                    },
                    {
                      label: "Forecast",
                      width: 5,
                      stroke: 'rgb(197,38,114)',
                    },
                    {
                      label: "Actual",
                      width: 3,
                      stroke: 'black',
                    },
                  ],
                };
                
                let uee = new uPlot(opts, data, this.graf.nativeElement);

              },
              error: (e) => {
                //console.log('funkcia nacitajUdajeGrafu: ... CHYBA ... ', e);
              },
            });
          },
          error: (e) => {
            //console.log('funkcia nacitajUdajeGrafu: ... CHYBA ... ', e);
          },
        });
      },
      error: (e) => {
        //console.log('funkcia nacitajUdajeGrafu: ... CHYBA ... ', e);
      },
    });
  }

}
