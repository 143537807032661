
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
// import { TranslationService } from './app/translation.service';
// const translationService = new TranslationService(); // Create an instance of TranslationService

// translationService.detectLanguage()
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
