import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { User } from '../user';
import { Client } from '../client';
import { UserService } from 'src/app/user/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from './../../translation.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  userList: User[];
  newUser: User;
  accessRights: any = [];
  emailPattern = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  newUserMenuOpen: boolean = false;

  clientList: Client[] = [];
  userDetails: any = {
    firstName: 'Test',
    lastName: 'Test',
    email: 'Test@gmail.com',
    userRole: 'User',
  };
  loadingFinished: boolean = true;
  displayedColumns: string[] = ['id', 'login', 'email', 'role','registeredBy', 'updated_at','status','actions'];
  dataSource: MatTableDataSource<User>;

  loggedMasterUser:any
  selectedData:any
  clientData:any

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private toastr:ToastrService,
    private translate: TranslateService,
    private translationService: TranslationService
  ) {
    //this.setLang();
  }

  setLang(){
    let selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";
    this.translate.use(selectedLang);
  }

  ngOnInit() {
    //this.setLang();

    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data)=>{
        this.clientData=data
      })
    }
    this.initializeView();
  }

  initializeView() {
    this.loadingFinished = false;
    this.adminService.getUsers().subscribe({
      next: (data) => {
        if(this.loggedMasterUser.role=="master user"){
          // Filter data with same client_id
          data = data.filter((user:any) => user.client_id === this.loggedMasterUser.client_id);
          // Remove or filter out this.loggedMasterUser.id
          // data = data.filter((user:any) => user.id !== this.loggedMasterUser.id);
          this.constructUserList(data);
          this.dataSource = new MatTableDataSource(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingFinished = true;
        }else{
          this.constructUserList(data);
          this.dataSource = new MatTableDataSource(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingFinished = true;
        }
      },
    });
    // this.adminService.getClients().subscribe({
    //   next: (data) => {
    //     this.retrieveClientIds(data);
    //   },
    // });
    this.newUser = {
      client_id: this.loggedMasterUser.client_id,
      login: '',
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      fullName:this.newUser?.firstName+" "+this.newUser?.lastName
    };
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleNewUserMenu() {
    if(this.userDetails.firstName!=='' && this.newUserMenuOpen){
      this.accessRights = [
        {
          sourceTable: 'Client',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK', 'HU', 'CZ'],
        },
        {
          sourceTable: 'Client plan',
          attribute: ['Short Name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Short name'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Montel data',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK', 'HU', 'CZ'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['All', 'Actual consumption'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: [],
          filterValue: [],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['All', 'Region'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client short name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client short name'],
          filterValue: ['TELED'],
        },
      ];
      this.userDetails = {
        firstName: '',
        lastName: '',
        email: '',
        userRole: '',
      };
    }
    else if(!this.newUserMenuOpen){
      this.newUserMenuOpen = true;

      this.accessRights = [
        {
          sourceTable: 'Client',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK', 'HU', 'CZ'],
        },
        {
          sourceTable: 'Client plan',
          attribute: ['Short Name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Short name'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Montel data',
          attribute: ['All', 'Region', 'Address'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK', 'HU', 'CZ'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Client consumption plan',
          attribute: ['All', 'Actual consumption'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: [],
          filterValue: [],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['All', 'Region'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Region'],
          filterValue: ['SK'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client Plan'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client Plan'],
          filterValue: ['Overhead', 'Kitchen'],
        },
        {
          sourceTable: 'Purchase Electricity',
          attribute: ['Client short name'],
          crud: {
            c: false,
            r: false,
            u: false,
            d: false,
          },
          filterColumn: ['Client short name'],
          filterValue: ['TELED'],
        },
      ];
      this.userDetails = {
        firstName: '',
        lastName: '',
        email: '',
        userRole: '',
      };
    }else{
      this.newUserMenuOpen = false;
    }
  }

  constructUserList(data: User[]) {
    this.userList = [];
    data.forEach((userData) => {
      let user: User = {
        id: userData.id,
        client_id: userData.client_id,
        login: userData.login,
        email: userData.email,
        token: userData.token,
        firstName: userData.firstName,
        lastName: userData.lastName,
        fullName:userData.fullName,
        role: userData.role,
        lastLogin: userData.lastLogin,
        created_at: userData.created_at?.split('.')[0],
        updated_at: userData.updated_at?.split('.')[0],
        registeredBy:userData.registeredBy,
        status:userData.status
      };
      if (!user.created_at) {
        user.created_at = '[Unknown]';
      }
      if (!user.updated_at) {
        user.updated_at = '[Unknown]';
      }
      if (!user.role) {
        user.role = 'user';
      }
      this.userList.push(user);
    });
  }

  retrieveClientIds(data: Client[]) {
    this.clientList = [];
    data.forEach((clientData) => {
      let client: Client = {
        id: clientData.id,
        name: clientData.name,
        address: clientData.address,
        region: clientData.region,
        webpage: clientData.webpage,
        phone: clientData.phone,
        status: clientData.status,
        created_at: clientData.created_at?.split('T')[0],
        updated_at: clientData.updated_at?.split('T')[0],

      };
      if (client.created_at == undefined) {
        client.created_at = '[Unknown]';
      }
      if (client.updated_at == undefined) {
        client.updated_at = '[Unknown]';
      }
      this.clientList.push(client);
    });
  }

  openCofirmationDialog(data:any){
    this.selectedData=data
    this.dialog.open(this.dialogTemplate);
  }

  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  deleteClientUser() {
    this.adminService.deleteClientUser(this.selectedData.id).subscribe((data)=>{
      this.dialog.closeAll();
      this.initializeView()
      this.toastr.success('Client User Is Removed', 'Success');
    })
  }

  editClientUser(userId: number) {
    //console.log("User ID:", userId);
    this.routeChecker(`admin/user/${userId}`)
  }
}
