<title>Setup your password | MyEnergy</title>
<meta
  name="description"
  content="If you forgot or lost your password, you can change or reset it."
/>

<section class="custom_login_section">
  <div class="login_card_wrapper">
    <div class="custom_logo_section">
      <div class="logo-section">
        <img
          class="img-responsive"
          src="../../assets/logo/logo-green.png"
          alt=""
        />
      </div>
      <h3 class="logo_title">
        I Control <br />
        <span> MyEnergy </span>
      </h3>
    </div>

    <form
      (ngSubmit)="createClientMasterUser()"
      #form="ngForm"
      ngNativeValidate
      *ngIf="!showMessage && !showThankMessage"
    >
      <div class="">
        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>
                {{
                  "oneTimePassSetup.form.messages.registeredUser.title"
                    | translate
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'oneTimePassSetup.form.placeholders.masterClientName'
                    | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                name="masterClientName"
                (keydown)="space($event)"
                [(ngModel)]="newClientUser.masterClientName"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'oneTimePassSetup.form.placeholders.email' | translate
                "
                minlength="3"
                maxlength="50"
                required="true"
                name="email"
                (keydown)="space($event)"
                [(ngModel)]="newClientUser.email"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [placeholder]="
                  'oneTimePassSetup.form.placeholders.firstName' | translate
                "
                minlength="4"
                maxlength="50"
                required="true"
                name="clientshortName"
                (keydown)="space($event)"
                #clientShortName="ngModel"
                [(ngModel)]="newClientUser.fullName"
                readonly
              />
            </div>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input type="text" class="form-control" [placeholder]="'oneTimePassSetup.form.placeholders.lastName' | translate"  minlength="3" maxlength="100"
                required="true" name="lastName" (keydown)="space($event)" #clientOfficialName="ngModel"
                [(ngModel)]="newClientUser.lastName" readonly />
            </div>
          </div>
        </div>
        -->

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Role: user"
                minlength="3"
                maxlength="100"
                required="true"
                [(ngModel)]="newClientUser.role"
                name="lastName"
                (keydown)="space($event)"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>
                {{
                  "oneTimePassSetup.form.messages.passwordSetup.title"
                    | translate
                }}
              </h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                [placeholder]="
                  'oneTimePassSetup.form.placeholders.password' | translate
                "
                minlength="8"
                maxlength="100"
                required="true"
                name="password"
                (keydown)="space($event)"
                [(ngModel)]="newClientUser.password"
                #password="ngModel"
                (input)="checkPasswordStrength(password.value)"
              />
            </div>
            <!-- <div class="validate_info" *ngIf="passwordStrength">
                *Password Strength: {{ passwordStrength }}
              </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <input
              type="password"
              class="form-control"
              [placeholder]="
                'oneTimePassSetup.form.placeholders.confirmPassword' | translate
              "
              minlength="8"
              maxlength="100"
              required="true"
              name="confirmPassword"
              (keydown)="space($event)"
              [(ngModel)]="newClientUser.confirmPassword"
              #confirmPassword="ngModel"
            />
          </div>
        </div>

        <div class="row">
          <div
            class="validate_info"
            *ngIf="passwordStrength !== 'Strong' && passwordStrength !== ''"
          >
            {{
              "oneTimePassSetup.form.validationMessages.passwordStrength"
                | translate
            }}
          </div>
        </div>

        <div
          *ngIf="password.touched && confirmPassword.touched"
          style="display: flex; justify-content: center"
        >
          <p
            class="validate_info"
            *ngIf="password.value != confirmPassword.value"
          >
            {{
              "oneTimePassSetup.form.validationMessages.passwordMismatch"
                | translate
            }}
          </p>
        </div>

        <div class="row">
          <div class="col text-start">
            <button class="subBtn" type="submit">
              {{ "registrationPage.submitButton" | translate }}
            </button>
          </div>
        </div>
        <div class="login_footer_wrapper">
          <p>
            {{ "oneTimePassSetup.form.messages.forAnyQuery" | translate
            }}<a (click)="routeChecker('contactUs')">
              {{
                "oneTimePassSetup.form.messages.expiredLink.contactUs"
                  | translate
              }}</a
            >
            {{
              "oneTimePassSetup.form.messages.expiredLink.orUse" | translate
            }}
            <a (click)="routeChecker('helpdesk')" class="helpdesk">
              {{
                "oneTimePassSetup.form.messages.expiredLink.helpdesk"
                  | translate
              }}.</a
            >
            <!-- <button (click)="signUp()">signup</button> -->
          </p>
        </div>
      </div>
    </form>
    <div class="thank_you_message" *ngIf="showMessage">
      <h4>
        <span
          >{{ "oneTimePassSetup.form.messages.expiredLink.title" | translate }}
        </span>
      </h4>
      <h6>
        {{ "oneTimePassSetup.form.messages.expiredLink.body" | translate }}
        <br />
        {{ "oneTimePassSetup.form.messages.expiredLink.please" | translate
        }}<a (click)="routeChecker('contactUs')">
          {{
            "oneTimePassSetup.form.messages.expiredLink.contactUs" | translate
          }}</a
        >
        {{ "oneTimePassSetup.form.messages.expiredLink.orUse" | translate }}
        <a (click)="routeChecker('sign-up')" class="helpdesk">
          {{
            "oneTimePassSetup.form.messages.expiredLink.helpdesk" | translate
          }}.</a
        >
      </h6>

      <button (click)="routeChecker('login')" class="subBtn">
        {{ "oneTimePassSetup.form.buttons.ok" | translate }}
      </button>
    </div>
    <div class="thank_you_message" *ngIf="showThankMessage">
      <h4>
        {{ "oneTimePassSetup.form.messages.thankYou.thank" | translate }}
        <span>
          {{ "oneTimePassSetup.form.messages.thankYou.you" | translate }}
        </span>
      </h4>
      <h6>
        {{ "oneTimePassSetup.form.messages.thankYou.body1" | translate }}
        <br />
        {{ "oneTimePassSetup.form.messages.thankYou.body2" | translate }}
      </h6>

      <button (click)="routeChecker('login')" class="subBtn">
        {{ "oneTimePassSetup.form.buttons.ok" | translate }}
      </button>
    </div>
  </div>
</section>
