import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivityLogsService } from './activity-logs.service';
import { FormControl } from '@angular/forms';
import { AdminService } from '../admin/admin.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from '../translation.service';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss'],
})

export class ActivityLogsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  activities: any = [
    {
      key: 'consumptionplans',
      display: 'activity-logs.activity2',
    },
    {
      key: 'consumptionplans/day',
      display: 'activity-logs.activity3',
    },
    {
      key: 'consumptionplans/intraday',
      display: 'activity-logs.activity4',
    },
    {
      key: 'contactus',
      display: 'activity-logs.activity5',
    },
    {
      key: 'registrations',
      display: 'activity-logs.activity6',
    },
    {
      key: 'users',
      display: 'activity-logs.activity7',
    },
  ];
  activityLogs: any;
  startDate: any = new FormControl();
  endDate: any = new FormControl();
  userlist: any;
  payload: any;
  selectedUser: any = '';
  loadingFinished: boolean = true;
  clientData: any;
  displayedColumns: string[] = [
    'user_id',
    'activity',
    'description',
    'created_at',
    'ip_address',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;
  parsedData: any;
  loggedMasterUser: any;

  constructor(
    private activityService: ActivityLogsService,
    private adminService: AdminService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private translationService: TranslationService
  ) {
    //  let selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

    //  if ( ! selectedLang ) selectedLang = "en";
    //  this.translate.use(selectedLang);
  }

  ngOnInit() {
    /*
    this.activityService.getActivities().subscribe({
      next: (data: any) => {},
      error: (error: any) => {
          this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('activity-logs.validator.toastr.error1').subscribe((data2: string) => {
              this.toastr.error(data2,data1);
            });      
          });      
      }
    });
    */

    this.payload = {
      activity: '',
      dateFrom: null,
      dateTo: null,
    };

    this.loggedMasterUser = localStorage.getItem('userToken');

    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getUsers().subscribe({
        next: (data: any) => {
          if (
            this.loggedMasterUser.role == 'master user' ||
            this.loggedMasterUser.role == 'user'
          ) {
            this.userlist = data.filter(
              (item: any) => item.client_id == this.loggedMasterUser?.client_id
            );
          } else {
            this.userlist = data;
          }
        },
        error:( error: any )=> {
          this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('activity-logs.validator.toastr.error2').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        }
      });
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe({
        next: (data) => {
          this.clientData = data;
        },
        error:(error) =>{
          this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('activity-logs.validator.toastr.error3').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        }
      });

      this.getActivityLogs();
    } else {
      this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
        this.translate.get('activity-logs.validator.toastr.error1').subscribe({ 
          next: (data2: string) => {
            this.toastr.error(data2,data1);
          }
        });      
      });      
    }
  }

  getCreatedByName(id: number) {
    let user: any = this.userlist.find((item: any) => item.id === id);
    return user?.fullName?.charAt(0).toUpperCase() + user?.fullName?.slice(1) || user.login;
  }

  getActivityLogs() {
    if ( ! this.loggedMasterUser){
      this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
        this.translate.get('activity-logs.validator.toastr.error1').subscribe({ 
          next: (data2: string) => {
            this.toastr.error(data2,data1);
          }
        });      
      });      

      return;
    }

    this.loadingFinished = false;
    if (this.payload.dateFrom)
      this.payload.dateFrom = new Date(this.payload.dateFrom).toISOString();
    if (this.payload.dateTo)
      this.payload.dateTo = new Date(this.payload.dateTo).toISOString();
    this.activityService.getActivityLogs(this.payload).subscribe({
      next: (data) => {
        if (this.selectedUser)
          this.activityLogs = data.data.filter(
            (item: any) => item.user_id === +this.selectedUser
          );
        else this.activityLogs = data.data;

        this.activityLogs.sort((a: any, b: any) => {
          return (
           new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
        for (let plan of this.activityLogs) {
         plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
         plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');
        }
        // this.activityLogs = this.activityLogs.filter(
        //   (item: { description: string }) => item.description !== 'GET'
        // );
        this.dataSource = new MatTableDataSource(this.activityLogs);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loadingFinished = true;
      },
      error: ( error: any) => {
        this.translate.get('activity-logs.validator.toastr.error').subscribe((data1: string) => {
          this.translate.get('activity-logs.validator.toastr.error4').subscribe({ 
            next: (data2: string) => {
              this.toastr.error(data2,data1);
            }
          });      
        });      
      }
    });
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  viewModal(element: any) {
    this.parsedData = Object.entries(JSON.parse(element.data));
  }
  getMethodName(type: string) {
    if (type == 'POST') return 'CREATE';
    else if (type == 'PUT') return 'UPDATE';
    else return type;
  }
}
