import { Component } from '@angular/core';
import { HelpdeskService } from './helpdesk.service';
import { PhoneState } from './phone-state';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})

export class HelpdeskComponent {
  request: boolean = true;
  response: boolean = !this.request;  

  constructor(private helpdeskService: HelpdeskService) {
    helpdeskService.request.subscribe({
      next: (request: boolean) => {
        this.request = request;
        this.response = !this.request;
      }
    })
  }

}
