import { Component, ElementRef, OnInit } from '@angular/core';
import { UserService } from './user/user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from './header.service';
import { TranslationService } from './translation.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})

export class HeaderComponent implements OnInit {
  loggedIn$ = this.userService.isLoggenIn;
  isLoggedIn: boolean = false;
  selectedLanguage: any | null;
  languageOptions = [
    { label: 'English', value: 'en',image:"../assets/images/english.png" },
    { label: 'Slovenský', value: 'sk',image:"../assets/images/slovak.png" },
    { label: 'Český', value: 'cs',image:"../assets/images/czech.png" },
    { label: 'Hungarian', value: 'hu',image:"../assets/images/hungary.png" },
    { label: 'Polski', value: 'pl',image:"../assets/images/polish.png" },
  ];

  showHeaderBar: boolean = true;

  subscription: Subscription;
  constructor(
    public userService: UserService,
    private router: Router,
    private _eref: ElementRef,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private translationService: TranslationService
  ) {
    userService.isLoggenIn.subscribe({
      next: (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      },
    });

    headerService.showHeaderBar.subscribe({
      next: (showHeaderBar:boolean) => {this.showHeaderBar = showHeaderBar}
    })

    var selectedLang:any
    setTimeout(()=>{
      selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

      this.translateService.use(selectedLang);
      this.selectedLanguage = this.languageOptions.find(language => language.value === selectedLang);
      if (this.selectedLanguage && this.selectedLanguage?.value) {
        setTimeout(()=>{
          const currentUrl = this.router.url.split('/').slice(2).join('/');
          //console.log()
          this.router.navigate([`${this.selectedLanguage.value}/${currentUrl}`]);
        })
        this.translateService.setDefaultLang(this.selectedLanguage.value);
        // this.translateService.use(this.selectedLanguage.value);
        this.selectedLanguage=this.languageOptions.find(language => language.value === this.selectedLanguage.value);
      } else {
        this.selectedLanguage = this.languageOptions.find(language => language.value === 'en');
        this.translateService.setDefaultLang(this.selectedLanguage.value);
        this.translateService.use(this.selectedLanguage.value);
        sessionStorage.setItem('language', 'en');
      }
    })
  }

  showDropdownAnalysisMenu: boolean = false;
  showDropdownForecastMenu: boolean = false;
  showDropdownBackcastMenu: boolean = false;
  showDropdownPlanMenu: boolean = false;
  showDropdownOrderMenu: boolean = false;
  showDropdownAdminMenu: boolean = false;
  showDropdownNavigationBar: boolean = false;
  showUserDropdown: boolean = false;
  showBtnDropdown: boolean = false;

  showMenu: boolean = false;

  user: any;
  ngOnInit() {
    this.subscription = this.loggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.getUser();
      }
    });
  }
  isActiveRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  changeLanguage(event: any): void {
    this.selectedLanguage = event.value;
    if (this.selectedLanguage) {
      this.translateService.use(this.selectedLanguage.value);
      sessionStorage.setItem('language', this.selectedLanguage.value);
      const currentUrl = this.router.url.split('/').slice(2).join('/');
      //console.log("header ",this.router.url.split('/').slice(2).join('/'))
      this.router.navigate([`${this.selectedLanguage.value}/${currentUrl}`]);
    }

    if ( this.showBtnDropdown ) this.togleBtnDropdown();
    if ( this.showMenu ) this.onNavToggleClick();
  }

  getUser() {
    let user = localStorage.getItem('userToken');
    if (user) {
      user = JSON.parse(user);
      this.user = user;
    }
  }

  getDisplayName() {
    let name = this.user.fullName || this.user.login;
    return name.charAt(0).toUpperCase();
  }

  logout() {
    this.showUserDropdown = false;
    this.showBtnDropdown  = false;
    this.userService.logout();
    this.router.navigate([`${this.selectedLanguage.value}/login`]);
  }

  toogleDropdownAnalysisMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownAnalysisMenu;
    this.disableAllMenu();
    this.showDropdownAnalysisMenu = !toggle;
  }
  
  onNavToggleClick() {
    this.showMenu = !this.showMenu;
  }

  toogleDropdownForecastMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownForecastMenu;
    this.disableAllMenu();
    this.showDropdownForecastMenu = !toggle;
  }

  toogleDropdownBackcastMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownBackcastMenu;
    this.disableAllMenu();
    this.showDropdownBackcastMenu = !toggle;
  }

  toogleDropdownPlanMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownPlanMenu;
    this.disableAllMenu();
    this.showDropdownPlanMenu = !toggle;
  }

  toogleDropdownOrderMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownOrderMenu;
    this.disableAllMenu();
    this.showDropdownOrderMenu = !toggle;
  }

  toggleDropdownAdminMenu(event: Event): void {
    event.preventDefault();
    let toggle = this.showDropdownAdminMenu;
    this.disableAllMenu();
    this.showDropdownAdminMenu = !toggle;
  }

  toogleDropdownNavigationBar(event: Event): void {
    event.preventDefault();
    this.showDropdownNavigationBar = !this.showDropdownNavigationBar;
  }

  togleUserDropdown(){
    this.showUserDropdown = !this.showUserDropdown;
  }

  clientUsers(){
    this.showUserDropdown = false;
    this.routeChecker('admin/users');
  }

  togleBtnDropdown(){
    this.showBtnDropdown = !this.showBtnDropdown;
  }

  switchToLogin(){
    if ( this.showBtnDropdown ) this.togleBtnDropdown();
    if ( this.showMenu ) this.onNavToggleClick();
    this.routeChecker('login');
  }

  switchToRegister(){
    if ( this.showBtnDropdown ) this.togleBtnDropdown();
    if ( this.showMenu ) this.onNavToggleClick();
    this.routeChecker('sign-up');
  }

  disableAllMenu() {
    this.showDropdownAnalysisMenu = false;
    this.showDropdownForecastMenu = false;
    this.showDropdownBackcastMenu = false;
    this.showDropdownPlanMenu = false;
    this.showDropdownOrderMenu = false;
    this.showDropdownAdminMenu = false;
    this.showDropdownNavigationBar = false;
    this.showUserDropdown = false;
    this.showBtnDropdown = false;
    this.showMenu = false;
  }

  mojPokus(){
   this.disableAllMenu();
  }

  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.disableAllMenu();
    }
  }
}
