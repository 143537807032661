import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalysisActualComponent } from './analysis-actual/analysis-actual.component';
import { Analysis1dayComponent } from './analysis1day/analysis1day.component';
import { AuthInterceptor } from '../user/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Analysis1weekComponent } from './analysis1week/analysis1week.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/i18n/', '.json');
}

// const routers: Routes = [

//   { path: ':id',
//     component: EditProjectComponent
//   },
//   { path: '',
//   component: ProjectListComponent
//   }
// ]

@NgModule({
  declarations: [
    AnalysisActualComponent,
    Analysis1dayComponent,
    Analysis1weekComponent
  ],
  imports: [
    CommonModule , RouterModule,  FormsModule,
    //,RouterModule.forChild(routers)
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ],
  exports: [

  ],
  providers:[
    {provide:HTTP_INTERCEPTORS, useClass:AuthInterceptor, multi:true}
  ]
})
export class AnalysisModule { }
