import {
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PlanService } from '../plan.service';
import { Plan } from 'src/app/shared/plan';
import { UserService } from 'src/app/user/user.service';
import { UserToken } from 'src/app/user/user-token';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/admin/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss'],
})
export class PlanListComponent {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  // TOP SECTION
  planId: any = 'consumptionPlan';
  selectedPlan: any;
  consumptionPlans: any = [];

  UPPER_LIMIT: number = 999;

  preview: boolean = false;
  btnText: string = 'plans.list.btnPreview';

  // TEXTS
  dayTypeTitles: string[] = ['Workdays', 'Weekends', 'Holidays'];
  timeRangeTitles: string[] = [
    'Before Working Time',
    'During Working Time',
    'After Working Time',
  ];
  hoursInDay: string[][] = [[], []];
  rangeTimeTitles: string[][] = [[], [], []];

  // // CONSTANT
  error: string = '';

  startDate: any = new FormControl();
  endDate: any = new FormControl();
  intradayStartDate: any = new FormControl();
  intradayEndDate: any = new FormControl();

  daysExceedError: boolean = false;

  dayConsumptions: any = [];
  user: any;
  displayedColumnsnIntradayPlan: string[] = [
    'time',
    'consumption',
    'minimum',
    'maximum',
    'actions',
  ];
  displayedColumnsnIntradayPlanReview: string[] = [
    'time',
    'consumption',
    'minimum',
    'maximum',
  ];
  displayedColumnsDayPlan: string[] = [
    'date',
    'consumption',
    'minimum',
    'maximum',
  ];
  dataSource: MatTableDataSource<any>;
  clientData: any;
  userList: any;

  clipBoardConsumption: any = 0;
  clipBoardMin: any = 0;
  clipBoardMax: any = 0;

  totalConsumption: any = 0;
  avgConsumption: any = 0;
  multiIntraday: any = [];

  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private planService: PlanService,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private adminService: AdminService,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.planId = Number(this.route.snapshot.paramMap.get('id'));
    this.getAllPlans();
    this.adminService.getUsers().subscribe({
      next: (data) => {
        this.userList = data;
      },
    });
    this.user = localStorage.getItem('userToken');
    if (this.user) {
      this.user = JSON.parse(this.user);
      this.planId = Number(this.route.snapshot.paramMap.get('id'));
      this.adminService.getClient(this.user.client_id).subscribe((data) => {
        this.clientData = data;
      });
    }

    if (this.planId) {
      this.planService.getPlan(this.planId).subscribe((res) => {
        this.selectedPlan = res;
        
        if ( this.selectedPlan.consumptions[0] ){
          if (this.selectedPlan.plan_type === 'intraday') {
            this.transformConsumptions(this.selectedPlan);
            this.getDateFromApi(
              this.selectedPlan?.consumptions[0]?.year,
              this.selectedPlan?.consumptions[0]?.month,
              this.selectedPlan?.consumptions[0]?.day
            );
          } else if (this.selectedPlan.plan_type === 'day') {
            this.getDateFromApiDay(
              this.selectedPlan?.consumptions[0].year,
              this.selectedPlan?.consumptions[0].month,
              this.selectedPlan?.consumptions[0].day,
              this.selectedPlan?.consumptions[
                this.selectedPlan?.consumptions.length - 1
              ].year,
              this.selectedPlan?.consumptions[
               this.selectedPlan.consumptions.length - 1
              ].month,
              this.selectedPlan?.consumptions[
                this.selectedPlan?.consumptions.length - 1
              ].day
            );
          }

          this.dataSource = new MatTableDataSource(
            this.selectedPlan.consumptions
          );
        }

        this.cdr.detectChanges();
      });    
    }
  }

  transformConsumptions(data: any) {
    const grouped: any = {};

    // Group consumptions by date
    data.consumptions.forEach((consumption: any) => {
      const { year, month, day } = consumption;
      const dateKey = `${year}-${month}-${day}`;

      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }

      grouped[dateKey].push(consumption);
    });

    // Create the new data structure
    const transformedData = Object.keys(grouped).map((dateKey) => {
      return {
        ...data,
        consumptions: grouped[dateKey],
      };
    });
    this.multiIntraday = transformedData;
    this.selectedPlan.consumptions = this.multiIntraday[0].consumptions;
    this.dataSource = new MatTableDataSource(this.selectedPlan.consumptions);
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  getAllPlans() {
    forkJoin({
      dayPlans: this.planService.getPlans('/day'),
      intradayPlans: this.planService.getPlans('/intraday'),
    }).subscribe((results) => {
      this.consumptionPlans = [
        ...results.dayPlans.data,
        ...results.intradayPlans.data,
      ];
    });
  }
  
  getDateFromApi(year: number, month: number, day: number) {
    this.intradayStartDate = new Date(year, month - 1, day);
  }
  getDateFromApiDay(
    startYear: number,
    startMonth: number,
    startDay: number,
    endYear: number,
    endMonth: number,
    endDay: number
  ) {
    this.startDate = new Date(startYear, startMonth - 1, startDay);
    this.endDate = new Date(endYear, endMonth - 1, endDay);
  }

  setPlanType(selectedItem: any) {
    this.selectedPlan = this.consumptionPlans.find(
      (item: any) => item.id === Number(selectedItem.value)
    );
  }
  calculateIntraDay() {
    this.multiIntraday = [];
    let startDate: any;
    let endDate: any;

    if (this.intradayStartDate){
      startDate = new Date(this.intradayStartDate);
    }

    if ( this.intradayEndDate ){
      endDate = new Date(this.intradayEndDate);
    }

    let diff = (endDate.getTime() - startDate.getTime()) + 1;
    const numberOfDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (numberOfDays < 51) {
      this.daysExceedError = false;
      for (let i = 0; i < numberOfDays; i++) {
        let plan = { ...this.selectedPlan };
        const datesArray = [];
        if (i === 0) startDate.setDate(startDate.getDate());
        else startDate.setDate(startDate.getDate() + 1);
        for (let hour = 0; hour < 24; hour++) {
          datesArray.push({
            consumption_plan_id: this.selectedPlan.id,
            hour: hour,
            consumption: 0.0,
            min: 0.0,
            max: 0.0,
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
          });
        }
        plan.consumptions = datesArray;
        this.multiIntraday.push(plan);
      }

      this.selectedPlan.consumptions = this.multiIntraday[0].consumptions;
      this.dataSource = new MatTableDataSource(this.selectedPlan.consumptions);
      this.dataSource.sort = this.sort;
    } else {
      this.daysExceedError = true;
    }
  }

  calculateDays() {
    let startDate: any;
    let endDate: any;

    if (this.startDate){
      startDate = new Date(this.startDate);
    }

    if ( this.endDate ){
      endDate = new Date(this.endDate);
    }

    let diff = (endDate.getTime() - startDate.getTime()) + 1;
    const numberOfDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (numberOfDays < 51) {
      this.daysExceedError = false;
      const datesArray = [];

      for (let i = 0; i < numberOfDays; i++) {
        if (i === 0) startDate.setDate(startDate.getDate());
        else startDate.setDate(startDate.getDate() + 1);
        datesArray.push({
          consumption_plan_id: this.selectedPlan.id,
          hour: 1,
          consumption: 0.0,
          min: 0.0,
          max: 0.0,
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate(),
        });
      }
      this.selectedPlan.consumptions = datesArray;
      this.dataSource = new MatTableDataSource(this.selectedPlan.consumptions);
      this.dataSource.sort = this.sort;

      //console.log("3. selectedPlan = ", this.selectedPlan);
      //console.log(this.selectedPlan.consumptions?.length);
    } else {
      this.daysExceedError = true;
    }
  }

  getHourName(hour: number) {
    let hourName;
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00 hour`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00 hour`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00 hour`;
    }
    return `${hour + 1}. ${hourName}`;
  }
  getIntervals(value: any) {
    let interval = '';
    if (value.plan_type === 'day') {
      interval = '24 hours';
    } else {
      if (value.interval === '60') return (interval = 'Hourly');
      if (value.interval === '30') return (interval = 'Half-Hourly');
      if (value.interval === '15') return (interval = 'Quarterly');
    }
    return interval;
  }
  getCreatedAt(value: any) {
    let date = value.split('.')[0].replace('T', ' ');
    return date;
  }
  getCreatedByName(id: number) {
    if ( this.userList ){
      let user: any = this.userList.find((item: any) => item.id === id);
      return user.fullName.charAt(0).toUpperCase() + user.fullName.slice(1);
    } else {
      return "";
    }
  }
  copyRowValues(data: any) {
    if (
      (!data.consumption && !data.min && !data.max) ||
      (data.consumption == '0.00' && data.min == '0.00' && data.max == '0.00')
    ) {
      return;
    }
    this.clipBoardConsumption = data.consumption;
    this.clipBoardMin = data.min;
    this.clipBoardMax = data.max;
  }
  checkDisablity(data: any) {
    return (
      (!data.consumption && !data.min && !data.max) ||
      (data.consumption == '0.00' && data.min == '0.00' && data.max == '0.00')
    );
  }
  pasteRowValues(data: any) {
    data.consumption = this.clipBoardConsumption;
    data.min = this.clipBoardMin;
    data.max = this.clipBoardMax;
  }
  clearRowValues(data: any) {
    data.consumption = 0.0;
    data.min = 0.0;
    data.max = 0.0;
  }
  getDate(data: any, index: number, type: string) {
    if (type == 'intraday') {
      const datePipe = new DatePipe('en-US');
      const date = new Date(
        data.consumptions[0].year,
        data.consumptions[0].month - 1,
        data.consumptions[0].day
      );
      const formattedDate = datePipe.transform(date, 'EEE yy-MM-dd');
      return `${index + 1}. ${formattedDate}`;
    } else {
      const datePipe = new DatePipe('en-US');
      const date = new Date(data.year, data.month - 1, data.day);
      const formattedDate = datePipe.transform(date, 'EEE yy-MM-dd');
      return `${index + 1}. ${formattedDate}`;
    }
  }
  submitPlan() {
    if (this.btnText === 'plans.list.btnPreview') {
      this.preview = true;
      this.btnText = "plans.list.btnSubmit";

      if (this.selectedPlan.plan_type === 'intraday') {
        this.dataSource = new MatTableDataSource(this.multiIntraday[0].consumptions);
        this.dataSource.sort = this.sort;
        this.totalConsumption = 0;
        this.avgConsumption = 0;
        for (let day of this.multiIntraday) {
          let value = day.consumptions
            .map((t: any) => t.consumption)
            .reduce((acc: any, value: any) => Number(acc) + Number(value), 0);
          this.totalConsumption += value;

          this.avgConsumption += value / day.consumptions.length;
        }
      } else {
        this.totalConsumption = this.selectedPlan.consumptions
          .map((t: any) => t.consumption)
          .reduce((acc: any, value: any) => Number(acc) + Number(value), 0);
        this.avgConsumption =
          this.totalConsumption / this.selectedPlan.consumptions.length;
          this.dataSource = new MatTableDataSource(this.selectedPlan.consumptions);
          this.dataSource.sort = this.sort;
      }
    } else {
      if (this.selectedPlan.plan_type === 'intraday') {
        this.selectedPlan.consumptions = [];
        this.multiIntraday.forEach((element: any) => {
          this.selectedPlan.consumptions = [
            ...this.selectedPlan.consumptions,
            ...element.consumptions,
          ];
        });
      }
      this.selectedPlan.status = 'active';
      this.planService
        .updatePlan(this.selectedPlan.id, this.selectedPlan)
        .subscribe({
          next: (data) => {
            if (data) {
              this.dialog.open(this.dialogTemplate);

              this.translate.get('plans.toastr.updateSuccess').subscribe((data1: string) => {
                this.translate.get('plans.toastr.success').subscribe((data2: string) => {
                  this.toastr.success(data1,data2);
                });      
              });
            }
          },
          error: (err) => {
            this.toastr.error(
              'An error occurred during user updation',
              'Error'
            );
          },
        });
    }
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  goToConsumptionPlans() {
    this.preview = false;
    this.btnText = 'plans.list.btnPreview';
    this.dialog.closeAll();
    this.routeChecker('plans');
  }

  onTabChange(data: any) {
    this.selectedPlan.consumptions = this.multiIntraday[data].consumptions;
    this.dataSource = new MatTableDataSource(this.selectedPlan.consumptions);
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  goBack(){
    this.preview = false;
    this.btnText = 'plans.list.btnPreview';
    this.dataSource = new MatTableDataSource(this.multiIntraday[0].consumptions)
  }
}
