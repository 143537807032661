import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { PlanService } from '../plan/plan.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from '../user/user';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-data-export-services',
  templateUrl: './data-export-services.component.html',
  styleUrls: ['./data-export-services.component.scss']
})
export class DataExportServicesComponent {
  userList: User[];
  dateFrom: string;
  dateUntil: string;
  dateUntillData: string

  loggedMasterUser: any;
  clientData: any;
  maxDate: any

  selectedDevice = ''

  loadingDay: boolean = false;
  errorDay: string = "";

  loadingIntraday: boolean = false;
  errorIntraday: string = "";

  dayConsumptionPlans: any = [];
  intradayConsumptionPlans: any = [];

  isLoading: boolean = false;

  // pagination variables, arrays, first for dayPLans, second for intradayPlans

  paginatorValues: number[][] = [];  // 0 - day plan, 1 - intraday plan

  // paginatorValues[][0] // items per page
  // paginatorValues[][1] // current page
  // paginatorValues[][2] // last page
  // paginatorValues[][3] // total items
  // paginatorValues[][4] // first item
  // paginatorValues[][5] // last item

  userDetails: any = {
    firstName: 'Test',
    lastName: 'Test',
    email: 'Test@gmail.com',
    userRole: 'User',
  };

  loadingFinished: boolean = true;

  displayedColumns: string[] = [
    'select',
    'name',
    'startDate',
    'created_at',
    'status',
  ];
  displayedColumnsDayPlans: string[] = [
    'select',
    'name',
    'startDate',
    'created_at',
    'status',
  ];
  dayPlanDisclaimer: boolean = false;
  intradayPlanDisclaimer: boolean = false;

  dayPlansDataSource: MatTableDataSource<any>;
  intradayPlansDataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  selection2 = new SelectionModel<any>(true, []);
  exportType: string
  selectedPlans:number

  @ViewChild('dayPaginator') dayPaginator: MatPaginator;
  @ViewChild('intradayPaginator') intradayPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dialogTemplate2') dialogTemplate2: TemplateRef<any>;

  constructor(private adminService: AdminService, private planService: PlanService,
    private cdr: ChangeDetectorRef,  private dialog: MatDialog,
  ) {
    this.setInitialDates()
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data) => {
        this.clientData = data;
      });
    }
    this.dayPlansDataSource = new MatTableDataSource<any>([]);
    this.intradayPlansDataSource = new MatTableDataSource<any>([]);

    this.paginatorValues[0] = [];
    this.paginatorValues[1] = [];

    this.paginatorValues[0][0] = 5;    // items per page of day plans
    this.paginatorValues[0][1] = 0;    // current page of day plans
    this.paginatorValues[0][2] = 0;    // last page of day plans
    this.paginatorValues[0][3] = 0;    // total items of day plans
    this.paginatorValues[0][4] = 1;    // first item of page of day plans
    this.paginatorValues[0][5] = 5;    // last item  of page of day plans

    this.paginatorValues[1][0] = 5;    // items per page of intraday plans
    this.paginatorValues[1][1] = 0;    // current page of intraday plans
    this.paginatorValues[1][2] = 0;    // last page of of intraday plans
    this.paginatorValues[1][3] = 0;    // total items of of intraday plans
    this.paginatorValues[1][4] = 1;    // first item of page of intraday plans
    this.paginatorValues[1][5] = 5;    // last item  of page of intraday plans
  }
  ngOnInit() {
    this.adminService.getUsers().subscribe({
      next: (data) => {
        this.userList = data;
      },
    });
    this.getAllPlans()
  }
  setInitialDates() {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate() + 21);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    this.maxDate = this.dateFrom
  }
  checkSelectedPlans(){
    this.selectedPlans=this.selection.selected.length + this.selection2.selected.length
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.intradayPlansDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.intradayPlansDataSource.data);
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  isAllSelected2() {
    const numSelected = this.selection2.selected.length;
    const numRows = this.dayPlansDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows2() {
    if (this.isAllSelected2()) {
      this.selection2.clear();
      return;
    }

    this.selection2.select(...this.dayPlansDataSource.data);
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel2(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection2.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  openExportDailog() {
    if(this.isLoading) return
    this.dialog.open(this.dialogTemplate2);
  }
  exportPlans() {
    this.isLoading=true
    let consumptionPLanIds = []
    for (let item of this.selection.selected) {
      consumptionPLanIds.push(item.id)
    }
    for (let item of this.selection2.selected) {
      consumptionPLanIds.push(item.id)
    }
    let payload = {
      clientId: this.clientData.id,
      dateFrom: this.dateFrom,
      dateTo: this.dateUntil,
      consumptionPlanIds: consumptionPLanIds
    }

    let requestOptions = {
      responseType: 'blob' as 'json',
    };

    this.planService.exportConsumptionsPlansExportSubsite(payload, requestOptions).subscribe(
      (response: Blob) => {
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Data Export.${this.dateFrom}-${this.dateUntil}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
        this.isLoading=false
      },
      (error) => {
        console.error('Error exporting consumption plans:', error);
      }
    );
  }

  handlePage(event: any, index: number) {
    this.paginatorValues[index][1] = event.pageIndex;
    // this.paginator[index][0] = event.pageSize;                       // not working for now
    this.paginatorValues[index][0] = 5;

    // console.log(this.paginatorValues);

    if (index === 0) {
      this.getDayPlan();
    }

    if (index === 1) {
      this.getIntradayPlan();
    }
  }

  getDayPlan() {
    this.loadingDay = true;
    this.errorDay = "";

    dayPlans: this.planService.getPlans('/day?page=' + (this.paginatorValues[0][1] + 1))
      .subscribe({
        next: (results) => {
          this.dayConsumptionPlans = results?.data;

          this.paginatorValues[0][0] = 5;                                            // items per page of day plans
          this.paginatorValues[0][1] = (results.meta?.current_page - 1);  // current page of day plans
          this.paginatorValues[0][2] = (results.meta?.last_page - 1);     // last page of day plans
          this.paginatorValues[0][3] = results.meta?.total;                 // total items of day plans
          this.paginatorValues[0][4] = results.meta?.from;                  // first item of page of day plans
          this.paginatorValues[0][5] = results.meta?.to;                    // last item  of page of day plans

          //console.log("Day\n", results);

          for (let plan of this.dayConsumptionPlans) {
            plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
            plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

            plan.startDate = this.getDate(plan.consumptions[0]);
            plan.endDate = this.getDate(
              plan.consumptions[plan.consumptions.length - 1]
            );
          }

          this.dayConsumptionPlans.sort((a: any, b: any) => {
            let dateA: any = new Date(a.startDate),
              dateB: any = new Date(b.startDate);
            return dateA - dateB;
          });

          this.dayPlansDataSource = new MatTableDataSource(
            this.dayConsumptionPlans
          );
          //this.dayPlansDataSource.paginator = this.dayPaginator;
          this.dayPlansDataSource.sort = this.sort;
          this.cdr.detectChanges()
          this.loadingDay = false;
        },
        error: (error) => { //console.log(error);
          this.errorDay = "plans.error.network";
          this.loadingDay = false;
        }
      });
  }

  getIntradayPlan() {
    this.loadingIntraday = true;
    this.errorIntraday = "";

    intradayPlans: this.planService.getPlans('/intraday?page=' + (this.paginatorValues[1][1] + 1))
      .subscribe({
        next: (results: any) => {
          this.intradayConsumptionPlans = results?.data;

          this.paginatorValues[1][0] = 5;                                                 // items per page of day plans
          this.paginatorValues[1][1] = (results.meta?.current_page - 1);  // current page of day plans
          this.paginatorValues[1][2] = (results.meta?.last_page - 1);     // last page of day plans
          this.paginatorValues[1][3] = results.meta?.total;                 // total items of day plans
          this.paginatorValues[1][4] = results.meta?.from;                  // first item of page of day plans
          this.paginatorValues[1][5] = results.meta?.to;                    // last item  of page of day plans

          //console.log("intraDay\n", results);

          for (let plan of this.intradayConsumptionPlans) {
            plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
            plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

            plan.startDate = this.getDate(plan.consumptions[0]);
            plan.endDate = this.getEndDateIntraday(plan.consumptions[0]);
          }

          this.intradayConsumptionPlans.sort((a: any, b: any) => {
            let dateA: any = new Date(a.startDate),
              dateB: any = new Date(b.startDate);
            return dateA - dateB;
          });

          this.intradayPlansDataSource = new MatTableDataSource(
            this.intradayConsumptionPlans
          );
          //this.intradayPlansDataSource.paginator = this.intradayPaginator;
          this.intradayPlansDataSource.sort = this.sort;
          this.cdr.detectChanges()

          this.loadingIntraday = false;
        },
        error: (error: any) => { //console.log(error);
          this.loadingIntraday = false;
          this.errorIntraday = "plans.error.network";
        }
      });
  }

  getAllPlans() {
    this.getDayPlan();
    this.getIntradayPlan();
  }
  getDate(data: any) {
    if (data) {
      let month;
      let day;
      if (data.month - 1 <= 9) {
        month = `0${data.month - 1}`;
      } else {
        month = `${data.month - 1}`;
      }
      if (data.day - 1 <= 9) {
        day = `0${data.day}`;
      } else {
        day = `${data.day}`;
      }
      let formattedDate = `${data.year}-${month}-${day}`;
      formattedDate += ' 00:00:00';
      return formattedDate;
    } else {
      return '----';
    }
  }
  getEndDateIntraday(data: any) {
    if (data) {
      let year = data.year;
      let month = data.month;
      let day = data.day;
      let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        daysInMonth[1] = 29;
      }
      day++;
      if (day > daysInMonth[month - 1]) {
        day = 1;
        month++;
      }
      if (month > 12) {
        month = 1;
        year++;
      }
      let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day
        }`;
      formattedDate += ' 00:00:00';
      return formattedDate;
    } else {
      return '----';
    }
  }
  getCreatedByName(id: number) {
    let user: any = this.userList.find((item: any) => item.id === id);
    return user.fullName.charAt(0).toUpperCase() + user.fullName.slice(1);
  }
  applyFilter(event: Event, type: string) {
    if (type == 'day') {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dayPlansDataSource.filter = filterValue.trim().toLowerCase();

      if (this.dayPlansDataSource.paginator) {
        this.dayPlansDataSource.paginator.firstPage();
      }
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      this.intradayPlansDataSource.filter = filterValue.trim().toLowerCase();

      if (this.intradayPlansDataSource.paginator) {
        this.intradayPlansDataSource.paginator.firstPage();
      }
    }
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  transformPrice(value: number): string | null {
    // Format the number with dot as the decimal separator
    if (value) {
      const formattedNumber = value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      // Append the euro symbol
      return `${formattedNumber}`;
    }
    return null;
  }
  checkCalculatedPrice(element: any) {
    for (let item of element.consumptions) {
      if (element.consumption_price_calculated) {
        if (!item.consumption_price_calculated) {
          if (element.plan_type == 'day') this.dayPlanDisclaimer = true;
          if (element.plan_type == 'intraday')
            this.intradayPlanDisclaimer = true;
          return '*';
        }
      }
    }
    return '';
  }
  getIntervals(value: string) {
    let interval = '';
    if (value === '60') return (interval = 'hourly');       // value is key for translation, do not translate
    if (value === '30') return (interval = 'hHourly');      // value is key for translation, do not translate
    if (value === '15') return (interval = 'quarterly');    // value is key for translation, do not translate
    return interval;
  }
}
