import { Component } from '@angular/core';
import { Plan } from 'src/app/shared/plan';
import { OrderService } from '../order.service';
import { PlanService } from 'src/app/plan/plan.service';
import { Router } from '@angular/router';
import { Forecast } from 'src/app/forecast/forecast';
import { ForecastService } from 'src/app/forecast/forecast.service';
import { DayEntry } from '../day-entry';

@Component({
  selector: 'app-prediction',
  templateUrl: './order-prediction.component.html',
  styleUrls: ['./order-prediction.component.scss'],
})
export class OrderPredictionComponent {
  planList: Plan[] = [];

  dayEntries: DayEntry[] = [];
  dayPrices: number[][] = [];

  forecastPricesByHour: number[][] = [];
  planForecastPrices: number[] = [];
  loadingFinished: boolean = true;
  holidays: string[] = [
    '23-1-1',
    '23-1-6',
    '23-4-7',
    '23-4-10',
    '23-5-1',
    '23-5-8',
    '23-7-5',
    '23-8-29',
    '23-7-1',
    '23-7-15',
    '23-11-1',
    '23-11-17',
    '23-12-5',
    '23-12-24',
    '23-12-25',
    '23-12-26',
    '24-1-1',
    '24-1-6',
  ];

  constructor(
    private orderService: OrderService,
    private planService: PlanService,
    private forecastService: ForecastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeView();
  }

  initializeView() {
    this.constructDayEntries();
    this.loadingFinished = false;
    // this.planService.getPlans().subscribe({
    //   next: (data) => {
    //     this.constructPlanList(data);
    //   },
    // });
    this.forecastService.getForecasts().subscribe({
      next: (data) => {
        this.constructForecastData(data);
        for (let planIndex = 0; planIndex < this.planList.length; planIndex++) {
          this.fillDayEntriesWithPlanData(planIndex);
          this.calculatePredictionPrice(planIndex);
        }
      },
    });
  }

  constructDayEntries() {
    for (let day = 0; day < 14; day++) {
      let hourValues = [];
      let hourPrices = [];
      for (let hour = 0; hour < 24; hour++) {
        hourValues.push(0);
        hourPrices.push(0);
      }
      this.dayPrices.push(hourPrices);
      let timeRangeValues = [0, 0, 0];
      let date = new Date();
      date.setDate(date.getDate() + day + 1);
      let dayType = this.decideDayType(date);
      let dayEntry: DayEntry = {
        index: day,
        hourValues: hourValues,
        timeRangeValues: timeRangeValues,
        date: date,
        dateFormatted: '',
        dayType: dayType,
        simpleView: false,
      };
      this.dayEntries.push(dayEntry);
    }
  }

  decideDayType(date: Date): number {
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getFullYear() % 2000;
    let stringDate = `${year}-${month}-${day}`;
    // console.log(stringDate);
    if (this.holidays.includes(stringDate)) {
      return 2;
    }
    day = date.getDay();
    if (day === 0 || day === 6) {
      return 1;
    }
    return 0;
  }

  constructForecastData(data: Forecast[]) {
    let nextDaysForecasts: Forecast[] = [];
    data.forEach((forecastData) => {
      let forecast: Forecast = {
        region: forecastData.region,
        year: forecastData.year,
        month: forecastData.month,
        day: forecastData.day,
        hour: forecastData.hour,
        priceAvg: forecastData.priceAvg,
        priceMin: 0,
        priceMax: 0,
      };
      nextDaysForecasts.push(forecast);
    });
    for (let day = 0; day < 14; day++) {
      let dayEntry = this.dayEntries[day];
      this.forecastPricesByHour.push([]);
      for (let hour = 0; hour < 24; hour++) {
        let forecast = nextDaysForecasts.find(
          (forecast) =>
            forecast.year === dayEntry.date.getFullYear() &&
            forecast.month === dayEntry.date.getMonth() + 1 &&
            forecast.day === dayEntry.date.getDate() &&
            forecast.hour === hour
        );
        this.forecastPricesByHour[day].push(forecast ? forecast.priceAvg : 0);
      }
    }
    // console.log(this.forecastPricesByHour);
  }

  constructPlanList(data: Plan[]) {
    // console.log(data);
    this.planList = [];
    data.forEach((planData) => {
      let plan: Plan = {
        id: planData.id,
        user_id: planData.user_id,
        client_id: planData.client_id,
        name: planData.name,
        description: planData.description,
        status: planData.status,
        wdayWorkHours: planData.wdayWorkHours,
        wendWorkHours: planData.wendWorkHours,
        hldyWorkHours: planData.hldyWorkHours,
        wdayStart: planData.wdayStart,
        wdayEnd: planData.wdayEnd,
        wendStart: planData.wendStart,
        wendEnd: planData.wendEnd,
        hldyStart: planData.hldyStart,
        hldyEnd: planData.hldyEnd,
        consWdayBefore: planData.consWdayBefore,
        consWday: planData.consWday,
        consWdayAfter: planData.consWdayAfter,
        consWendBefore: planData.consWendBefore,
        consWend: planData.consWend,
        consWendAfter: planData.consWendAfter,
        consHldyBefore: planData.consHldyBefore,
        consHldy: planData.consHldy,
        consHldyAfter: planData.consHldyAfter,
        created_at: planData.created_at?.split('T')[0],
        updated_at: planData.updated_at?.split('T')[0],
      };
      if (plan.created_at == undefined) {
        plan.created_at = '[Unknown]';
      }
      if (plan.updated_at == undefined) {
        plan.updated_at = '[Unknown]';
      }
      if (plan.name == '') {
        plan.name = '[Untitled]';
      }
      if (plan.description == '') {
        plan.description = '[Empty]';
      }
      this.planList.push(plan);
    });
    // console.log(this.planList);
    this.loadingFinished = true;
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  prefillOrder(planId: number) {
    let plan = this.planList.find((plan) => plan.id === planId);
    //console.log('Using plan ' + plan?.name);
    this.routeChecker(`order/14days/${planId}`)
  }

  fillDayEntriesWithPlanData(planIndex: number) {
    let planValues = [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ];
    planValues[0] = [
      this.planList[planIndex].consWdayBefore,
      this.planList[planIndex].consWday,
      this.planList[planIndex].consWdayAfter,
    ];
    planValues[1] = [
      this.planList[planIndex].consWendBefore,
      this.planList[planIndex].consWend,
      this.planList[planIndex].consWendAfter,
    ];
    planValues[2] = [
      this.planList[planIndex].consHldyBefore,
      this.planList[planIndex].consHldy,
      this.planList[planIndex].consHldyAfter,
    ];
    let planTimes = [
      [0, 0],
      [0, 0],
      [0, 0],
    ];
    planTimes[0] = [
      this.planList[planIndex].wdayStart,
      this.planList[planIndex].wdayEnd,
    ];
    planTimes[1] = [
      this.planList[planIndex].wendStart,
      this.planList[planIndex].wendEnd,
    ];
    planTimes[2] = [
      this.planList[planIndex].hldyStart,
      this.planList[planIndex].hldyEnd,
    ];
    let worktimeHours = [
      this.planList[planIndex].wdayWorkHours,
      this.planList[planIndex].wendWorkHours,
      this.planList[planIndex].hldyWorkHours,
    ];
    this.dayEntries.forEach((dayEntry) => {
      if (worktimeHours[dayEntry.dayType]) {
        for (let index = 0; index < planTimes[dayEntry.dayType][0]; index++) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][0];
        }
        for (
          let index = planTimes[dayEntry.dayType][0];
          index < planTimes[dayEntry.dayType][1] + 1;
          index++
        ) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][1];
        }
        for (
          let index = planTimes[dayEntry.dayType][1] + 1;
          index < 24;
          index++
        ) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][2];
        }
      } else {
        for (let index = 0; index < 24; index++) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][0];
        }
      }
    });
    // console.log(this.dayEntries);
  }

  calculatePredictionPrice(planIndex: number) {
    this.planForecastPrices[planIndex] = 0;
    let prices: number[] = [];
    this.dayEntries.forEach((dayEntry) => {
      // console.log(dayEntry);
      let hourIndex = 0;
      dayEntry.hourValues.forEach((hourValue) => {
        if (hourValue != 0) {
          // let price = this.dayPrices[dayEntry.index][hourIndex];
          let price = this.forecastPricesByHour[dayEntry.index][hourIndex];
          if (price) prices.push(hourValue * price);
        }
        hourIndex++;
      });
    });
    // console.log(prices);
    this.planForecastPrices[planIndex] = prices.reduce((a, b) => a + b, 0);
    // console.log(this.planForecastPrices[planIndex]);
  }
}
