
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { PlanService } from '../plan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Plan } from 'src/app/shared/plan';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BusinessUnitLocationsService } from 'src/app/business-unit-locations/business-unit-locations.service';

@Component({
  selector: 'app-create',
  templateUrl: './plan-create.component.html',
  styleUrls: ['./plan-create.component.scss'],
})
export class PlanCreateComponent {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  // TOP SECTION
  planName: string = '';
  planDescription: string = '';
  planType: string = '';
  consumptionPlan: any;

  // DAY AND TIME DISABLING
  workHoursChecks: boolean[] = [true, false, false];
  rangeChecks: boolean[][] = [
    [true, true, true],
    [true, true, true],
    [true, true, true],
  ];

  // WORKING TIME
  chosenTimesStrings: string[][] = [[], [], []];
  workingTimeStrings: string[] = ['', '', ''];
  chosenTimesValues: number[][] = [
    [8, 15],
    [8, 15],
    [8, 15],
  ];

  // CONSUMPTION
  plannedConsumptionValues: number[][] = [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ];
  UPPER_LIMIT: number = 999;

  // TEXTS
  dayTypeTitles: string[] = ['Workdays', 'Weekends', 'Holidays'];
  timeRangeTitles: string[] = [
    'Before Working Time',
    'During Working Time',
    'After Working Time',
  ];
  hoursInDay: string[][] = [[], []];
  rangeTimeTitles: string[][] = [[], [], []];

  // // CONSTANTS
  // WORKDAY: number = 0;
  // WEEKEND: number = 1;
  // HOLIDAY: number = 2;
  // FROM: number = 0;
  // UNTIL: number = 1;
  // BEFORE: number = 0;
  // DURING: number = 1;
  // AFTER: number = 2;

  // HANDLING
  error: string = '';

  startDate = new FormControl();
  endDate = new FormControl();

  daysExceedError: boolean = false;

  dayConsumptions: any = [];
  user: any;
  planId: number;

  clientData: any;
  consumptionId: any;

  locations:any
  businessUnits:any
  locationsData:any
  selectedBusinessUnit:any=''

  constructor(
    private planService: PlanService,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private businessUnitLocationsService: BusinessUnitLocationsService
  ) {}

  ngOnInit() {
    let id   = Number(this.route.snapshot.paramMap.get('id'));
    let type = this.route.snapshot.paramMap.get('type');

    this.user = localStorage.getItem('userToken');
    if (this.user) {
      this.user = JSON.parse(this.user);
      this.planId = Number(this.route.snapshot.paramMap.get('id'));
      this.adminService.getClient(this.user.client_id).subscribe((data) => {
        this.clientData = data;
      });

      if (this.planId || id ) {
        this.planId = id ? id : this.planId;
        this.planService.getPlan(this.planId).subscribe((res) => {
          this.consumptionPlan = res;
          if(!this.consumptionPlan.clientlocation_id) this.consumptionPlan.clientlocation_id=''
          if(!this.consumptionPlan.businessunit_id) this.consumptionPlan.businessunit_id=''
        });
      } else {
        this.consumptionPlan = {
          plan_id: null,
          client_id: this.user.client_id,
          clientlocation_id:'',
          businessunit_id:'',
          description: '',
          plan_type:type,
          name: '',
          created_by: this.user.id,
          interval: '',
          unit: '',
        };
      }
    }
    this.getBusinessUnits()
    this.getLocations()
  }
  getBusinessUnits() {
    this.businessUnitLocationsService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data
    })

  }
  getLocations() {
    this.businessUnitLocationsService.getLocations().subscribe((data) => {
      this.locations = data
      this.locationsData = [...data]
    })
  }
  filterLocation(event:any){
    if(event){
      this.locations=this.locationsData.filter((item:any)=>item.businessunit_id===Number(event))
    }else{
      this.locations=this.locationsData
    }
  }
  onPlanTypeChange(planType: string) {
    this.consumptionPlan.plan_type = planType;
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  submitPlan() {
    if (!this.checkIfValid()) {
      return;
    }
    if (this.planId) {
      this.consumptionPlan.status="active"
      this.planService
        .updatePlan(this.planId.toString(), this.consumptionPlan)
        .subscribe({
          next: (data) => {
            if (data) {
              this.translate.get('plans.toastr.updateSuccess').subscribe((data1: string) => {
                this.translate.get('plans.toastr.success').subscribe((data2: string) => {
                  this.toastr.success(data1,data2);
                });
              });

              //console.log("plan-create.component 1 ", data);
              //console.log("plan-create.component 2 ",this.consumptionPlan.id);

              //this.router.navigate([`/plan/list/${this.consumptionPlan.id}`]);
              this.routeChecker(`/plan/list/${this.consumptionPlan.id}`)
            }
          },
          error: (err) => {
            this.translate.get('plans.toastr.updateError').subscribe((data1: string) => {
              this.translate.get('plans.toastr.error').subscribe((data2: string) => {
                this.toastr.error(data1,data2);
              });
            });
          },
        });
    } else {
      this.dialog.open(this.dialogTemplate);
    }
  }

  createPlan(ans: string) {
    this.planService.newPlan(this.consumptionPlan).subscribe({
      next: (data) => {
        if (data) {
          this.translate.get('plans.toastr.createSuccess').subscribe((data1: string) => {
            this.translate.get('plans.toastr.success').subscribe((data2: string) => {
              this.toastr.success(data1,data2);
            });
          });

          this.dialog.closeAll();
          if (ans === 'no') {
            this.routeChecker('plans')
          } else {
            this.routeChecker(`plan/list/${data.id}`)
          }
        }
      },
      error: (err) => {
        this.translate.get('plans.toastr.createError').subscribe((data1: string) => {
          this.translate.get('plans.toastr.error').subscribe((data2: string) => {
            this.toastr.error(data1,data2);
          });
        });
      },
    });
  }

  checkIfValid(): boolean {
    return true;
  }
}
