
// import { Injectable } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';
// import { filter } from 'rxjs/operators';
// import { take } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root',
// })
// export class TranslationService {
//   private supportedLangs = ['sk', 'cs', 'pl', 'hu'];
//   private defaultLang = 'en';
//   public urlLang :string | null

//   constructor(private router: Router) {}
//   initializeLanguage(){
//     this.router.events.pipe(
//       filter(event => event instanceof NavigationEnd)
//     ).subscribe(() => {
//       const url = this.router.url;
//       this.urlLang = this.getLanguageFromUrl(url);
//       console.log(this.urlLang)
//       const browserLang = navigator.language.split('-')[0];
//       const lang = this.supportedLangs.includes(this.urlLang || browserLang) ? this.urlLang || browserLang : this.defaultLang;
//       if (!sessionStorage.getItem('language')) {
//         sessionStorage.setItem('language', lang);
//       }
//       return sessionStorage.getItem('language')!;
//     });
//   }

//   private getLanguageFromUrl(url: string): string | null {
//     const match = url.match(/^\/([^\/]+)/);
//     return match ? match[1] : null;
//   }
// }


import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class TranslationService {
  private supportedLangs = ['sk', 'cs', 'pl', 'hu'];
  private defaultLang = 'en';
  public urlLang: string | null = null;
  public browserLang:string
  constructor(private router: Router) {}

  initializeLanguage(): Promise<string> {
    //console.log('Initializing language...');
    return new Promise((resolve) => {
      // Immediately set a default language
      this.browserLang = navigator.language.split('-')[0];
      const initialLang = this.supportedLangs.includes(this.browserLang) ? this.browserLang : this.defaultLang;
      sessionStorage.setItem('language', initialLang);

      // Then wait for the first NavigationEnd event
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      ).subscribe(() => {
        const url = this.router.url;

        const { langs, route } = this.parseUrl(url);

        this.urlLang = this.getLanguageFromUrl(url);

        if(this.urlLang=='null') this.urlLang=this.browserLang

        const lang = this.supportedLangs.includes(this.urlLang) ? this.urlLang : this.defaultLang;

        sessionStorage.setItem('language', lang);
        if(route) sessionStorage.setItem('initialRoute',route)

        resolve(lang);
      });

      // Resolve after a timeout if no NavigationEnd event occurs
      setTimeout(() => {
        if (!this.urlLang) {
          resolve(initialLang);
        }
      }, 2500);
    });
  }

  private getLanguageFromUrl(url: string): string  {
    const match = url.match(/^\/([^\/]+)/);
    return match ? match[1] : this.browserLang;
  }
  private parseUrl(url: string): { langs: string | null, route: string | null } {
    const match = url.match(/^\/([^\/]+)(\/.*)?/);
    return {
      langs: match ? match[1] : null,
      route: match && match[2] ? match[2] : null,
    };
  }
}




