import { Component } from '@angular/core';
import { OrderService } from '../order.service';
import { Order } from '../order';
import { DayEntry } from '../day-entry';
import { Plan } from 'src/app/shared/plan';
import { PlanService } from 'src/app/plan/plan.service';
import { ActivatedRoute } from '@angular/router';
import { ForecastService } from 'src/app/forecast/forecast.service';
import { Forecast } from 'src/app/forecast/forecast';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-order14-days',
  templateUrl: './order14-days.component.html',
  styleUrls: ['./order14-days.component.scss'],
})
export class Order14DaysComponent {
  // Constants
  hourTitles: string[] = [];
  dayTypeTitles: string[] = ['Workday', 'Weekend', 'Holiday'];
  timeRangeTitles: string[] = [
    'Before Working Time (00:00 - 08:00)',
    'During Working Time (08:00 - 16:00)',
    'After Working Time (16:00 - 00:00)',
  ];
  daysOfWeek: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  holidays: string[] = [
    '23-1-1',
    '23-1-6',
    '23-4-7',
    '23-4-10',
    '23-5-1',
    '23-5-8',
    '23-7-5',
    '23-8-29',
    '23-7-1',
    '23-7-15',
    '23-11-1',
    '23-11-17',
    '23-12-5',
    '23-12-24',
    '23-12-25',
    '23-12-26',
    '24-1-1',
    '24-1-6',
  ];

  simpleSetupValues: number[][] = [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ];
  simpleSetupChecks: boolean[][] = [
    [false, false, false],
    [false, false, false],
    [false, false, false],
  ];

  upperLimit: number = 999;
  currency: string = '€';
  unit: string = 'MWh';
  showSimpleSetup: boolean = false;
  showPlanChoose: boolean = true;
  showFileImport: boolean = false;
  dayEntries: DayEntry[] = [];

  dayPrices: number[][] = [];

  totalConsumption: number = 0;
  allValuesCount: number = 0;
  individualDays: number = 0;
  totalForecastPrice: number = 0;

  planList: Plan[] = [];
  chosenPlan: Plan;
  planLastUpdate: string = '';
  forecastList: Forecast[] = [];

  loadedFileName = '';

  prefillPlanId: number | null;
  private sub: any;

  constructor(
    private orderService: OrderService,
    private planService: PlanService,
    private forecastService: ForecastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.prefillPlanId = +params['id'];
      // console.log('Prefill Id', this.prefillPlanId);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    this.initializeView();
    this.forecastService.getForecasts().subscribe({
      next: (data) => {
        this.constructForecastData(data);
        this.calculateAllValues();
      },
    });
    // this.planService.getPlans().subscribe({
    //   next: (data) => {
    //     this.constructPlanList(data);
    //     if (this.prefillPlanId) {
    //       this.setValuesFromPlan();
    //     }
    //   },
    // });
  }

  constructForecastData(data: Forecast[]) {
    data.forEach((forecastData) => {
      let forecast: Forecast = {
        region: forecastData.region,
        year: forecastData.year,
        month: forecastData.month,
        day: forecastData.day,
        hour: forecastData.hour,
        priceAvg: forecastData.priceAvg,
        priceMin: forecastData.priceMin,
        priceMax: forecastData.priceMax,
      };
      this.forecastList.push(forecast);
    });
    for (let day = 0; day < 14; day++) {
      let dayEntry = this.dayEntries[day];
      for (let hour = 0; hour < 24; hour++) {
        let forecast = this.forecastList.find(
          (forecast) =>
            forecast.year === dayEntry.date.getFullYear() &&
            forecast.month === dayEntry.date.getMonth() + 1 &&
            forecast.day === dayEntry.date.getDate() &&
            forecast.hour === hour
        );
        this.dayPrices[day][hour] = forecast ? forecast.priceAvg : 0;
      }
    }
  }

  initializeView() {
    for (let day = 0; day < 14; day++) {
      let hourValues = [];
      let hourPrices = [];
      for (let hour = 0; hour < 24; hour++) {
        hourValues.push(0);
        hourPrices.push(0);
      }
      this.dayPrices.push(hourPrices);
      let timeRangeValues = [0, 0, 0];
      let date = new Date();
      date.setDate(date.getDate() + day + 1);
      let dayType = this.decideDayType(date);
      let dateFormatted = this.formatDate(date, dayType);
      let dayEntry: DayEntry = {
        index: day,
        hourValues: hourValues,
        timeRangeValues: timeRangeValues,
        date: date,
        dateFormatted: dateFormatted,
        dayType: dayType,
        simpleView: false,
      };
      this.dayEntries.push(dayEntry);
    }
    for (let hour = 0; hour < 24; hour++) {
      this.hourTitles[hour] = (hour > 9 ? '' : '0') + hour.toString() + ':00';
    }
  }

  constructPlanList(data: Plan[]) {
    // console.log(data);
    data.forEach((planData) => {
      let plan: Plan = {
        id: planData.id,
        user_id: planData.user_id,
        client_id: planData.client_id,
        name: planData.name,
        description: planData.description,
        status: planData.status,
        wdayWorkHours: planData.wdayWorkHours,
        wendWorkHours: planData.wendWorkHours,
        hldyWorkHours: planData.hldyWorkHours,
        wdayStart: planData.wdayStart,
        wdayEnd: planData.wdayEnd,
        wendStart: planData.wendStart,
        wendEnd: planData.wendEnd,
        hldyStart: planData.hldyStart,
        hldyEnd: planData.hldyEnd,
        consWdayBefore: planData.consWdayBefore,
        consWday: planData.consWday,
        consWdayAfter: planData.consWdayAfter,
        consWendBefore: planData.consWendBefore,
        consWend: planData.consWend,
        consWendAfter: planData.consWendAfter,
        consHldyBefore: planData.consHldyBefore,
        consHldy: planData.consHldy,
        consHldyAfter: planData.consHldyAfter,
        created_at: planData.created_at?.split('T')[0],
        updated_at: planData.updated_at?.split('T')[0],
      };
      if (plan.created_at == undefined) {
        plan.created_at = '[Unknown]';
      }
      if (plan.updated_at == undefined) {
        plan.updated_at = '[Unknown]';
      }
      if (plan.name == '') {
        plan.name = '[Untitled]';
      }
      if (plan.description == '') {
        plan.description = '[Empty]';
      }
      this.planList.push(plan);
    });
    // console.log(this.planList);
  }

  decideDayType(date: Date): number {
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getFullYear() % 2000;
    let stringDate = `${year}-${month}-${day}`;
    // console.log(stringDate);
    if (this.holidays.includes(stringDate)) {
      return 2;
    }
    day = date.getDay();
    if (day === 0 || day === 6) {
      return 1;
    }
    return 0;
  }

  formatDate(date: Date, dayType: number): string {
    let thisDate = `${date.getUTCFullYear()}-${
      date.getUTCMonth() + 1
    }-${date.getUTCDate()}`;
    let dateFormatted = `${this.daysOfWeek[date.getDay()]} ${thisDate} (${
      this.dayTypeTitles[dayType]
    })`;
    return dateFormatted;
  }

  enableSimpleView(index: number) {
    this.dayEntries[index].simpleView = !this.dayEntries[index].simpleView;
    // console.log(index);
  }

  submitValues() {
    // console.log(this.dayConsumptions);
    for (let hour = 0; hour < this.dayEntries.length; hour++) {
      const hourValues = this.dayEntries[hour].hourValues;
      for (let valueIndex = 0; valueIndex < hourValues.length; valueIndex++) {
        const hourValue = hourValues[valueIndex];
        if (hourValue > 0 && hourValue <= this.upperLimit) {
          let data = this.constructRestData(
            valueIndex,
            hourValue,
            this.dayEntries[hour].date
          );
          //console.log(data);
          this.orderService.postNewOrder(data).subscribe({
            next: (data) => {
              this.successfulSaving();
            },
          });
        }
      }
    }
  }

  constructRestData(hour: number, consumption: number, date: Date): any {
    const year = date.getUTCFullYear();
    const month = date.getMonth() + 1;
    const day = date.getUTCDate();
    const order: Order = {
      client_id: 2,
      year: year,
      month: month,
      day: day,
      hour: hour,
      consumption: consumption * 1000,
      status: 'O',
    };
    // console.log(order);
    return order;
  }

  successfulSaving() {
    //console.log('Success!');
  }

  calculateHourValuesFromTimeRanges(index: number) {
    for (let timeRange = 0; timeRange < 3; timeRange++) {
      for (let hour = 0; hour < 8; hour++) {
        this.dayEntries[index].hourValues[timeRange * 8 + hour] =
          this.dayEntries[index].timeRangeValues[timeRange];
      }
    }
    this.dayEntries[index].simpleView = false;
    this.calculateAllValues();
  }

  toggleSimpleSetup() {
    this.showSimpleSetup = !this.showSimpleSetup;
  }

  togglePlanChoose() {
    this.showPlanChoose = !this.showPlanChoose;
  }

  toggleFileImportCard() {
    this.showFileImport = !this.showFileImport;
  }

  setValuesFromSimpleSetup(onlyForEmpty: boolean) {
    this.dayEntries.forEach((dayEntry) => {
      let setupValues = this.simpleSetupValues[dayEntry.dayType];
      let setupChecks = this.simpleSetupChecks[dayEntry.dayType];
      for (let index = 0; index < 24; index++) {
        if (onlyForEmpty && dayEntry.hourValues[index] != 0) {
          continue;
        }
        if (index > 15) {
          if (setupChecks[2]) dayEntry.hourValues[index] = setupValues[2];
        } else if (index > 7) {
          if (setupChecks[1]) dayEntry.hourValues[index] = setupValues[1];
        } else {
          if (setupChecks[0]) dayEntry.hourValues[index] = setupValues[0];
        }
      }
    });
    this.showSimpleSetup = false;
    this.calculateAllValues();
    this.resetAllSimpleSetupChecks();
  }

  handleImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      this.loadedFileName = file.name;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log('Rows:', rows);
          rows.forEach((row: any) => {
            let dateString = `20${row['Year']}-${row['Month']}-${row['Day']}`;
            let date = new Date(dateString).toDateString();
            // console.log(date);
            let dayEntry = this.dayEntries.find(
              (dayEntry) => dayEntry.date.toDateString() == date
            );
            if (dayEntry) {
              // console.log(row['Consumption']);
              dayEntry.hourValues[row['Hour start']] = row['Consumption'];
            }
          });
          this.calculateAllValues();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  choosePlan() {
    this.planLastUpdate = this.chosenPlan.updated_at!;
  }

  setValuesFromPlan() {
    if (this.prefillPlanId) {
      this.chosenPlan = this.planList.find(
        (plan) => plan.id === this.prefillPlanId
      )!;
    }
    this.prefillPlanId = null;
    if (!this.chosenPlan) {
      return;
    }
    // console.log(this.chosenPlan.name);
    let planValues = [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ];
    planValues[0] = [
      this.chosenPlan.consWdayBefore,
      this.chosenPlan.consWday,
      this.chosenPlan.consWdayAfter,
    ];
    planValues[1] = [
      this.chosenPlan.consWendBefore,
      this.chosenPlan.consWend,
      this.chosenPlan.consWendAfter,
    ];
    planValues[2] = [
      this.chosenPlan.consHldyBefore,
      this.chosenPlan.consHldy,
      this.chosenPlan.consHldyAfter,
    ];
    let planTimes = [
      [0, 0],
      [0, 0],
      [0, 0],
    ];
    planTimes[0] = [this.chosenPlan.wdayStart, this.chosenPlan.wdayEnd];
    planTimes[1] = [this.chosenPlan.wendStart, this.chosenPlan.wendEnd];
    planTimes[2] = [this.chosenPlan.hldyStart, this.chosenPlan.hldyEnd];
    let worktimeHours = [
      this.chosenPlan.wdayWorkHours,
      this.chosenPlan.wendWorkHours,
      this.chosenPlan.hldyWorkHours,
    ];
    // console.log(planTimes);
    this.dayEntries.forEach((dayEntry) => {
      // let setupValues = this.simpleSetupValues[dayEntry.dayType];
      // let setupChecks = this.simpleSetupChecks[dayEntry.dayType];
      if (worktimeHours[dayEntry.dayType]) {
        for (let index = 0; index < planTimes[dayEntry.dayType][0]; index++) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][0];
        }
        for (
          let index = planTimes[dayEntry.dayType][0];
          index < planTimes[dayEntry.dayType][1] + 1;
          index++
        ) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][1];
        }
        for (
          let index = planTimes[dayEntry.dayType][1] + 1;
          index < 24;
          index++
        ) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][2];
        }
      } else {
        for (let index = 0; index < 24; index++) {
          dayEntry.hourValues[index] = planValues[dayEntry.dayType][0];
        }
      }
    });
    this.showPlanChoose = false;
    this.calculateAllValues();
    this.resetAllSimpleSetupChecks();
  }

  activateSimpleSetupCheck(x: number, y: number) {
    this.simpleSetupChecks[x][y] = true;
  }

  resetAllSimpleSetupChecks() {
    this.simpleSetupChecks = [
      [false, false, false],
      [false, false, false],
      [false, false, false],
    ];
  }

  calculateAllValues() {
    this.allValuesCount = 0;
    this.individualDays = 0;
    this.totalConsumption = 0;
    this.totalForecastPrice = 0;
    let singleDays: string[] = [];
    let prices: number[] = [];
    this.dayEntries.forEach((dayEntry) => {
      let hourIndex = 0;
      dayEntry.hourValues.forEach((hourValue) => {
        if (hourValue != 0) {
          this.allValuesCount++;
          this.totalConsumption += hourValue;
          let date = dayEntry.date.toDateString();
          // console.log(date);
          if (!singleDays.includes(date)) {
            singleDays.push(date);
          }
          let price = this.dayPrices[dayEntry.index][hourIndex];
          if (price) prices.push(hourValue * price);
        }
        hourIndex++;
      });
    });
    // console.log(prices);
    this.individualDays = singleDays.length;
    this.totalForecastPrice = prices.reduce((a, b) => a + b, 0);
  }
}
