<title>MyEnergy Helpdesk: Support When You Need It</title>
<meta
  name="description"
  content="Do you need assistance with the services of MyEnergy? We will provide you with professional support and guidance any time you need us, thanks to our committed help desk. MyEnergy's trained team is here to guide you during every step of the way, in case you are looking for answers about platofrm usage, require troubleshooting assistance or keen to explore additional features."
/>

<div class="container-fluid">
  <section>
    <form
      name="requestForm"
      id="requestForm"
      action="#"
      method="get"
      ngForm
      (ngSubmit)="onSubmit()"
      #form="ngForm"
    >
      <section class="client_request_section">
        <div class="client_request_section__form">
          <div class="client_request_section__form-inner">
            <div class="request_card_wrapper">
              <div class="">
                <div class="request_logo_section">
                  <div class="request_title site__title">
                    <span *ngIf="!email">{{
                      "helpDesk.request.helloNoEmail" | translate
                    }}</span>
                    <span *ngIf="email && !requestId"
                      >{{ "helpDesk.request.hello1" | translate }}
                      <span class="green-text">{{ email }}</span
                      >{{ "helpDesk.request.hello2" | translate }}</span
                    >
                  </div>
                </div>

                <div class="form-group">
                  <div *ngIf="!registered" class="row">
                    <!-- <label for="subject">Short appropriate description</label><br> -->
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      class="mb-2 form-control"
                      [placeholder]="'helpDesk.request.subject' | translate"
                      autofocus=""
                      [(ngModel)]="requestSubject"
                      (ngModelChange)="isSubjectValid($event)"
                      #test1="ngModel"
                      required
                      minlength="4"
                      maxlength="{{ subjectMaxLength }}"
                      [ngClass]="
                        test1.valid ? 'requestValid' : 'requestInvalid'
                      "
                    />
                    <div
                      *ngIf="test1.touched && subjectInvalid != ''"
                      class="col-lg-12 invalid-input"
                      [style.top.px]="errSubjectY"
                      [style.left.px]="errSubjectX"
                    >
                      {{
                        "helpDesk.request.validator." + subjectInvalid
                          | translate
                      }}
                    </div>
                  </div>
                  <div class="row_columb">
                    <div class="row dropdown">
                      <select
                        class="mt-2 mb-2 btn requestSubmit"
                        [ngClass]="{
                          show: showServiceDropdown,
                          requestInvalid:
                            !myEnergyService ||
                            myEnergyService === 'My Energy Service'
                        }"
                        id="myEnergyDropDwn"
                        name="myEnergyDropDwn"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        [(ngModel)]="myEnergyService"
                        #myEnergyDropDwn="ngModel"
                        (click)="toogleServiceDropdown($event)"
                        (change)="updateService($event)"
                      >
                        <option selected disabled hidden class="default-option">
                          My Energy Service
                          <!-- {{ "helpDesk.request.service" | translate }} -->
                        </option>
                        <option
                          class="dropdown-item"
                          *ngFor="let item of myEnergyServiceList"
                          [value]="
                            'helpDesk.request.services.' + item | translate
                          "
                        >
                          {{ "helpDesk.request.services." + item | translate }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          !myEnergyService ||
                          myEnergyService === 'My Energy Service'
                        "
                        class="text-danger"
                      ></div>
                    </div>

                    <fieldset class="row mb-4 mt-4">
                      <div class="pr-solution">
                        {{ "helpDesk.request.priority" | translate }}
                      </div>
                      <div class="col-3">
                        <div class="high-solution">
                          <div class="row">
                            <div class="col-6">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="priority"
                                id="low"
                                value="LOW"
                                (change)="onChangePriority($event)"
                                [checked]="priority == 'LOW'"
                              />
                              <label class="form-check-label" for="low"
                                >LOW</label
                              >
                            </div>

                            <div class="col-6">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="priority"
                                id="high"
                                value="HIGH"
                                (change)="onChangePriority($event)"
                                [checked]="priority == 'HIGH'"
                              />
                              <label class="form-check-label" for="high">
                                HIGH
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="row">
                    <textarea
                      class="mt-2 mb-2 p-3 rounded"
                      rows="4"
                      type="text"
                      id="description1"
                      name="description1"
                      [placeholder]="'helpDesk.request.fullText' | translate"
                      required
                      minlength="4"
                      maxlength="{{ descMaxLength }}"
                      [(ngModel)]="description"
                      (ngModelChange)="isDescValid($event)"
                      #test2="ngModel"
                      [ngClass]="
                        test2.valid ? 'requestValid' : 'requestInvalid'
                      "
                    >
                    </textarea>
                    <div
                      *ngIf="descInvalid != ''"
                      class="col-lg-12 invalid-input"
                      [style.top.px]="errDescY"
                      [style.left.px]="errDescX"
                    >
                      {{
                        "helpDesk.request.validator." + descInvalid | translate
                      }}
                    </div>
                  </div>

                  <div class="row mb-2 mt-2">
                    <div class="col-3 p-0">
                      {{ "helpDesk.request.phone" | translate }}
                    </div>

                    <div class="dropdown col-3 p-0">
                      <select
                        class="mt-0 mb-2 col-12 btn requestSubmit"
                        [ngClass]="{ show: showStateDropdown }"
                        id="stateDropDwn"
                        name="stateDropDwn"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="toogleStateDropdown($event)"
                        (change)="updateState($event)"
                      >
                        <option class="row" selected style="display: none">
                          <div class="col-1 text-left">
                            {{ phoneState.prefix }}
                          </div>
                          <div class="col-2 text right">
                            {{ phoneState.name }}
                          </div>
                        </option>
                        <option
                          class="dropdown-item"
                          *ngFor="let item of phoneStateList; let i = index"
                          [(ngModel)]="item.prefix"
                          [ngModelOptions]="{ standalone: true }"
                          ngDefaultControl
                          [value]="item.prefix"
                        >
                          <div class="col-2">
                            {{ item.prefix }}
                          </div>
                          <div class="col-4">
                            {{ item.name }}
                          </div>
                        </option>
                      </select>
                    </div>

                    <div class="col-6 pr-0">
                      <input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        class="form-control"
                        [placeholder]="'helpDesk.request.phonePH' | translate"
                        autofocus=""
                        [(ngModel)]="phoneNumber"
                        (ngModelChange)="isPhoneValid($event)"
                        #test3="ngModel"
                        required="true"
                        maxlength="{{ phoneMaxLength }}"
                        [ngClass]="
                          test3.valid ? 'requestValid' : 'requestInvalid'
                        "
                        minlength="6"
                      />
                      <div
                        *ngIf="test3.touched && phoneInvalid != ''"
                        class="col-lg-12 invalid-input"
                        [style.top.px]="errPhoneY"
                        [style.left.px]="errPhoneX"
                      >
                        {{
                          "helpDesk.request.validator." + phoneInvalid
                            | translate
                        }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2 mt-2">
                    <div class="col-3 p-0">
                      {{ "helpDesk.request.email" | translate }}
                    </div>
                    <div class="col-9 p-0">
                      {{ email }}
                    </div>
                  </div>

                  <div *ngIf="!registered" class="row mb-2 mt-3">
                    <div class="input-group">
                      <div class="col-3 p-0">
                        {{ "helpDesk.request.attachment" | translate }}
                      </div>

                      <div class="custom-file col-9">
                        <input
                          type="file"
                          id="attachment"
                          name="attachment"
                          class="custom-file-input file-upload requestSubmit"
                          id="inputGroupFile"
                          (change)="handleImport($event)"
                          accept="*.*"
                        />

                        <label
                          class="custom-file-label requestSubmit rounded"
                          for="inputGroupFile"
                        >
                          {{
                            attachment != null
                              ? attachment.name
                              : ("helpDesk.request.attachmentPH" | translate)
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="next">
                    <div class="row mb-2 mt-5">
                      <button
                        class="col-2 mt-2 mb-2 btn requestSubmit {{
                          buttonClass
                        }}"
                        type="submit"
                        (click)="btnSubmitClick($event)"
                        [disabled]="!form.valid"
                      >
                        {{ "helpDesk.request." + buttonContent | translate }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="request_footer_wrapper">
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </section>
</div>
