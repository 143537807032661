import { UserService } from './user.service';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

export const loggedInGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const toastr = inject(ToastrService)
  const userService = inject(UserService)
  //if(userService.)
  //const isLoggedIn = !!userService.userToken?.token;
  const isLoggedIn = userService.userToken?.token ? true : false;

  if (isLoggedIn) {
    userService.validateToken({ token: userService.userToken?.token }).subscribe({
      next: (res) => {
        if (!res.valid) {
          userService.logout();
          toastr.error("You have been logged out on this device due to being logged in somewhere else.", "Error");
          var selectedLang = sessionStorage.getItem('language');
          router.navigate([`${selectedLang}/login`]);
        }
        return isLoggedIn;
      },
      error: (e) => {
        userService.logout();
        toastr.error("You have been logged out on this device due to being logged in somewhere else.", "Error");
        var selectedLang = sessionStorage.getItem('language');
        router.navigate([`${selectedLang}/login`]);
      },
    })
  }
  if (!isLoggedIn) {
    toastr.error("You have to be logged in to use this service.", "Error");
    var selectedLang = sessionStorage.getItem('language');
    router.navigate([`${selectedLang}/login`]);
  }
  return isLoggedIn;
};
