import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-client-user-password-setup',
  templateUrl: './client-user-password-setup.component.html',
  styleUrls: ['./client-user-password-setup.component.scss'],
})
export class ClientUserPasswordSetupComponent {
  @ViewChild('form') form: FormGroup;

  newClientUser: any;

  loadingFinished: boolean = true;

  emailPattern = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';

  passwordStrength = '';
  emailTakenError = false;

  text = '';
  showMessage: boolean = false;
  showThankMessage: boolean = false;

  public content: string = 'contentToConvert';
  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.newClientUser = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      masterClientName: '',
      role:''
    };
    this.route.queryParams.subscribe((params:any) => {
      let token = params['token'];
      if (token) {
        let payload = {
          token,
        };
        this.userService.checkPendingUser(payload).subscribe((data) => {
          if (data.user) {
            this.newClientUser = data.user;
            this.newClientUser.firstName = data.user.fullName.split(' ')[0];
            this.newClientUser.lastName = data.user.fullName.split(' ')[1];
            this.newClientUser.password= '';
            this.newClientUser.confirmPassword= '';
            this.newClientUser.masterClientName=data.client.name
            this.newClientUser.role=data.user.role
          }
          if(!data.pending) this.showMessage=true
        },
        (error) => {
          if(error.status === 404) {
            this.showMessage=true
          }
        });
      }
    });

  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  createClientMasterUser() {
    if (this.newClientUser.password !== this.newClientUser.confirmPassword)
      return;

    let registerpayload = {
      token: this.newClientUser.token,
      password: this.newClientUser.password,
    };
    this.userService.updatePendingUser(registerpayload).subscribe((data) => {
      if(data) this.showThankMessage=true
    });
  }

  handleSpace(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart || 0;

    if (cursorPosition === 0 && event.key === ' ') {
      event.preventDefault();
    }
  }
  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
  letterOnly(event: any) {
    var charCode = event.keyCode;
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      charCode === 8 ||
      charCode === 32 ||
      charCode === 63
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkPasswordStrength(password: string): void {
    let strength = 'Weak';
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(
      password
    );
    const hasMixedCase =
      password !== password.toLowerCase() ||
      password !== password.toUpperCase();

    if (password.length >= 8 && (hasNumber || hasSpecialChar) && hasMixedCase) {
      strength = 'Strong';
    } else if (
      password.length >= 8 &&
      (hasNumber || hasSpecialChar || hasMixedCase)
    ) {
      strength = 'Moderate';
    }
    this.passwordStrength = strength;
  }
}
