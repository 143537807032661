import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { PhoneState } from './phone-state';
import { UserService } from '../user/user.service';

import { UserToken } from '../user/user-token';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HelpdeskService {
  userToken: UserToken;
  request: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  response: boolean = !this.isRequest();
  baseUrl: string = environment.baseUrlAPI;
  requestsUrl: string = this.baseUrl + '/helprequests';
  specificConditionRequestURL:string=this.baseUrl + '/specificconditions';

  requestTitle: string;
  requestId: string | any;
  requestSubject: string;
  myEnergyService: string;
  description: string;
  priority: string;
  phoneState: string;
  phoneNumber: string;
  attachment: File | any;
  email: string;
  prefilledData: any;
  registered: boolean = false;
  submit: boolean = false;

  constructor(private userService: UserService, private hc: HttpClient) {
    this.userToken = this.userService?.userToken!;

    if ( this.userToken ) {
      this.prefilledData = this.userToken;
   }
   
    this.requestId = '';
    this.requestSubject = '';
    this.myEnergyService = 'My Energy Service';
    this.description = '';
    this.priority =
      this.priority === '' || this.priority === undefined
        ? 'LOW'
        : this.priority;
    this.phoneState =
      this.phoneState === '' || this.phoneState === undefined
        ? '+421'
        : this.phoneState;
    this.phoneNumber = '';
    this.attachment = null;
    this.email = this.userToken?.email!;
    this.requestTitle = 'Hello ' + this.email + ', how can we help?';

    //console.log('HelpDesk service constructor');
  }

  submitRequest(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.userToken.token,
    });
    return this.hc.post(this.requestsUrl, payload, { headers });
  }

  specificConditionRequest(payload: any): Observable<any> {
    return this.hc.post(this.specificConditionRequestURL, payload);
  }
  postNewRequest(data: any): Observable<any> {
    return this.hc.post(this.requestsUrl, data);
  }
  isRequest(): boolean {
    return this.request.getValue();
  }

  setRequest(value: boolean): void {
    this.request.next(value);
    this.response = !this.request;
  }

  resetData(){
    this.setRequestTitle("Hello ' + this.email + ', how can we help?");
    this.setRequestId("");
    this.setRequestSubject("");
    this.setMyEnergyService("My Energy Service");
    this.setDescription("");
    this.setPriority("LOW");
    this.setPhoneState("+421");
    this.setPhoneNumber("");
    this.setAttachment(null);
    this.setEmail(this.userToken?.email);  
  }

  isResponse(): boolean {
    return this.response;
  }

  setRequestTitle(requestTitle: string): void {
    this.requestTitle = requestTitle;
  }

  getRequestTitle(): string {
    return this.requestTitle;
  }

  setRequestId(requestId: string): void {
    this.requestId = requestId;
  }

  getRequestId(): string {
    return this.requestId;
  }

  setRegistered(registered: boolean): void {
    this.registered = registered;
  }

  getRegistered(): boolean {
    return this.registered;
  }

  setRequestSubject(requestSubject: string): void {
    this.requestSubject = requestSubject;
  }

  getRequestSubject(): string {
    return this.requestSubject;
  }

  setMyEnergyService(myEnergyService: string): void {
    this.myEnergyService = myEnergyService;
  }

  getMyEnergyService(): string {
    return this.myEnergyService;
  }

  setDescription(description: string): void {
    this.description = description;
  }

  getDescription(): string {
    return this.description;
  }

  setPriority(priority: string): void {
    this.priority = priority;
  }

  getPriority(): string {
    return this.priority;
  }

  setPhoneState(phoneState: string): void {
    this.phoneState = phoneState;
  }

  getPhoneState(): string {
    return this.phoneState;
  }

  setPhoneNumber(phoneNumber: string): void {
    this.phoneNumber = phoneNumber;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  setAttachment(attachment: File | null): void {
    this.attachment = attachment;
  }

  getAttachment(): File {
    return this.attachment;
  }

  setEmail(email: string): void {
    this.email = email;
  }

  getEmail(): string {
    return this.email;
  }
}
