import { Component, ViewChild, isDevMode } from '@angular/core';
import { AnalysisService } from 'src/app/analysis/analysis.service';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/user/user.service';
import { ActualPrice } from '../actual-price';
import { EChartsOption } from 'echarts';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-analysis1week',
  templateUrl: './analysis1week.component.html',
  styleUrls: ['./analysis1week.component.scss'],
})
export class Analysis1weekComponent {
  timestamps: number[] = [];
  values: Array<Array<[number, number]>> = [[], [], [], [], []];
  pricesDownloaded: boolean[] = [false, false, false];
  dateFrom: string;
  dateUntil: string;
  region: string = 'sk';
  isLoading = true;
  chartOption: EChartsOption;
  allDataDownloaded = false;

  constructor(private analysisService: AnalysisService, public userService: UserService) {
    this.setInitialDate();
  }

  @ViewChild('graf') graf: any;

  ngAfterViewInit() {
    //console.log('som v ngAfterViewInit', this.graf.nativeElement);
    this.gatherPlotData();
  }

  setInitialDate() {
    let day = formatDate(new Date(), 'yyyy-MM-dd', 'en') + " 00:00:00";
    let date = new Date(day);
    date.setDate(date.getDate() - 5);
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');

    day = formatDate(new Date(), 'yyyy-MM-dd', 'en') + " 23:59:59";
    date = new Date(day);
    date.setDate(date.getDate() + 7);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
  }

  //  change source of data, before from montel, now from database
  gatherPlotData() {
    let actualMaxTime   = 0;
    let actualMinTime   = 0;
    let backcastMaxTime = 0;
    //let forecastMaxTime = this.dateStringToUnix(this.dateUntil + " 24:00:00");

    Promise.all([
      lastValueFrom(this.analysisService.getPricesActualData(this.dateFrom, this.dateUntil, this.region,false)),
      lastValueFrom(this.analysisService.getPricesBackcastData(this.dateFrom, this.dateUntil, this.region, false)),      
      lastValueFrom(this.analysisService.getPricesForecastData(this.dateFrom, this.dateUntil, this.region, false))
    ]).then(([actualData, backcastData, forecastData]) => {
      //console.log("actual = ",actualData, actualData.length);
      //console.log("backcast = ", backcastData,backcastData.length);
      //console.log("forecast", forecastData, forecastData.length);

      let maxIndex = 0;

      if ( actualData.length > 0 ){
        this.processData(actualData, 0, 0, null, null);
        maxIndex = this.values[0].length;
      }

      if ( backcastData.length > 0 ){
        this.processData(backcastData, 1, 0, null, null );

        if ( maxIndex < this.values[1].length ) maxIndex = this.values[1].length;
      }

      if ( forecastData.length > 0 ){
        this.processData(forecastData, 2, 0, null, null );
        this.processData(forecastData, 3, 1, null, null );
        this.processData(forecastData, 4, 2, null, null );

        if ( maxIndex < this.values[2].length ) maxIndex = this.values[2].length;
      }

      this.allDataDownloaded = true;
      this.updateChartOption();
      this.isLoading = false;
      //console.log("values :", this.values);
    });
  }

  processData(jsonData: any, valueIndex: number, priceIndex: number | null, limitMin: number | null, limitMax: number | null): number {
    let myMaxTime: number = 0;
    let attrib: string = "price";

    //const dateFromUnix = this.dateStringToUnix(this.dateFrom);
    //const dateUntilUnix = this.dateStringToUnix(this.dateUntil);

    //console.log("JsonData = ",jsonData);

    if(jsonData instanceof Array){
      jsonData.forEach((element: any) => {
        let write:boolean = false;
        let time = this.dateStringToUnix(element?.year + "." + element?.month + "." + element?.day + " " + element?.hour + ":00:00");

        if ( time >= 0 ){
          // Filter data within the specified date range
          write = true;
          
          if ( limitMin !== null && limitMin > time )  write = false;
          if ( limitMax !== null && limitMax < time )  write = false;

          if ( write ){
            if (!this.timestamps.includes(time)) {
              this.timestamps.push(time);
              if ( time > myMaxTime ) myMaxTime = time; 
            }

            if ( priceIndex === null ){              // if priceIndex is null, find first not null value
              for ( let i = 0; i < 20; i++){
                attrib = "price";

                if ( i > 0 ) attrib = attrib.concat(i.toString());
                if ( element[attrib] && element[attrib] !== null ) break;
              }
            } else{
              if ( priceIndex != 0 ){
                //let index = 1;
                //for ( let i = 0; i < 14; i++){
                  attrib = "price" + priceIndex.toString();
                  //if ( element[attrib] && element[attrib] !== null ){
                  //  if ( priceIndex === index){
                  //    break;
                  //  }
                  //  index++;
                  //}
                //}
              }
            }

            if ( element[attrib] ){
              this.values[valueIndex].push( [time, element[attrib] / 100]);
            }
          }
        }
      });
    }

    return myMaxTime;
  }

  dateToUnix(dateText: string, plusDays: number = 0): number {
    let date = new Date(dateText);
    date.setDate(date.getDate() + plusDays);
    const timeInMillisecond = date.getTime();
    const unix = Math.floor(timeInMillisecond / 1000);
    // console.log(`Date ${date} to ${unix}`);
    return unix;
  }

  saveActualPrices() {
    for (let timeIndex = 0; timeIndex < 336; timeIndex++) {
      let date = new Date(this.timestamps[timeIndex] * 1000);
      // console.log(date);
      // console.log(this.values[0][timeIndex]);
      if (!this.values[0][timeIndex]) {
        continue;
      }
      let actualPrice: ActualPrice = {
        region: 'sk',
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        price: this.values[0][timeIndex][1]/100,
      };
      this.analysisService.savePricesActualData(actualPrice).subscribe({
        next: (data) => {
          console.log(data);
        },
      });
    }
  }

  dateStringToUnix(dateText: string): number {
    const date   = new Date(dateText);
    const userTZ = date.getTimezoneOffset() * 60000;

    return Math.floor( ( date.getTime() + userTZ ) / 1000);
  }

  updateChartOption() {
    // Convert dateFrom and dateUntil to Unix timestamps in milliseconds
    let   date = new Date();   
    const tz   = date.getTimezoneOffset() * 60000;

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          if (Array.isArray(params) && params.length > 0) {
            let date = new Date(params[0].value[0] - tz);          // The value[0] should be in milliseconds

            return `${formatDate(date, 'dd.MM.yyyy HH:mm', 'en')}<br/>
              ${params
                .map(
                  (param: any) =>
                    `${param.seriesName}: ${param.value[1]} Eur/MWh`
                )
                .join('<br/>')}`;
          }
          return '';
        },
      },
      legend: {
        data: ['Backcast', 'Forecast 1', 'Forecast 2', 'Forecast 3', 'Actual'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
        },
      ],
      xAxis: {
        name: 'Date / Time',
        type: 'time',
        //min: dateFromUnix, // Convert dateFrom to milliseconds
        //max: dateUntilUnix, // Convert dateUntil to milliseconds
        axisLabel: {
          formatter: (value: number) =>
            formatDate(new Date(value - tz), 'dd.MM.yyyy HH:mm', 'en'), // Use value directly
          rotate:35, // Set the rotation angle
        },
      },
      yAxis: {
        type: 'value',
        name: 'Eur / MWh',
      },
      series: [
        {
          name: 'Backcast',
          type: 'line',
          symbol: 'none',
          //symbolSize: 7,
          data: this.values[1].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: 'rgb(196,216,224)', width: 1 },
          itemStyle: { color: 'rgb(196,216,224)' },
          areaStyle: { color: 'rgb(196,216,224)', opacity: 0.5 },
          smooth:true
        },
        {
          name: 'Forecast 1',
          type: 'line',
          symbol: 'none',
          //symbolSize: 7,
          data: this.values[2].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: 'rgb(169,139,254)', width: 3 },
          itemStyle: { color: 'rgb(169,139,254)' },
          smooth:true
        },
        {
          name: 'Forecast 2',
          type: 'line',
          symbol: 'none',
          //symbolSize: 7,
          data: this.values[3].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: 'rgb(168,190,255)', width: 2 },
          itemStyle: { color: 'rgb(168,190,255)' },
          smooth:true
        },
        {
          name: 'Forecast 3',
          type: 'line',
          symbol: 'none',
          //symbolSize: 7,
          data: this.values[4].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: 'rgb(197,38,114)', width: 5 },
          itemStyle: { color: 'rgb(197,38,114)' },                    
          smooth:true
        },
        {
          name: 'Actual',
          type: 'line',
          symbol: 'none',
          //symbolSize: 7,
          data: this.values[0].map((item) => [item[0] * 1000, item[1]]), // Ensure timestamp is in milliseconds
          lineStyle: { color: 'black', width: 3 },
          itemStyle: { color: 'black' },                    
          smooth:true
        },
      ],
    };
  }

  setRegion() {
    this.analysisService.setRegion(this.region);
    this.resetPlotData();
    this.gatherPlotData();
  }

  resetPlotData() {
    this.timestamps = [];
    this.values = [[], [], [], [], []];
    this.pricesDownloaded = [false, false, false];
    this.allDataDownloaded = false;
    this.isLoading = true;
  }

  generateChart() {
    let day = formatDate(new Date(this.dateFrom), 'yyyy-MM-dd', 'en') + " 23:59:59";
    let date = new Date(day);
    date.setDate(date.getDate() + 6);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');

    this.resetPlotData();
    this.gatherPlotData();
  }
}
