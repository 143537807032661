import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Order14DaysComponent } from './order14-days/order14-days.component';
import { RouterModule } from '@angular/router';
import { OrderListComponent } from './order-request/order-list.component';
import { FormsModule } from '@angular/forms';
import { OrderPredictionComponent } from './prediction/order-prediction.component';

@NgModule({
  declarations: [Order14DaysComponent, OrderListComponent, OrderPredictionComponent],
  imports: [CommonModule, RouterModule, FormsModule],
  exports: [OrderListComponent, OrderPredictionComponent],
})
export class OrderModule {}
