import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { UserToken } from '../user/user-token';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContactUsService {
  baseUrl: string = environment.baseUrlAPI;
  contactUsUrl: string = this.baseUrl + '/contactus';
  userToken: UserToken;

  constructor( private hc: HttpClient, private userService: UserService) { 
    this.userToken = this.userService?.userToken!;
  }

  contactUs(payload:any):Observable<any> {
    return this.hc.post(this.contactUsUrl, payload);
  }
}
