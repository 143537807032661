<title>MyEnergy News: Stay Updated on Energy Trends and Insights</title>
<meta name="description" content="MyEnergy News is your primary source for the latest information about energy trends and industry advancements. Our platform keeps you up-to-date with the most important news, market updates and expert analysis so you can make informed decisions on energy use, efficiency and optimization tactics.">

<section
  class="smallHero"
  style="background-image: url(../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <h2 class="smallHero__title">MyEnergy {{'newsPage.smallHero.title' | translate}}</h2>
  </div>
</section>
<section class="newsTemplate">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="articleCard">
          <div class="articleCard__content">
            <h6 class="articleCard__date">{{'newsPage.newsTemplate.articleCard.1.date' | translate}}</h6>
            <h4 class="articleCard__title">
              {{'newsPage.newsTemplate.articleCard.1.title' | translate}}
            </h4>
            <p>
              {{ showFullText ? ('newsPage.newsTemplate.articleCard.1.description' | translate) : (('newsPage.newsTemplate.articleCard.1.description' | translate) | slice:0:310) }}
              <span *ngIf="!showFullText">...</span>
              <span *ngIf="!showFullText" (click)="showFullText = true; $event.stopPropagation();" style="color: #00bf63; cursor: pointer;">{{'newsPage.newsTemplate.articleCard.1.readMore' | translate}}</span>
              <span *ngIf="showFullText" (click)="showFullText = false; $event.stopPropagation();" style="color: #00bf63; cursor: pointer;">{{'newsPage.newsTemplate.articleCard.1.readLess' | translate}}</span>
            </p>

          </div>
          <div class="articleCard__img">
            <img src="../../assets/images/european-council.jpg" alt="img" class="img-fluid" />
          </div>
        </div>
        <div class="new_articleCard">
          <div class="articleCard__content">
            <h6 class="articleCard__date">{{'newsPage.newsTemplate.articleCard.2.date' | translate}}</h6>
            <h4 class="articleCard__title">
              {{'newsPage.newsTemplate.articleCard.2.title' | translate}}
              <span class="articleCard__subTitle"
              >{{'newsPage.newsTemplate.articleCard.2.objectives' | translate}}</span
              >
            </h4>
            <ul style="text-align: start">
              <li>{{'newsPage.newsTemplate.articleCard.2.bulletPoints.1' | translate}}
              </li>
              <li>{{'newsPage.newsTemplate.articleCard.2.bulletPoints.2' | translate}}
              </li>
            </ul>
            <a href="https://drive.google.com/drive/folders/14ibC4WsTzi01NpACXW3mCPRWnshjtMls" target="_blank">
              <button class="btn button groupbtn">{{'newsPage.newsTemplate.articleCard.2.watchHere' | translate}}</button>
            </a>

          </div>

        </div>
        <div class="articleCard">
          <div class="articleCard__content">
            <span class="articleCard__date">{{'newsPage.newsTemplate.articleCard.3.date' | translate}}</span>
            <h4 class="articleCard__title">
              {{'newsPage.newsTemplate.articleCard.3.title' | translate}}
            </h4>
            <p>
              {{'newsPage.newsTemplate.articleCard.3.description' | translate}}
            </p>
            <p>
              https://lnkd.in/gsFxcJqG . {{'newsPage.newsTemplate.articleCard.3.commentPrompt' | translate}}
            </p>
          </div>
          <div class="articleCard__img">
            <img
              src="../../assets/images/image (2).png"
              alt="img"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="articleCard">
          <div class="articleCard__content">
            <h6 class="articleCard__date"> {{'newsPage.newsTemplate.articleCard.4.date' | translate}}</h6>
            <h4 class="articleCard__title">
              {{'newsPage.newsTemplate.articleCard.4.title' | translate}}
            </h4>
            <p>
              {{'newsPage.newsTemplate.articleCard.4.description' | translate}}
            </p>
            <a href="https://www.linkedin.com/groups/12989415/" target="_blank">
              <button class="btn button groupbtn">{{'newsPage.newsTemplate.articleCard.4.joinHere' | translate}}</button>
            </a>
          </div>
          <div class="articleCard__img">
            <img
              src="../../assets/images/group.jpg"
              alt="img"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
