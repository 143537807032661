import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RequestAPresentationService {
  baseUrl: string = environment.baseUrlAPI;
  contactUsUrl: string = this.baseUrl + '/demorequests';

  constructor(private hc: HttpClient) {}

  contactUs(payload: any): Observable<any> {
    return this.hc.post(this.contactUsUrl, payload);
  }
}
