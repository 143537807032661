<title>MyEnergy Helpdesk: Support When You Need It</title>
<meta
  name="description"
  content="Do you need assistance with the services of MyEnergy? We will provide you with professional support and guidance any time you need us, thanks to our committed help desk. MyEnergy's trained team is here to guide you during every step of the way, in case you are looking for answers about platofrm usage, require troubleshooting assistance or keen to explore additional features."
/>

<div class="container-fluid">
  <section>
    <form
      name="requestForm"
      id="requestForm"
      action="#"
      method="get"
      ngForm
      (ngSubmit)="onSubmit()"
      #form="ngForm"
    >
      <section class="client_request_section">
        <div class="client_request_section__form">
          <div class="client_request_section__form-inner">
            <div class="request_card_wrapper">
              <div class="">
                <div class="request_logo_section">
                  <div class="request_title site__title">
                    <span>
                      {{
                        "registrationPage.specificConditionsDialog.hello1"
                          | translate
                      }}
                      <span class="green-text"
                        >{{ userFirstName }} {{ userLastName }}</span
                      >
                      {{
                        "registrationPage.specificConditionsDialog.hello2"
                          | translate
                      }}
                      <span class="green-text"
                        >{{ officialName }} ( {{ shortName }} )</span
                      >
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <textarea
                      class="mt-2 mb-2 p-3 rounded"
                      rows="4"
                      type="text"
                      id="description1"
                      name="description1"
                      [placeholder]="
                        'registrationPage.specificConditionsDialog.placeholder'
                          | translate
                      "
                      required
                      minlength="4"
                      maxlength="{{ descMaxLength }}"
                      [(ngModel)]="description"
                      (ngModelChange)="isDescValid($event)"
                      #test2="ngModel"
                      [ngClass]="
                        test2.valid ? 'requestValid' : 'requestInvalid'
                      "
                    >
                    </textarea>
                    <div
                      *ngIf="descInvalid != ''"
                      class="col-lg-12 invalid-input"
                      [style.top.px]="errDescY"
                      [style.left.px]="errDescX"
                    >
                      {{
                        "helpDesk.request.validator." + descInvalid | translate
                      }}
                    </div>
                  </div>

                  <div class="row mb-2 mt-2">
                    <div class="col-3 p-0">
                      {{ "helpDesk.request.phone" | translate }}
                    </div>

                    <div class="col-9 p-0">
                      {{ phoneNumber }}
                    </div>
                  </div>

                  <div class="row mb-2 mt-2">
                    <div class="col-3 p-0">
                      {{ "helpDesk.request.email" | translate }}
                    </div>
                    <div class="col-9 p-0">
                      {{ email }}
                    </div>
                  </div>

                  <div class="next">
                    <div class="row mb-2 mt-5">
                      <button
                        class="col-2 mt-2 mb-2 btn requestSubmit {{
                          buttonClass
                        }}"
                        type="submit"
                        (click)="btnSubmitClick($event)"
                        [disabled]="!form.valid"
                      >
                        {{
                          "registrationPage.specificConditionsDialog.submitButton"
                            | translate
                        }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="request_footer_wrapper">
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </section>
</div>
