<title>Terms of Service | MyEnergy</title>
<meta
  name="description"
  content="As the business landscape evolves, the Terms of Service may need to be adjusted. The Amendments will be published and take effect at the start of the next month, but only 30 days after publication on the myenergycost.eu portal."
/>

<section class="contentTemplate">
  <div class="container" id="contentToConvert">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="contentTemplate__head">
          <h2 class="contentTemplate__title">
            MyEnergy
            <span
              >{{ "termsOfService.termsOf" | translate }}
              {{ "termsOfService.service" | translate }}</span
            >
          </h2>
          <button (click)="captureScreen()" class="button button--border">
            {{ "termsOfService.printBtn" | translate }}
          </button>
        </div>
        <h6>
          {{ "termsOfService.introductionandOverview.title" | translate }}
        </h6>
        <p>
          {{ "termsOfService.introductionandOverview.contentA" | translate }}
          <br />
          <br />
          {{ "termsOfService.introductionandOverview.contentB" | translate }}
        </p>
        <h6>{{ "termsOfService.serviceDescription.title" | translate }}</h6>
        <p>{{ "termsOfService.serviceDescription.content" | translate }}</p>
        <ul>
          <li>
            {{ "termsOfService.serviceDescription.details.A" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.B" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.C" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.D" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.E" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.F" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.G" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.H" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceDescription.details.I" | translate }}
          </li>
        </ul>
        <h6>
          {{ "termsOfService.serviceLevelsandMetrics.title" | translate }}
        </h6>
        <ul>
          <li>
            {{ "termsOfService.serviceLevelsandMetrics.details.A" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceLevelsandMetrics.details.B" | translate }}
          </li>
          <li>
            {{ "termsOfService.serviceLevelsandMetrics.details.C" | translate }}
          </li>
        </ul>
        <h6>
          {{
            "termsOfService.responsibilitiesAndObligations.title" | translate
          }}
        </h6>
        <p>
          {{
            "termsOfService.responsibilitiesAndObligations.content" | translate
          }}
        </p>
        <ul>
          <li>
            {{
              "termsOfService.responsibilitiesAndObligations.details.A"
                | translate
            }}
          </li>
          <li>
            {{
              "termsOfService.responsibilitiesAndObligations.details.B"
                | translate
            }}
          </li>
        </ul>
        <p>
          {{
            "termsOfService.responsibilitiesAndObligations.client_responsibilities.title"
              | translate
          }}
        </p>
        <ul>
          <li>
            {{
              "termsOfService.responsibilitiesAndObligations.client_responsibilities.A"
                | translate
            }}
          </li>
          <li>
            {{
              "termsOfService.responsibilitiesAndObligations.client_responsibilities.B"
                | translate
            }}
          </li>
          <li>
            {{
              "termsOfService.responsibilitiesAndObligations.client_responsibilities.C"
                | translate
            }}
          </li>
        </ul>
        <h6>{{ "termsOfService.communicationProtocol.title" | translate }}</h6>
        <ul>
          <li>
            {{ "termsOfService.communicationProtocol.details.A" | translate }}
          </li>
          <li>
            {{ "termsOfService.communicationProtocol.details.B" | translate }}
          </li>
          <li>
            {{ "termsOfService.communicationProtocol.details.C" | translate }}
          </li>
          <li>
            {{ "termsOfService.communicationProtocol.details.D" | translate }}
          </li>
        </ul>
        <h6>{{ "termsOfService.problemResolution.title" | translate }}</h6>
        <p>{{ "termsOfService.problemResolution.content" | translate }}</p>
        <ul>
          <li>
            {{ "termsOfService.problemResolution.details.1" | translate }}
          </li>
          <li>
            {{ "termsOfService.problemResolution.details.2" | translate }}
          </li>
          <li>
            {{ "termsOfService.problemResolution.details.3" | translate }}
          </li>
        </ul>
        <h6>
          {{ "termsOfService.contractDurationTermination.title" | translate }}
        </h6>
        <ul>
          <li>
            {{
              "termsOfService.contractDurationTermination.details.A" | translate
            }}
          </li>
          <li>
            {{
              "termsOfService.contractDurationTermination.details.B" | translate
            }}
          </li>
        </ul>
        <h6>
          {{ "termsOfService.confidentialitySecurity.title" | translate }}
        </h6>
        <ul>
          <li>
            {{ "termsOfService.confidentialitySecurity.details.A" | translate }}
          </li>
          <li>
            {{ "termsOfService.confidentialitySecurity.details.B" | translate }}
          </li>
          <li>
            {{ "termsOfService.confidentialitySecurity.details.C" | translate }}
          </li>
        </ul>
        <h6>{{ "termsOfService.costAndPayment.title" | translate }}</h6>
        <div class="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  {{ "termsOfService.costAndPayment.columnA" | translate }}
                </th>
                <th>
                  {{ "termsOfService.costAndPayment.columnB" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ "termsOfService.costAndPayment.details.A" | translate }}
                </td>
                <td>
                  <b>{{
                    "termsOfService.costAndPayment.prices.A" | translate
                  }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  {{ "termsOfService.costAndPayment.details.B" | translate }}
                </td>
                <td>
                  <b>{{
                    "termsOfService.costAndPayment.prices.B" | translate
                  }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  {{ "termsOfService.costAndPayment.details.C" | translate }}
                </td>
                <td>
                  <b>{{
                    "termsOfService.costAndPayment.prices.C" | translate
                  }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  {{ "termsOfService.costAndPayment.details.D" | translate }}
                </td>
                <td>
                  <b>{{
                    "termsOfService.costAndPayment.prices.D" | translate
                  }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  {{ "termsOfService.costAndPayment.details.E" | translate }}
                </td>
                <td>
                  <b>{{
                    "termsOfService.costAndPayment.prices.E" | translate
                  }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h6>{{ "termsOfService.disputeResolution.title" | translate }}</h6>
        <p>{{ "termsOfService.disputeResolution.content" | translate }}</p>
        <h6>{{ "termsOfService.amendmentReview.title" | translate }}</h6>
        <p>{{ "termsOfService.amendmentReview.content" | translate }}</p>
      </div>
    </div>
  </div>
</section>
