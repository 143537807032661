import { Component, ViewChild } from '@angular/core';
import { AnalysisService } from 'src/app/analysis/analysis.service';
import uPlot, { Options } from 'uplot';
import { Details, Meta } from 'ngx-uplot/public-api';
import pricesActual from 'src/data/testovaci-cierna-actual.json';
import pricesBackcast from 'src/data/testovaci-siva-backcast.json';
import pricesForecast from 'src/data/testovaci-farebna-forecast.json';
import { read, utils, writeFile } from 'xlsx';

@Component({
  selector: 'app-backcast1week',
  templateUrl: './backcast1week.component.html',
  styleUrls: ['./backcast1week.component.scss'],
})
export class Backcast1weekComponent {
  timestamps: number[] = [];
  dataUkazka: Details = this.getData();
  optionsUkazka: any = this.getOptions();
  dataActualPrices: Details = this.getDataActual();
  optionsActualPrices: Options = this.getOptionsActual();
  movies: any[] = [
    ['111Movie', '111Category', '111Director', '111Rating'],
    ['222Movie', '222Category', '222Director', '222Rating'],
  ];

  constructor(private analysisService: AnalysisService) {}

  /* -----------==========================--------*/
  handleImport($event: any) {
    /* -----------==========================--------*/
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          this.movies = rows;
          //console.log('Filmy:', this.movies);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  /* -----------==========================--------*/
  handleExport() {
    /* -----------==========================--------*/
    const headings = [['Movie', 'Category', 'Director', 'Rating']];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.movies, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, 'Movie Report.xlsx');
  }

  getData(): Details {
    class ActualDetails implements Details {
      constructor(public data: any[][], public meta: Meta[]) {}
    }
    class ActualMeta implements Meta {
      constructor(
        public name: string,
        public show?: boolean,
        public strokeColor?: string,
        public strokeWidth?: number,
        public fillColor?: string,
        public dash?: Array<number>
      ) {}
    }

    let xs = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ];

    return new ActualDetails(
      [xs, xs.map((x) => x * 1), xs.map((x) => x * 2), xs.map((x) => x * 3)],
      new Array(
        new ActualMeta('name', true, 'red', 200, 'black', undefined),
        new ActualMeta('name', true, 'red', 200, 'black', undefined)
      )
    );
  }

  getOptions(): Options {
    return {
      width: 1000,
      height: 280,
      title: 'Titulok Grafu',
      scales: {
        x: {
          time: false,
        },
      },
      series: [
        {},
        {
          stroke: 'red',
          fill: 'rgba(255,0,0,0.1)',
        },
        {
          stroke: 'green',
          fill: 'rgba(0,255,0,0.1)',
        },
        {
          stroke: 'blue',
          fill: 'rgba(0,0,255,0.1)',
        },
      ],
    };
  }

  /* generovanie dat krivky */

  getDataActual() {
    class ActualDetails implements Details {
      constructor(public data: any[][], public meta: Meta[]) {}
    }
    class ActualMeta implements Meta {
      constructor(
        public name: string,
        public show?: boolean,
        public strokeColor?: string,
        public strokeWidth?: number,
        public fillColor?: string,
        public dash?: Array<number>
      ) {}
    }

    let jsonData = pricesActual.data;
    const values: number[][] = [[], [], [], [], []];
    let mapActual = new Map<number, number>();
    jsonData.forEach((element) => {
      let date = element.d.substring(0, 16);
      const timeInMillisecond = new Date(date).getTime();
      const unixTimestamp = Math.floor(timeInMillisecond / 1000);
      mapActual.set(unixTimestamp, element.v);
      if (!this.timestamps.includes(unixTimestamp)) {
        this.timestamps.push(unixTimestamp);
      }
    });

    jsonData = pricesBackcast.data;
    let mapBackcast = new Map<number, number>();
    let lastValue = 0;
    jsonData.forEach((element) => {
      let date = element.d.substring(0, 16);
      const timeInMillisecond = new Date(date).getTime();
      const unixTimestamp = Math.floor(timeInMillisecond / 1000);
      mapBackcast.set(unixTimestamp, element.v);
      if (!this.timestamps.includes(unixTimestamp)) {
        this.timestamps.push(unixTimestamp);
      }
      lastValue = unixTimestamp;
    });
    mapBackcast.set(lastValue, 0);

    jsonData = pricesForecast[0].data;
    let mapForecast1 = new Map<number, number>();
    jsonData.forEach((element) => {
      let date = element.d.substring(0, 16);
      const timeInMillisecond = new Date(date).getTime();
      const unixTimestamp = Math.floor(timeInMillisecond / 1000);
      mapForecast1.set(unixTimestamp, element.v);
      if (!this.timestamps.includes(unixTimestamp)) {
        this.timestamps.push(unixTimestamp);
      }
    });

    jsonData = pricesForecast[1].data;
    let mapForecast2 = new Map<number, number>();
    jsonData.forEach((element) => {
      let date = element.d.substring(0, 16);
      const timeInMillisecond = new Date(date).getTime();
      const unixTimestamp = Math.floor(timeInMillisecond / 1000);
      mapForecast2.set(unixTimestamp, element.v);
      if (!this.timestamps.includes(unixTimestamp)) {
        this.timestamps.push(unixTimestamp);
      }
    });

    jsonData = pricesForecast[2].data;
    let mapForecast3 = new Map<number, number>();
    jsonData.forEach((element) => {
      let date = element.d.substring(0, 16);
      const timeInMillisecond = new Date(date).getTime();
      const unixTimestamp = Math.floor(timeInMillisecond / 1000);
      mapForecast3.set(unixTimestamp, element.v);
      if (!this.timestamps.includes(unixTimestamp)) {
        this.timestamps.push(unixTimestamp);
      }
    });

    this.timestamps.sort();

    this.timestamps.forEach((element) => {
      values[0].push(mapActual.get(element)!);
      values[1].push(mapBackcast.get(element)!);
      values[2].push(mapForecast1.get(element)!);
      values[3].push(mapForecast2.get(element)!);
      values[4].push(mapForecast3.get(element)!);
    });

    let data = new ActualDetails(
      [this.timestamps, values[1], values[3], values[4], values[2], values[0]],
      new Array(
        new ActualMeta('name', true, 'red', 200, 'black', undefined),
        new ActualMeta('name', true, 'red', 200, 'black', undefined)
      )
    );

    return data;
  }

  getOptionsActual(): Options {
    return {
      width: 1000,
      height: 350,
      title: 'Spot price',
      series: [
        {},
        {
          label: 'Backcast',
          width: 1,
          stroke: 'rgb(196,216,224)',
          fill: 'rgb(196,216,224)',
        },
        {
          label: 'Forecast',
          width: 3,
          stroke: 'rgb(169,139,254)',
        },
        {
          label: 'Forecast',
          width: 2,
          stroke: 'rgb(168,190,255)',
        },
        {
          label: 'Forecast',
          width: 5,
          stroke: 'rgb(197,38,114)',
        },
        {
          label: 'Actual',
          width: 3,
          stroke: 'black',
        },
      ],
    };
  }

  getDataFromServer() {
    this.analysisService.getPricesActualDataOld(false).subscribe({
      next: (data) => {
        //console.log('funkcia nacitajUdajeGrafu: ...Prisli data...', data);
      },
      error: (e) => {
        //console.log('funkcia nacitajUdajeGrafu: ... CHYBA ... ', e);
      },
    });
  }
}
