import { UserToken } from './user-token';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  APIkey: string = 'B6KtVmlJjxxNucDMjBwC1jGBpXbZyikr';
  APIkeyTokenName: string = 'X-Authorization-Token';

  constructor(private userService: UserService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('AUTH INTERCEPTOR');

    if (this.userService.userToken?.token) {
      const cloneReq = request.clone({
        headers: request.headers
          .set(this.APIkeyTokenName, this.userService.userToken.token)
          .set('Accept', 'application/json')
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Credentials', 'true')
          .set(
            'Access-Control-Allow-Headers',
            'Content-Type, X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Date, X-Api-Version, X-File-Name'
          )
          .set(
            'Access-Control-Allow-Methods',
            'POST,GET,PUT,PATCH,DELETE,OPTIONS'
          ),
      });
      // console.log("Header Token: ", request.headers);
      return next.handle(cloneReq);
    }
    return next.handle(request);
  }
}
