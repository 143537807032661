import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import pricesActual from 'src/data/testovaci-cierna-actual.json';
import pricesForecast from 'src/data/testovaci-farebna-forecast.json';
import pricesBackcast from 'src/data/testovaci-siva-backcast.json';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {
  //change source of data, before from montel, now from database
  //pricesActualUrl: string = environment.baseUrlAPI + '/data/[region]/actual/1h';
  //pricesForecastUrl: string = environment.baseUrlAPI + '/data/[region]/forecast/1h';
  //pricesBackcastUrl: string = environment.baseUrlAPI + '/data/[region]/backcast/1h';

  pricesActualUrl: string = environment.baseUrlAPI + '/actuals';
  pricesForecastUrl: string = environment.baseUrlAPI + '/forecasts';
  pricesBackcastUrl: string = environment.baseUrlAPI + '/backcasts';

  freq_hodinova: string = 'PT1H';
  region = 'sk'

  baseUrl: string = environment.baseUrlAPI;
  actualsUrl: string = this.baseUrl+'/actuals';

  constructor(private hc: HttpClient) {}

  setRegion(region: string) {
    this.region = region;
  }

  //   change source of data, before from montel, now from database
  getPricesActualDataOld(test: boolean = false): Observable<any> {
    return test ? of(pricesActual) : this.hc.get<any>(this.pricesActualUrl.replace('[region]', this.region));
  }

  getPricesForecastDataOld(test: boolean = false): Observable<any[]> {
    return test ? of(pricesForecast) : this.hc.get<any>(this.pricesForecastUrl.replace('[region]', this.region));
  }

  getPricesBackcastDataOld(test: boolean = false): Observable<any> {
    return test ? of(pricesBackcast) : this.hc.get<any>(this.pricesBackcastUrl.replace('[region]', this.region));
  }
  //

  getPricesActualData(from: string, to: string, region: string, test: boolean = false): Observable<any> {
    let url: string = this.pricesActualUrl + "?from=" + from + "&to=" + to + "&region=" + region;
    //console.log("URL:",url);
    return test ? of(pricesActual) : this.hc.get<any>(url);
  }

  getPricesForecastData(from: string, to: string, region: string, test: boolean = false): Observable<any[]> {
    let url: string = this.pricesForecastUrl + "?from=" + from + "&to=" + to + "&region=" + region;
    //console.log("URL:",url);
    return test ? of(pricesForecast) : this.hc.get<any>(url);
  }

  getPricesBackcastData(from: string, to: string, region: string, test: boolean = false): Observable<any> {
    let url: string = this.pricesBackcastUrl + "?from=" + from + "&to=" + to + "&region=" + region;
    //console.log("URL:",url);
    return test ? of(pricesBackcast) : this.hc.get<any>(url);
  }

  savePricesActualData(data: any): Observable<any> {
    //console.log('Actual price saved to ' + this.actualsUrl);
    return this.hc.post<any>(this.actualsUrl, data);
  }

  // getPricesActualDataOnline(begin: string, end: string): Observable<any> {
  //   return this.hc.get<any>(
  //     `${this.urlActual}?begin=${begin}&end=${end}&frequency=${this.freq}`
  //   );
  // }

  // getPricesForecastDataOnline(begin: string, end: string): Observable<any[]> {
  //   return this.hc.get<any>(
  //     `${this.urlForecast}?begin=${begin}&end=${end}&frequency=${this.freq}`
  //   );
  // }

  // getPricesBackcastDataOnline(begin: string, end: string): Observable<any> {
  //   return this.hc.get<any>(
  //     `${this.urlBackcast}?begin=${begin}&end=${end}&frequency=${this.freq}`
  //   );
  // }
}
