<title>Order 14 days | MyEnergy</title>

<div class="pageTemplate">
  <div class="row" hidden="true">
    <div class="col-12">
      <div class="card bg-light mb-0 mt-3">
        <div class="card-header">
          <div class="row">
            <h4 class="col">Simple Setup</h4>
            <div class="col text-right m-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-caret-down-square"
                viewBox="0 0 16 16"
                (click)="toggleSimpleSetup()"
              >
                <path
                  d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"
                />
                <path
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div [hidden]="!showSimpleSetup" class="card-body text-secondary">
          <div *ngFor="let dayType of dayTypeTitles; index as dayTypeIndex">
            <h5 class="card-title">{{ dayType }}s (MWh)</h5>
            <div class="card-text">
              <div class="row">
                <div
                  *ngFor="
                    let timeRange of timeRangeTitles;
                    index as timeRangeIndex
                  "
                  class="col m-0 p-0"
                >
                  <div class="card text-center my-0">
                    <div class="card-title mt-2 mb-0">
                      {{ timeRange }}
                    </div>
                    <div class="card-body m-1 p-3">
                      <input
                        [style.background]="
                          simpleSetupValues[dayTypeIndex][timeRangeIndex] === 0
                            ? ''
                            : '#82dbd8'
                        "
                        [(ngModel)]="
                          simpleSetupValues[dayTypeIndex][timeRangeIndex]
                        "
                        (change)="
                          activateSimpleSetupCheck(dayTypeIndex, timeRangeIndex)
                        "
                        min="0"
                        max="{{ upperLimit }}"
                        type="number"
                      />
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault{{ dayTypeIndex }}{{
                            timeRangeIndex
                          }}"
                          [(ngModel)]="
                            simpleSetupChecks[dayTypeIndex][timeRangeIndex]
                          "
                        />
                        <label
                          class="form-check-label"
                          for="flexCheckDefault{{ dayTypeIndex }}{{
                            timeRangeIndex
                          }}"
                        >
                          Apply?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="!showSimpleSetup" class="text-right mb-3 mr-3">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            (click)="setValuesFromSimpleSetup(true)"
          >
            Set only for empty Fields
          </button>
          <button
            type="button"
            class="btn btn-info btn-lg"
            (click)="setValuesFromSimpleSetup(false)"
          >
            Set for all Fields
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card bg-light mt-3">
        <div class="card-header">
          <div class="row">
            <h4 class="col">Choose Plan</h4>
            <div class="col text-right m-2" [hidden]="showPlanChoose">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-caret-down-square"
                viewBox="0 0 16 16"
                (click)="togglePlanChoose()"
              >
                <path
                  d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"
                />
                <path
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"
                />
              </svg>
            </div>
            <div class="col text-right m-2" [hidden]="!showPlanChoose">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-caret-up-square"
                viewBox="0 0 16 16"
                (click)="togglePlanChoose()"
              >
                <path
                  d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                />
                <path
                  d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div [hidden]="!showPlanChoose" class="card-body text-secondary pb-2">
          <div class="row ml-1">
            <h6 class="mt-2">Use plan:</h6>
            <div class="col">
              <select
                class="form-select"
                [(ngModel)]="chosenPlan"
                (change)="choosePlan()"
              >
                <!-- <option [ngValue]="null" selected>Choose your plan...</option> -->
                <option
                  *ngFor="let plan of planList; index as planIndex"
                  [ngValue]="plan"
                >
                  {{ plan.name }}
                </option>
              </select>
            </div>
            <span [hidden]="!chosenPlan" class="mt-2"
              >Last Updated: {{ planLastUpdate }}</span
            >
            <div class="col"></div>
            <button
              type="button"
              class="btn btn-primary btn-lg mb-2 mr-2"
              [disabled]="!chosenPlan"
              (click)="setValuesFromPlan()"
            >
              Fill values from this Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card bg-light mt-3">
        <div class="card-header">
          <div class="row">
            <h4 class="col">Import from CSV</h4>
            <div class="col text-right m-2" [hidden]="showFileImport">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-caret-down-square"
                viewBox="0 0 16 16"
                (click)="toggleFileImportCard()"
              >
                <path
                  d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"
                />
                <path
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"
                />
              </svg>
            </div>
            <div class="col text-right m-2" [hidden]="!showFileImport">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-caret-up-square"
                viewBox="0 0 16 16"
                (click)="toggleFileImportCard()"
              >
                <path
                  d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                />
                <path
                  d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div [hidden]="!showFileImport" class="card-body text-secondary pb-4">
          <div class="row ml-1">
            <h6 class="mt-2">Upload a CSV file:</h6>
            <div class="col">
              <div class="input-group col">
                <div class="custom-file">
                  <input
                    type="file"
                    name="file"
                    class="custom-file-input"
                    id="inputGroupFile"
                    required
                    (change)="handleImport($event)"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <label
                    class="custom-file-label"
                    for="inputGroupFile"
                    *ngIf="!loadedFileName"
                    >Choose file</label
                  >
                  <label
                    class="custom-file-label"
                    for="inputGroupFile"
                    *ngIf="loadedFileName"
                    >{{ loadedFileName }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-4">
      <h5>Consumption: {{ totalConsumption | number }} {{ unit }}</h5>
    </div>
    <div class="col-4">
      <h5>
        Forecasted price: {{ totalForecastPrice | number }} {{ currency }}
      </h5>
    </div>
    <div class="col-2">
      <h6>Selected hours: {{ allValuesCount | number }}</h6>
    </div>
    <div class="col-2">
      <h6>Individual days: {{ individualDays }}</h6>
    </div>
  </div>

  <div class="row">
    <div
      *ngFor="let dayEntry of dayEntries; index as entryIndex"
      class="col-12"
    >
      <div class="card border-secondary mb-0 mt-3">
        <div class="card-header">
          <div class="row">
            <h4 class="col">{{ dayEntry.dateFormatted }}</h4>
            <div class="col text-right m-2">
              <div class="custom-control custom-switch" hidden="true">
                <input
                  [ngModel]="dayEntry.simpleView"
                  type="checkbox"
                  (ngModelChange)="enableSimpleView(entryIndex)"
                  role="switch"
                  class="custom-control-input"
                  id="flexSwitchCheckDefault{{ entryIndex }}"
                />
                <label
                  class="custom-control-label"
                  for="flexSwitchCheckDefault{{ entryIndex }}"
                  >Simple view</label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          [hidden]="dayEntries[entryIndex].simpleView"
          class="card-body text-secondary"
        >
          <h5 class="card-title">Hourly basis (MWh)</h5>
          <div class="card-text">
            <div class="row">
              <div
                *ngFor="let hourTitle of hourTitles; index as hourIndex"
                class="col m-0 p-0"
              >
                <div class="card text-center my-0">
                  <div class="card-title mt-1 mb-0">{{ hourTitle }}</div>
                  <span [hidden]="dayPrices[entryIndex][hourIndex] === 0"
                    >Price: {{ dayPrices[entryIndex][hourIndex] }}</span
                  >
                  <span [hidden]="dayPrices[entryIndex][hourIndex] != 0"
                    >Price Unknown</span
                  >
                  <div class="card-body m-0 p-3">
                    <input
                      [style.background]="
                        dayEntry.hourValues[hourIndex] === 0 ? '' : '#aaffee'
                      "
                      [(ngModel)]="dayEntry.hourValues[hourIndex]"
                      (change)="calculateAllValues()"
                      min="0"
                      max="{{ upperLimit }}"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [hidden]="!dayEntries[entryIndex].simpleView"
          class="card-body text-secondary"
        >
          <h5 class="card-title">Time ranges (MWh)</h5>
          <div class="card-text">
            <div class="row">
              <div
                *ngFor="
                  let timeRange of timeRangeTitles;
                  index as timeRangeIndex
                "
                class="col m-0 p-0"
              >
                <div class="card text-center my-0">
                  <div class="card-title mt-2 mb-0">
                    {{ timeRange }}
                  </div>
                  <div class="card-body m-1 p-3">
                    <input
                      [style.background]="
                        dayEntry.timeRangeValues[timeRangeIndex] === 0
                          ? ''
                          : '#82dbd8'
                      "
                      [(ngModel)]="dayEntry.timeRangeValues[timeRangeIndex]"
                      min="0"
                      max="{{ upperLimit }}"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <button
              type="button"
              class="btn btn-info btn-lg"
              (click)="calculateHourValuesFromTimeRanges(entryIndex)"
            >
              Set for this Day
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-4">
      <h5>Consumption: {{ totalConsumption | number }} {{ unit }}</h5>
    </div>
    <div class="col-4">
      <h5>
        Forecasted price: {{ totalForecastPrice | number }} {{ currency }}
      </h5>
    </div>
    <div class="col-2">
      <h6>Selected hours: {{ allValuesCount | number }}</h6>
    </div>
    <div class="col-2">
      <h6>Individual days: {{ individualDays }}</h6>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right my-3">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        (click)="submitValues()"
      >
        Make an Order
      </button>
    </div>
  </div>
</div>
