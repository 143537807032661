<title>>MyEnergy analysis: cost of consumption for 1 week</title>
<meta
  name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast."
/>

<div class="pageTemplate">
  <div class="row">
    <div class="col">
      <h1 class="text-center m-3">1 Week Analysis</h1>

      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">{{
                "priceToday.market" | translate
              }}</label>
            </div>
            <select
              class="custom-select"
              [(ngModel)]="region"
              (change)="setRegion()"
              id="inputGroupSelect01"
            >
              <option selected [ngValue]="'sk'">Slovakia</option>
              <option [ngValue]="'cz'">Czech Republic</option>
              <option [ngValue]="'hu'">Hungary</option>
            </select>
          </div>
        </div>

        <!-- <div class="col-12 col-sm-6 col-lg-3"></div> -->
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="input-group mb-3 text-right">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01"
                >{{ "priceToday.from" | translate }}:</label
              >
            </div>
            <input
              type="date"
              [(ngModel)]="dateFrom"
              class="form-control"
              aria-label="Text input with checkbox"
              value="2023-11-03"
              (change)="generateChart()"
            />
          </div>
        </div>
      </div>

      <body class="graf img-fluid" #graf></body>

      <div>
        <!-- <ngx-uplot [data]="plotData" [options]="plotOptions" [legend]="true">
        </ngx-uplot> -->
        <div
          echarts
          [options]="chartOption"
          [loading]="isLoading"
          theme="macarons"
          class="demo-chart"
        ></div>
      </div>
      <div class="row p-3"></div>

      <!-- <button
        *ngIf="userService.userToken?.login !== 'visitor'"
        type="button"
        class="btn btn-secondary col"
        (click)="saveActualPrices()"
      >
        Dev: Save Actual Prices
      </button> -->

      <div class="row">
        <div class="col-9"></div>
        <div class="col-3 fa-3x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-printer-fill fa-3x"
            viewBox="0 0 16 16"
          >
            <path
              d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
            />
            <path
              d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-box-arrow-down"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
            />
          </svg>
        </div>
      </div>

      <!-- <p>
        mockupu obrazovka 
    </p>
    <p>
        <img src="../../../assets/screens/analysis-1week.GIF" alt="">
    </p> -->
    </div>
  </div>
</div>
