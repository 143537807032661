import { UserToken } from './../user/user-token';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactUsService } from './contact-us.service';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})

export class ContactUsComponent implements OnInit {
  @ViewChild('form') form: FormGroup;  
  
  newContactInfo: any;
  showMessage: boolean = false;
  user:UserToken;
  contactUsText: any;

  firstNameValid: string = "";
  lastNameValid: string = "";
  emailValid: string = "";
  phoneValid: string = "";
  messageValid: string = "";
  counter : number = 0;
  messageMaxLength: number = 1500;
  //myLang: string;
  //myLangJson: any;

  constructor(private toastr: ToastrService,private contactUsService:ContactUsService, private router: Router, private headerComponent: HeaderComponent, private translate: TranslateService) {
    this.user = this.contactUsService?.userToken!;

    //this.myLang = headerComponent.selectedLanguage.value;
  }

  ngOnInit() {
    this.newContactInfo = {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      phone:""
    };

    if (this.user) {
      this.newContactInfo.email=this.user.email
      if (this.user.fullName !== '') {
        let names = this.user.fullName.split(' ');
        if (names.length > 0) {
            this.newContactInfo.firstname = names[0];
        }
        if (names.length > 1) {
            this.newContactInfo.lastname = names.slice(1).join(' ');
        }
      }
    }
  }

  ngAfterViewInit() {
    //this.myLang = this.headerComponent.selectedLanguage.value;
    //this.loadLangJson();
  }


  nullFormItems(){
    this.newContactInfo = {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      phone:""
    }

    this.form.value.id=null
    this.form.value.user_id=null
    this.form.value.status="pending"
    this.form.value.created_at=null
    this.form.value.updated_at=null

  }

  contactUs() {
    //console.log(this.form);

    var test: boolean = true;

    if (! this.form.valid) {
      test = false;
    }

    if (
    this.firstNameValid != "" &&
    this.lastNameValid != "" &&
    this.emailValid != "" &&
    this.phoneValid != "" &&
    this.messageValid != "" ){ 
      test = false
    }

    if ( ! test ){      
      this.translate.get('contactUs.validator.toastrError').subscribe((data1: string) => {
        this.translate.get('contactUs.validator.toastrError2').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });      
      });      

      return;
    }

    this.form.value.id=null
    this.form.value.user_id=null
    this.form.value.status="pending"
    this.form.value.created_at=null
    this.form.value.updated_at=null

    this.contactUsService.contactUs(this.form.value).subscribe((data) => {
      if (data) {
        this.translate.get('contactUs.validator.toastrSuccess').subscribe((data1: string) => {
          this.translate.get('contactUs.validator.toastrSuccess2').subscribe((data2: string) => {
            this.toastr.success(data2,data1);
          });      
        });      

        this.showMessage = true;
      }
    });

    this.router.navigate(['/']);

    // emailjs.send('service_amhuwbw', 'template_78hgoj7', this.newContactInfo, 'f_mRhH-xgBWniXTZN')
    // .then((result: EmailJSResponseStatus) => {
    //   console.log(result.text);
    //   this.showMessage = true;
    // }, (error) => {
    //   console.log(error.text);
    // });

  }

  isFirstNameValid(event: any){
    this.firstNameValid = "";

    if ( event.length < 2 ){
      this.firstNameValid = "contactUs.validator.firstName";
    }

    return this.firstNameValid;
  }

  isLastNameValid(event: any){
    this.lastNameValid = "";

    if ( event.length < 2 ){
      this.lastNameValid = "contactUs.validator.lastName";
    }

    return this.lastNameValid;
  }

  isEmailValid(event: any){
    this.emailValid = "";

    //if ( ! /^[\w\.]+@[\w]+\.[\w]+$/.test(event) ) {
    if ( ! /^[a-z0-9\._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event) ){
      this.emailValid = "contactUs.validator.email";
    }

    return this.emailValid;
  }

  isPhoneValid(event: any){
    this.phoneValid = "";

    //console.log(event);
    if ( event.length < 6 ){
      this.phoneValid = "contactUs.validator.phoneLength";
    }

    if ( ! /^\+*[\d \-]*$/.test(event) ) {    
      this.phoneValid = "contactUs.validator.phone";
    }

    return this.phoneValid;
  }

  isMessageValid(event:any){
    this.messageValid = "";
    this.counter = event.length;

    // console.log(this.counter);

    if ( event.length < 4 ){
      this.messageValid = "contactUs.validator.messageMin";
    }

    if ( event.length > this.messageMaxLength ){
      this.messageValid = "contactUs.validator.messageMax";
    }

    return this.messageValid;
  }

  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
}
