import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/admin.service';
import { Client } from 'src/app/admin/client';
import { User } from 'src/app/user/user';
import { UserService } from 'src/app/user/user.service';
import { forkJoin } from 'rxjs';
import { PlanService } from '../plan.service';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Details, Meta } from 'ngx-uplot/public-api';
//import uPlot, { Options } from 'uplot';
import { formatDate } from '@angular/common';
import { EChartsOption, getInstanceByDom, connect } from 'echarts';
import { TranslateService } from '@ngx-translate/core';
import { BusinessUnitLocationsService } from 'src/app/business-unit-locations/business-unit-locations.service';

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.scss'],
})
export class AllPlansComponent {
  userList: User[];
  newUser: User;
  accessRights: any = [];
  emailPattern = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';
  newUserMenuOpen: boolean = false;

  // char variables
  plotData: any ;
  plotOptions: EChartsOption;
  allDataDownloaded: boolean = false;
  dateFrom: number = 0;
  dateUntil: number = 0;
  dayArray: number[] = [];
  cnsArray: number[] = [];
  plotArray: Array<[number, number]> = [];
  chartElement: any;
  isLoading: boolean = false;

  // pagination variables, arrays, first for dayPLans, second for intradayPlans

  paginatorValues: number[][] = [];  // 0 - day plan, 1 - intraday plan

  // paginatorValues[][0] // items per page
  // paginatorValues[][1] // current page
  // paginatorValues[][2] // last page
  // paginatorValues[][3] // total items
  // paginatorValues[][4] // first item
  // paginatorValues[][5] // last item

  clientList: Client[] = [];
  userDetails: any = {
    firstName: 'Test',
    lastName: 'Test',
    email: 'Test@gmail.com',
    userRole: 'User',
  };

  loadingFinished: boolean = true;

  displayedColumns: string[] = [
    'name',
    'startDate',
    'endDate',
    'location',
    'totalCost',
    'createdBy',
    'updatedBy',
    'status',
    'actions',
  ];
  displayedColumnsDayPlans: string[] = [
    'name',
    'startDate',
    'endDate',
    'location',
    'totalCost',
    'createdBy',
    'updatedBy',
    'status',
    'actions',
  ];

  dayPlansDataSource: MatTableDataSource<User>;
  intradayPlansDataSource: MatTableDataSource<User>;

  loggedMasterUser: any;
  selectedData: any;
  clientData: any;
  dayConsumptionPlans: any = [];
  intradayConsumptionPlans: any = [];
  selectedPlan: any;
  selectedPlanConsumptions: any;

  displayedColumnsnIntradayPlan: string[] = [
    'time',
    'consumption',
    'minimum',
    'maximum',
    'actions',
  ];
  displayedColumnsnIntradayPlanReview: string[] = [
    'time',
    'consumption',
    'minimum',
    'maximum',
    'totalCost',
  ];
  displayedColumnsDayPlan: string[] = [
    'date',
    'consumption',
    'minimum',
    'maximum',
    'totalCost',
  ];
  totalConsumption: any = 0;
  avgConsumption: any = 0;
  dayPlanDisclaimer: boolean = false;
  intradayPlanDisclaimer: boolean = false;
  exportType: any='';
  exportPlanType: any;
  loading: boolean = false;
  loadingDay: boolean = false;
  errorDay: string    = "";
  loadingIntraday: boolean = false;
  errorIntraday: string    = "";

  locations:any
  businessUnits:any

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  @ViewChild('dialogTemplate2') dialogTemplate2: TemplateRef<any>;
  @ViewChild('dayPaginator')      dayPaginator: MatPaginator;
  @ViewChild('intradayPaginator') intradayPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private planService: PlanService,
    public translate: TranslateService,
    private businessUnitLocationsService: BusinessUnitLocationsService
  ) { this.dayPlansDataSource = new MatTableDataSource<any>([]);
      this.intradayPlansDataSource = new MatTableDataSource<any>([]);

      this.paginatorValues[0] = [];
      this.paginatorValues[1] = [];

      this.paginatorValues[0][0] = 5;    // items per page of day plans
      this.paginatorValues[0][1] = 0;    // current page of day plans
      this.paginatorValues[0][2] = 0;    // last page of day plans
      this.paginatorValues[0][3] = 0;    // total items of day plans
      this.paginatorValues[0][4] = 1;    // first item of page of day plans
      this.paginatorValues[0][5] = 5;    // last item  of page of day plans

      this.paginatorValues[1][0] = 5;   // items per page of intraday plans
      this.paginatorValues[1][1] = 0;    // current page of intraday plans
      this.paginatorValues[1][2] = 0;    // last page of of intraday plans
      this.paginatorValues[1][3] = 0;    // total items of of intraday plans
      this.paginatorValues[1][4] = 1;    // first item of page of intraday plans
      this.paginatorValues[1][5] = 5;   // last item  of page of intraday plans

    }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initializeView();
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService
        .getClient(this.loggedMasterUser.client_id)
        .subscribe((data) => {
          this.clientData = data;
        });
    }
    this.getAllPlans();
    this.getBusinessUnits()
    this.getLocations()
  }

  handlePage(event: any, index: number){
    //console.log("event,index':", event, index);

    this.paginatorValues[index][0] = event.pageSize;    // items per page of day plans
    this.paginatorValues[index][1] = event.pageIndex;   // current page of day plans
    this.paginatorValues[index][2] = 0;                 // last page of day plans
    this.paginatorValues[index][3] = event.length;      // total items of day plans
    this.paginatorValues[index][4] = 1;                 // first item of page of day plans
    this.paginatorValues[index][5] = 5;                 // last item  of page of day plans

    //console.log("this.paginatorValues:",this.paginatorValues);

    if ( index === 0 ){
      this.getDayPlan();
    }

    if ( index === 1 ){
      this.getIntradayPlan();
    }
  }

  getDayPlan() {
    this.loadingDay = true;
    this.errorDay = "";

    dayPlans: this.planService.getPlansWithParams("/day","page=" + ( this.paginatorValues[0][1] + 1 )+ "&" + "perPage=" + ( this.paginatorValues[0][0]) + "&order=id:desc,name:desc" )
    .subscribe( {next: (results) => {
      this.dayConsumptionPlans = results?.data;

      this.paginatorValues[0][0] = results.meta?.per_page;              // items per page of day plans
      this.paginatorValues[0][1] = ( results.meta?.current_page - 1 );  // current page of day plans
      this.paginatorValues[0][2] = ( results.meta?.last_page - 1 );     // last page of day plans
      this.paginatorValues[0][3] = results.meta?.total;                 // total items of day plans
      this.paginatorValues[0][4] = results.meta?.from;                  // first item of page of day plans
      this.paginatorValues[0][5] = results.meta?.to;                    // last item  of page of day plans

      //console.log("Day: ", results);
      //console.log("paginator: ", this.paginatorValues);

      for (let plan of this.dayConsumptionPlans) {
        plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
        plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

        //console.log("start date:", plan.consumptions[0])
        plan.startDate = this.getDate(plan.consumptions[0]);
        //console.log("end date:", plan.consumptions[plan.consumptions.length - 1]);
        plan.endDate = this.getDate(
          plan.consumptions[plan.consumptions.length - 1]
        );
      }

      this.dayConsumptionPlans.sort((a: any, b: any) => {
        let dateA: any = new Date(a.startDate),
          dateB: any = new Date(b.startDate);
        return dateA - dateB;
      });

      this.dayPlansDataSource = new MatTableDataSource(
        this.dayConsumptionPlans
      );
      //this.dayPlansDataSource.paginator = this.dayPaginator;
      this.dayPlansDataSource.sort = this.sort;

      this.loadingDay = false;
    },
    error: ( error ) => { //console.log(error);
      this.errorDay = "plans.error.network";
      this.loadingDay = false;
    }
  });
  }

  getIntradayPlan(){
    this.loadingIntraday = true;
    this.errorIntraday = "";

    intradayPlans: this.planService.getPlansWithParams("/intraday", "page=" + ( this.paginatorValues[1][1] + 1 ) + "&" + "perPage=" + ( this.paginatorValues[1][0]) + "&order=id:desc,name:desc" )
    .subscribe({ next: (results: any) => {
      this.intradayConsumptionPlans = results?.data;

      this.paginatorValues[1][0] = results.meta?.per_page;              // items per page of day plans
      this.paginatorValues[1][1] = ( results.meta?.current_page - 1 );  // current page of day plans
      this.paginatorValues[1][2] = ( results.meta?.last_page - 1 );     // last page of day plans
      this.paginatorValues[1][3] = results.meta?.total;                 // total items of day plans
      this.paginatorValues[1][4] = results.meta?.from;                  // first item of page of day plans
      this.paginatorValues[1][5] = results.meta?.to;                    // last item  of page of day plans

      //console.log("intraDay\n", results);

      for (let plan of this.intradayConsumptionPlans) {
        plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
        plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

        plan.startDate = this.getDate(plan.consumptions[0]);
        plan.endDate = this.getEndDateIntraday(plan.consumptions[0]);
      }

      this.intradayConsumptionPlans.sort((a: any, b: any) => {
        let dateA: any = new Date(a.startDate),
          dateB: any = new Date(b.startDate);
        return dateA - dateB;
      });

      this.intradayPlansDataSource = new MatTableDataSource(
        this.intradayConsumptionPlans
      );
      //this.intradayPlansDataSource.paginator = this.intradayPaginator;
      this.intradayPlansDataSource.sort = this.sort;

      this.loadingIntraday = false;
    },
    error: ( error: any ) => { //console.log(error);
      this.loadingIntraday = false;
      this.errorIntraday = "plans.error.network";
    }
    });
  }

  getAllPlans(){
    this.getDayPlan();
    this.getIntradayPlan();
  }

  getAllPlans2() {
    this.loading = true;

    forkJoin({
      dayPlans: this.planService.getPlans('1/day?page=' + ( this.paginatorValues[0][1] + 1) ),
      intradayPlans: this.planService.getPlans('1/intraday?page=' + ( this.paginatorValues[1][1] + 1 ) ),
    }).subscribe({ next: (results: any) => {
      //console.log("results :\n", results);
      //console.log("day :\n", results.dayPlans);
      //console.log("intraday :\n", results.intradayPlans);

      this.dayConsumptionPlans = results.dayPlans?.data;
      this.intradayConsumptionPlans = results.intradayPlans?.data;

      //this.paginatorValues[0][0] = 5;                                            // items per page of day plans
      this.paginatorValues[0][1] = ( results.dayPlans.meta?.current_page - 1 );  // current page of day plans
      this.paginatorValues[0][2] = ( results.dayPlans.meta?.last_page - 1 );     // last page of day plans
      this.paginatorValues[0][3] = results.dayPlans.meta?.total;                 // total items of day plans
      this.paginatorValues[0][4] = results.dayPlans.meta?.from;                  // first item of page of day plans
      this.paginatorValues[0][5] = results.dayPlans.meta?.to;                    // last item  of page of day plans

      //this.paginatorValues[1][0] = 5;                                                 // items per page of day plans
      this.paginatorValues[1][1] = ( results.intradayPlans.meta?.current_page - 1 );  // current page of day plans
      this.paginatorValues[1][2] = ( results.intradayPlans.meta?.last_page - 1 );     // last page of day plans
      this.paginatorValues[1][3] = results.intradayPlans.meta?.total;                 // total items of day plans
      this.paginatorValues[1][4] = results.intradayPlans.meta?.from;                  // first item of page of day plans
      this.paginatorValues[1][5] = results.intradayPlans.meta?.to;                    // last item  of page of day plans

      for (let plan of this.dayConsumptionPlans) {
        plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
        plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

        plan.startDate = this.getDate(plan.consumptions[0]);
        plan.endDate = this.getDate(
          plan.consumptions[plan.consumptions.length - 1]
        );
      }
      for (let plan of this.intradayConsumptionPlans) {
        plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
        plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');

        plan.startDate = this.getDate(plan.consumptions[0]);
        plan.endDate = this.getEndDateIntraday(plan.consumptions[0]);
      }

      this.dayConsumptionPlans.sort((a: any, b: any) => {
        let dateA: any = new Date(a.startDate),
          dateB: any = new Date(b.startDate);
        return dateA - dateB;
      });
      this.intradayConsumptionPlans.sort((a: any, b: any) => {
        let dateA: any = new Date(a.startDate),
          dateB: any = new Date(b.startDate);
        return dateA - dateB;
      });

      this.dayPlansDataSource = new MatTableDataSource(
        this.dayConsumptionPlans
      );
      //this.dayPlansDataSource.paginator = this.dayPaginator;
      this.dayPlansDataSource.sort = this.sort;

      this.intradayPlansDataSource = new MatTableDataSource(
        this.intradayConsumptionPlans
      );
      //this.intradayPlansDataSource.paginator = this.intradayPaginator;
      this.intradayPlansDataSource.sort = this.sort;
      this.loading = false;
    },
    error: ( error: any ) => { //console.log(error);
      this.loading = false;
    }
    });
  }

  initializeView() {
    this.adminService.getUsers().subscribe({
      next: (data) => {
        this.userList = data;
      },
    });
  }

  applyFilter(event: Event, type: string) {
    if (type == 'day') {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dayPlansDataSource.filter = filterValue.trim().toLowerCase();

      if (this.dayPlansDataSource.paginator) {
        this.dayPlansDataSource.paginator.firstPage();
      }
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      this.intradayPlansDataSource.filter = filterValue.trim().toLowerCase();

      if (this.intradayPlansDataSource.paginator) {
        this.intradayPlansDataSource.paginator.firstPage();
      }
    }
  }
  getCreatedByName(id: number) {
    if ( this.userList ){
      let user: any = this.userList.find((item: any) => item.id === id);
      return user.fullName.charAt(0).toUpperCase() + user.fullName.slice(1);
    } else {
      return "";
    }
  }
  openCofirmationDialog(data: any) {
    this.selectedData = data;
    this.dialog.open(this.dialogTemplate);
  }

  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
  deleteConsumptionPlan() {
    this.planService.deletePlan(this.selectedData.id).subscribe((data) => {
      this.getAllPlans();
      this.dialog.closeAll();
      this.translate.get('plans.toastr.deleteSuccess').subscribe((data1: string) => {
        this.translate.get('plans.toastr.success').subscribe((data2: string) => {
          this.toastr.success(data1,data2);
        });
      });
    });
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }
  editConsumptionPlan(id: number) {
    this.routeChecker(`plan/create/${id}`)
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  getHourName(hour: number) {
    let hourName;
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00 hour`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00 hour`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00 hour`;
    }
    return hourName;
  }
  getIntervals(value: string) {
    let interval = '';
    if (value === '60') return (interval = 'hourly');       // value is key for translation, do not translate
    if (value === '30') return (interval = 'hHourly');      // value is key for translation, do not translate
    if (value === '15') return (interval = 'quarterly');    // value is key for translation, do not translate
    return interval;
  }

  viewModal(data: any) {
    this.selectedPlan = data;
    this.plotOptions = this.generatePlotOptions(data);

    //console.log(this.plotOptions);

    this.totalConsumption = this.selectedPlan.consumptions
      .map((t: any) => t.consumption)
      .reduce((acc: any, value: any) => Number(acc) + Number(value), 0);
    this.avgConsumption =
      this.totalConsumption / this.selectedPlan.consumptions.length;
    this.selectedPlanConsumptions = new MatTableDataSource(
      this.selectedPlan.consumptions
    );

    this.allDataDownloaded = true;
  }

  getCurrentDateTime(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  transformCost(value: number): string | null {
    // Format the number with dot as the decimal separator
    if (value) {
      const formattedNumber = value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      // Append the euro symbol
      return `${formattedNumber}`;
    }
    return null;
  }
  checkCalculatedCost(element: any) {
    for (let item of element.consumptions) {
      if (element.consumption_cost_calculated) {
        if (!item.consumption_cost_calculated) {
          if (element.plan_type == 'day') this.dayPlanDisclaimer = true;
          if (element.plan_type == 'intraday')
            this.intradayPlanDisclaimer = true;
          return '*';
        }
      }
    }
    return '';
  }

  generateCSV(data: any) {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Sheet 1');

    // Add header details
    worksheet.getCell('A1').value = 'Plan Details';
    worksheet.getCell('A1').font = { bold: true };
    worksheet.mergeCells('A1:F1');

    worksheet.getCell('A2').value = 'Name:';
    worksheet.getCell('A2').font = { bold: true };
    worksheet.getCell('A3').value = data.name;

    worksheet.mergeCells('A2:B2');
    worksheet.mergeCells('A3:B3');

    worksheet.getCell('C2').value = 'Client:';
    worksheet.getCell('C2').font = { bold: true };
    worksheet.getCell('C3').value = this.clientData.name;

    worksheet.mergeCells('C2:D2');
    worksheet.mergeCells('C3:D3');

    worksheet.getCell('E2').value = 'Created by:';
    worksheet.getCell('E2').font = { bold: true };
    worksheet.getCell('E3').value = this.getCreatedByName(data.created_by);

    worksheet.mergeCells('E2:F2');
    worksheet.mergeCells('E3:F3');

    worksheet.getCell('A4').value = 'Description:';
    worksheet.getCell('A4').font = { bold: true };
    worksheet.getCell('A5').value = data.description;

    worksheet.mergeCells('A4:F4');
    worksheet.mergeCells('A5:F5');

    worksheet.getCell('A7').value = 'Created at:';
    worksheet.getCell('A7').font = { bold: true };
    worksheet.getCell('A8').value = data.created_at;

    worksheet.mergeCells('A7:B7');
    worksheet.mergeCells('A8:B8');

    worksheet.getCell('C7').value = 'Updated at:';
    worksheet.getCell('C7').font = { bold: true };
    worksheet.getCell('C8').value = data.updated_at;

    worksheet.mergeCells('C7:D7');
    worksheet.mergeCells('C8:D8');

    worksheet.getCell('E7').value = 'Type:';
    worksheet.getCell('E7').font = { bold: true };
    worksheet.getCell('E8').value = data.plan_type;

    worksheet.getCell('F7').value = 'Status:';
    worksheet.getCell('F7').font = { bold: true };
    worksheet.getCell('F8').value = data.status;

    worksheet.getCell('A9').value = 'Exported by:';
    worksheet.getCell('A9').font = { bold: true };
    worksheet.getCell('A10').value = this.loggedMasterUser.fullName;

    worksheet.mergeCells('A9:B9');
    worksheet.mergeCells('A10:B10');

    worksheet.getCell('C9').value = 'Exported at:';
    worksheet.getCell('C9').font = { bold: true };
    worksheet.getCell('C10').value = this.getCurrentDateTime();

    worksheet.mergeCells('C9:D9');
    worksheet.mergeCells('C10:D10');

    worksheet.getCell('E9').value = 'Interval:';
    worksheet.getCell('E9').font = { bold: true };
    worksheet.getCell('E10').value = data.interval;

    worksheet.getCell('F9').value = 'Unit:';
    worksheet.getCell('F9').font = { bold: true };
    worksheet.getCell('F10').value = data.unit;

    worksheet.getCell('A11').value = 'Plan Data';
    worksheet.getCell('A11').font = { bold: true };
    worksheet.mergeCells('A11:F11');

    worksheet.addRow([
      'Date:',
      'Start time:',
      'End time:',
      'Consumption:',
      'Min:',
      'Max:',
    ]);

    if (data.plan_type === 'day') {
      // Add rows to the worksheet
      data.consumptions.forEach((item: any) => {
        const date = `${item.year}-${item.month
          .toString()
          .padStart(2, '0')}-${item.day.toString().padStart(2, '0')}`;
        // const startTime = `${item.hour.toString().padStart(2, '0')}:${item.minutes.toString().padStart(2, '0')}`;
        const startTime = '00:00';
        const endTime = startTime; // Assuming start time and end time are the same
        worksheet.addRow([
          date,
          startTime,
          endTime,
          item.consumption,
          item.min,
          item.max,
        ]);
      });
    } else {
      data.consumptions.forEach((item: any) => {
        const date = `${item.year}-${item.month
          .toString()
          .padStart(2, '0')}-${item.day.toString().padStart(2, '0')}`;
        let startTime;
        let endTime;
        if (item.hour === 9) {
          startTime = `0${item.hour}:00`;
          endTime = `${item.hour + 1}:00`;
        } else if (item.hour < 9) {
          startTime = `0${item.hour}:00`;
          endTime = `0${item.hour + 1}:00`;
        } else if (item.hour > 9) {
          startTime = `${item.hour}:00`;
          endTime = `${item.hour + 1}:00`;
        }
        worksheet.addRow([
          date,
          startTime,
          endTime,
          item.consumption,
          item.min,
          item.max,
        ]);
      });
    }

    // Write to XLSX
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');

      let blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(
        blob,
        `${
          this.clientData?.shortName?.toUpperCase() +
          ' - ' +
          data.name +
          ' - ' +
          year +
          '-' +
          month +
          '-' +
          day +
          ' ' +
          hours +
          '' +
          minutes
        }.xlsx`
      );
    });
  }
  getDate(data: any) {
    let month = "01";
    let day   = "01"

    if (data) {
      month = `${data.month}`;
      if (data.month < 10) {
        month = "0" + month;
      }

      day = `${data.day}`;
      if (data.day < 10) {
        day = "0" + day;
      } 

      let formattedDate = `${data.year}-${month}-${day}`;
      formattedDate += ' 00:00:00';
      return formattedDate;
    } else {
      return '----';
    }
  }
  redirectToPlanCreation(type: string) {
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/plan/create/0`, { type }]);
  }

  exportPlans() {
    let payload: any = {
      consumption_plan_ids: [],
      export_type: this.exportType,
    };
    if (this.exportPlanType == 'intraday') {
      for(let item of this.intradayConsumptionPlans){
        payload.consumption_plan_ids.push(item.id);
      }
    }
    if (this.exportPlanType == 'day') {
      for(let item of this.dayConsumptionPlans){
        payload.consumption_plan_ids.push(item.id);
      }
    }

    let requestOptions = {
      responseType: 'blob' as 'json',
    };
    this.planService.exportConsumptionsPlans(payload, requestOptions).subscribe(
      (response: Blob) => {
        this.dialog.closeAll();
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Consumption plans.${this.exportType}`;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
        this.exportPlanType = '';
        this.exportType = '';
      },
      (error) => {
        console.error('Error exporting consumption plans:', error);
      }
    );
  }
  getBusinessUnits() {
    this.businessUnitLocationsService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data
    })

  }
  getLocations() {
    this.businessUnitLocationsService.getLocations().subscribe((data) => {
      this.locations = data
    })
  }
  getBusinessUnitName(id:any){
    if(id){
      if ( this.businessUnits){
        let item=this.businessUnits.find((item:any)=>item.id===id)

        if ( item ){
          let name=this.capitalizeFirstLetter(item.identifier)
          return name ? name : "";
        } else return "";
      } else {
        return "";
      }
    }else{
      return 'Not Assigned'
    }
  }
  getLocationName(id:any){
    if(id){
      let item=this.locations.find((item:any)=>item.id===id)
      let name=this.capitalizeFirstLetter(item.identifier)
      return name
    }else{
      return 'Not Assigned'
    }
  }

  openFormatDialog(planType: string) {
    //console.log("Som tu");
    this.exportPlanType = planType;
    if (this.exportPlanType == 'intraday') {
      if(this.intradayConsumptionPlans.length===0) return
    }
    if (this.exportPlanType == 'day') {
      if(this.dayConsumptionPlans.length===0) return
    }
    this.dialog.open(this.dialogTemplate2);
  }

  getEndDateIntraday(data: any) {
    if (data) {
      let year = data.year;
      let month = data.month;
      let day = data.day;
      let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        daysInMonth[1] = 29;
      }
      day++;
      if (day > daysInMonth[month - 1]) {
        day = 1;
        month++;
      }
      if (month > 12) {
        month = 1;
        year++;
      }
      let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`;
      formattedDate += ' 00:00:00';
      return formattedDate;
    } else {
      return '----';
    }
  }

  gatherPlotData( data: any) {
    // console.log(data);

    let plotArray : Array<[number,number]> = [];
    let index = 0;
    let dayArray: number[] = [];
    let cnsArray: number[] = [];

    data?.consumptions.forEach((element: any) => {
      let day = new Date(element?.year, ( element?.month - 1 ), element?.day, element?.hour, element?.minutes).getTime();
      dayArray[index] = day;
      cnsArray[index] = element?.consumption;

      plotArray[index] = [day,element?.consumption] ;
      index++;
    });

    this.dayArray   = dayArray;
    this.cnsArray   = cnsArray;
    this.plotArray  = plotArray;

    return;
  }

  generatePlotOptions( data: any ): EChartsOption {

    this.gatherPlotData( data );

    // console.log(this.dayArray);

    const dateFromUnix = new Date(this.dateFrom).getTime();
    const dateUntilUnix = new Date(this.dateUntil).getTime();
    var opts: EChartsOption = {
      title: {
        //text: 'Consumption',
        //textAlign: 'left'
      },
      tooltip: {
        trigger: 'axis',
        //axisPointer: {
        //  type: 'cross'
        //}
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
        },
      ],
      xAxis: {
        name: 'Date',
        nameLocation: 'end',
        nameRotate: 0,
        nameGap: 30,
        //type: 'time',
        data:  this.dayArray.map( (day: any)  => {
          const date = new Date(day);

          if ( this.selectedPlan.plan_type == "day" ){
            return formatDate(date, 'dd.MM.yyyy', 'en') ;
          } else {
            return formatDate(date, 'HH:mm:ss', 'en') ;
          }
        }),
        axisLabel:{
          rotate: 45
        }
      },
      yAxis: {
        name: this.selectedPlan.unit,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 40,
        nameTextStyle:{
          align: 'left'
        },
        type: 'value'
        //axisLabel: 'kWh'
      },
      series: [
        { name: 'Consumption',
          data: this.cnsArray,
          type: 'line',
          smooth: true
        },
      ],
    }

    return opts;

  }
}
