<title>MyEnergy : Business Units & Locations</title>
<meta
  name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast."
/>

<div class="pageTemplate">
  <!-- <div class="text-center mt-3">
    <div class="spinner-border text-info" role="status"></div>
  </div> -->
  <div>
    <div class="headingBlock">
      <div
        class="headingBlock__title site__title text-center m-3"
        *ngIf="!isLoading"
      >
        {{ "business-units-locations.title" | translate }}
        <span style="color: #00bf63">
          {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}
        </span>
      </div>
    </div>

    <body class="graf img-fluid" #graf></body>

    <div class="text-center" *ngIf="isLoading">
      <div class="spinner-border text-info" role="status"></div>
    </div>

    <div
      style="
        margin: auto;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
      "
      *ngIf="tree && !isLoading"
    >
      <angular-org-chart
        *ngIf="tree"
        [nodeTemplate]="nodeTemplate"
        [data]="tree"
        #exampleChart="orgChart"
      >
      </angular-org-chart>
      <ng-template #nodeTemplate let-node>
        <div class="chart-node">
          <div class="name">{{ node?.name }}</div>
          <div class="description">{{ node?.description }}</div>
        </div>
      </ng-template>
    </div>

    <div class="requestList" *ngIf="!isLoading" ngForm #form="ngForm">
      <div class="container-fluid">
        <div class="userListingFlex">
          <div class="userListing">
            <form id="form1" name="form1" #form1="ngForm">
              <div class="userListing__card">
                <div class="input-group mb-3" style="margin-top: 10px">
                  <div class="input-group-prepend">
                    <label
                      class="input-group-text"
                      for="selectedBusinessUnit"
                      >{{
                        "business-units-locations.businessUnit" | translate
                      }}</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    id="selectedBusinessUnit"
                    name="selectedBusinessUnit"
                    [(ngModel)]="selectedBusinessUnit"
                    (ngModelChange)="setBusinessUnit($event)"
                  >
                    <option
                      value=""
                      selected
                      style="color: gray"
                      disabled
                      hidden
                    >
                      {{ "business-units-locations.newBU" | translate }}
                    </option>
                    <option
                      [ngValue]="item.id"
                      *ngFor="let item of businessUnits"
                    >
                      {{ capitalizeFirstLetter(item.identifier) }}
                    </option>
                  </select>
                </div>

                <h5>
                  {{
                    selectedBusinessUnit
                      ? ("business-units-locations.updateBU" | translate)
                      : ("business-units-locations.createBU" | translate)
                  }}
                </h5>

                <div class="fields">
                  <label class="" for="businessName">{{
                    "business-units-locations.nameBU" | translate
                  }}</label>
                  <input
                    type="text"
                    id="businessName"
                    name="businessName"
                    class="requestValid"
                    #test1="ngModel"
                    required="true"
                    minlength="3"
                    maxlength="50"
                    matInput
                    (ngModelChange)="isBusinessNameValid($event)"
                    [(ngModel)]="selectedBusinessUnitForEdit.identifier"
                  />
                  <div
                    *ngIf="test1.touched && BusinessNameInvalid != ''"
                    class="col-lg-12 invalid-input"
                    [style.top.px]="errBusinessNameY"
                    [style.left.px]="errBusinessNameX"
                  >
                    {{
                      "business-units-locations.validator.businessUnit." +
                        BusinessNameInvalid | translate
                    }}
                  </div>
                </div>

                <div class="fields">
                  <label class="" for="businessDesc">{{
                    "business-units-locations.descriptionBU" | translate
                  }}</label>
                  <input
                    type="text"
                    id="businessDesc"
                    name="businessDesc"
                    class="requestValid"
                    #test2="ngModel"
                    required="true"
                    minlength="3"
                    maxlength="255"
                    (ngModelChange)="isBusinessDescValid($event)"
                    matInput
                    [(ngModel)]="selectedBusinessUnitForEdit.description"
                  />
                  <div
                    *ngIf="test2.touched && businessDescInvalid != ''"
                    class="col-lg-12 invalid-input"
                    [style.top.px]="errBusinessDescY"
                    [style.left.px]="errBusinessDescX"
                  >
                    {{
                      "business-units-locations.validator.businessUnit." +
                        businessDescInvalid | translate
                    }}
                  </div>
                </div>

                <div class="buttons">
                  <button class="addBtn" (click)="addUpdateBusinessUnit()">
                    {{
                      selectedBusinessUnit
                        ? ("business-units-locations.update" | translate)
                        : ("business-units-locations.create" | translate)
                    }}
                  </button>
                  <button
                    class="deleteBtn"
                    (click)="deleteBusinessUnit()"
                    [ngStyle]="{
                      'background-color':
                        selectedBusinessUnitForEdit.status === 'active' ||
                        selectedBusinessUnit == ''
                          ? 'rgb(255, 127, 127)'
                          : 'red'
                    }"
                  >
                    {{ "business-units-locations.delete" | translate }}
                  </button>
                  <button class="cancelBtn" (click)="cancelBusinessUnit()">
                    {{ "business-units-locations.cancel" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="userListing">
            <form id="form2" name="form2" #form2="ngForm">
              <div class="userListing__card">
                <div class="input-group mb-3" style="margin-top: 10px">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">{{
                      "business-units-locations.locations" | translate
                    }}</label>
                  </div>
                  <select
                    id="inputGroupSelect01"
                    name="inputGroupSelect01"
                    class="custom-select"
                    [(ngModel)]="selectedLocation"
                    (ngModelChange)="setLocation($event)"
                  >
                    <option
                      value=""
                      style="color: gray"
                      disabled
                      selected
                      hidden
                    >
                      {{ "business-units-locations.newLo" | translate }}
                    </option>
                    <option [ngValue]="item.id" *ngFor="let item of locations">
                      {{ capitalizeFirstLetter(item.identifier) }}
                    </option>
                  </select>
                </div>

                <h5>
                  {{
                    selectedLocation
                      ? ("business-units-locations.updateLo" | translate)
                      : ("business-units-locations.createLo" | translate)
                  }}
                </h5>

                <div class="fields">
                  <label for="inputGroupSelect02">{{
                    "business-units-locations.locationsBU" | translate
                  }}</label>
                  <!--
                  <select
                    id="inputGroupSelect02"
                    name="inputGroupSelect02"
                    class="custom-select"
                    [(ngModel)]="selectedLocationForEdit.businessunit_id"
                    (ngModelChange)="setBusinessUnitLocation($event)"
                  >
                  -->
                  <select
                    id="inputGroupSelect02"
                    name="inputGroupSelect02"
                    class="custom-select"
                    [(ngModel)]="selectedBusinessUnit"
                    (ngModelChange)="setBusinessUnitLocation($event)"
                  >
                    <option
                      value=""
                      selected
                      style="color: gray"
                      disabled
                      hidden
                    ></option>
                    <option
                      [ngValue]="item.id"
                      *ngFor="let item of businessUnits"
                    >
                      {{ capitalizeFirstLetter(item.identifier) }}
                    </option>
                  </select>
                </div>

                <div class="fields">
                  <label class="" for="locationName">{{
                    "business-units-locations.nameLo" | translate
                  }}</label>
                  <input
                    type="text"
                    id="locationName"
                    name="locationName"
                    class="requestValid"
                    #test3="ngModel"
                    required="true"
                    minlength="3"
                    maxlength="50"
                    matInput
                    (ngModelChange)="isLocationNameValid($event)"
                    [(ngModel)]="selectedLocationForEdit.identifier"
                  />
                  <div
                    *ngIf="test3.touched && locationNameInvalid != ''"
                    class="col-lg-12 invalid-input"
                    [style.top.px]="errLocationNameY"
                    [style.left.px]="errLocationNameX"
                  >
                    {{
                      "business-units-locations.validator.businessLocation." +
                        locationNameInvalid | translate
                    }}
                  </div>
                </div>
                <div class="fields">
                  <label class="" for="locationName">{{
                    "business-units-locations.descriptionLo" | translate
                  }}</label>
                  <input
                    type="text"
                    id="locationDesc"
                    name="locationDesc"
                    class="requestValid"
                    #test4="ngModel"
                    required="true"
                    minlength="3"
                    maxlength="255"
                    matInput
                    (ngModelChange)="isLocationDescValid($event)"
                    [(ngModel)]="selectedLocationForEdit.description"
                  />
                  <div
                    *ngIf="test4.touched && locationDescInvalid != ''"
                    class="col-lg-12 invalid-input"
                    [style.top.px]="errLocationDescY"
                    [style.left.px]="errLocationDescX"
                  >
                    {{
                      "business-units-locations.validator.businessLocation." +
                        locationDescInvalid | translate
                    }}
                  </div>
                </div>

                <div class="buttons">
                  <button class="addBtn" (click)="addUpdateLocation()">
                    {{
                      selectedLocation
                        ? ("business-units-locations.update" | translate)
                        : ("business-units-locations.create" | translate)
                    }}
                  </button>
                  <button
                    class="deleteBtn"
                    (click)="deleteLocation()"
                    [ngStyle]="{
                      'background-color':
                        selectedLocationForEdit.status === 'active' ||
                        selectedLocation == ''
                          ? 'rgb(255, 127, 127)'
                          : 'red'
                    }"
                  >
                    {{ "business-units-locations.delete" | translate }}
                  </button>
                  <button class="cancelBtn" (click)="cancelLocation()">
                    {{ "business-units-locations.cancel" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
