import { Component, ElementRef, ViewChild } from '@angular/core';
import { PhoneState } from 'src/app/helpdesk/phone-state';
import { HelpdeskService } from 'src/app/helpdesk/helpdesk.service';
import { UserService } from 'src/app/user/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-specific-conditions',
  templateUrl: './specific-conditions.component.html',
  styleUrls: ['./specific-conditions.component.scss']
})
export class SpecificConditionsComponent {
  @ViewChild('form') form: FormGroup;
  buttonClass: string = '';
  myEnergyService: string = "Specific Conditions";
  description: string = "";
  officialName: string = "";
  shortName: string = "";
  userFirstName: string = "";
  userLastName: string = "";
  phoneState: string = "";
  phoneNumber: string = "";
  email: string = "";

  phoneStateList: PhoneState[] = [
    { prefix: '+421', name: 'Slovakia' },
    { prefix: '+420', name: 'Czech Republic' },
    { prefix: '+48', name: 'Poland' },
    { prefix: '+36', name: 'Hungary' },
    { prefix: '+49', name: 'Germany' },
    { prefix: '+43', name: 'Austria' },
  ];

  userToken: any;
  registered: any;

  errDescX: number = 0;
  errDescY: number = 0;
  descInvalid: string = "";
  counter : number = 0;
  descMaxLength: number = 1500;

  constructor(
    private helpdeskService: HelpdeskService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {
      this.userToken  = userService.userToken;
      this.registered = userService.getRegistered();
  }

  ngOnInit() {
    this.registered   = this.userService.getRegistered();

    if (this.registered) {
      this.phoneNumber = this.registered?.phone;
      this.email = this.registered?.email;
      this.officialName = this.registered?.officialName;
      this.shortName = this.registered?.shortName;
      this.userFirstName = this.registered?.userFirstName;
      this.userLastName = this.registered?.userLastName;
        }
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";

    console.log(`${selectedLang}/${url}`);
    this.router.navigate([`${selectedLang}/${url}`]);
    console.log(`${selectedLang}/${url}`);
  }

  btnSubmitClick(event: Event): void {
    this.buttonClass = 'btn-success';
  }

  isDescValid(event: any){
    this.errDescX = 0;
    this.errDescY = 0;
    this.descInvalid = "";
    this.counter = event.length;

    var element = this.elementRef.nativeElement.querySelector("#description1");

    if ( element?.offsetParent ){
      this.errDescX = element?.offsetParent.getBoundingClientRect().x;
      this.errDescY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errDescX = Math.abs(this.errDescX - element.getBoundingClientRect().x);
    this.errDescY = Math.abs(this.errDescY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 4 ){
      this.descInvalid = "description"
    }

    return this.descInvalid;    
  }

  calcX(start: number, element: ElementRef| any): number{
    var x: number = start;
    x = x + element?.getBoundingClientRect().x;
    
    //console.log(element, element?.getBoundingClientRect().x);

    if ( element?.offsetParent ){
      x = this.calcX(x, element.offsetParent);
    }

    return x;
  }

  onSubmit() {
    if (
      !this.myEnergyService || this.myEnergyService === 'My Energy Service' ||
      !this.description ||
      !this.phoneNumber
    ) {
      // Если не все поля заполнены, выводим сообщение об ошибке

      this.translate.get('helpDesk.request.validator.toastrError').subscribe((data: string) => {
        this.toastr.error(data);
      });      
    
      // this.toastr.error('Please fill in all fields correctly!', '');
      return; // Прекращаем выполнение функции
    }

    if (this.registered) {
      let payload = {
        id: null,
        registration_id: this.registered.id,
        message: this.description,
        status: 'pending',
        created_at: null,
        updated_at: null,
      };

      this.helpdeskService
        .specificConditionRequest(payload)
        .subscribe((data: any) => {
          if (data) {
            this.translate.get('helpDesk.request.validator.toastrSuccess').subscribe((data1: string) => {
              this.toastr.success(data1);
            });      
      
            localStorage.removeItem('registered');
            this.registered = this.userService.getRegistered();
            this.routeChecker('/');
          }
        });
    }
  }
}
