import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovalPageService {
  baseUrl: string = environment.baseUrlAPI;
  masterUserRegisterUrl: string = this.baseUrl + '/registrations';
  clientsRegisterUrl: string = this.baseUrl + '/clients';
  usersRegisterUrl:string = this.baseUrl + '/users'
  loggedMasterUser:any

  constructor(private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
   }

  masterUserRegistration(): Observable<any> {
    return this.hc.get(this.masterUserRegisterUrl);
  }
  clientsRegisters(): Observable<any> {
    return this.hc.get(this.clientsRegisterUrl);
  }
  changeRegistrationStatus(id: number, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.put<any>(`${this.masterUserRegisterUrl}/${id}`, data);
  }

  addUser(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(this.usersRegisterUrl, payload, { headers });
  }
  addClients(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(this.clientsRegisterUrl, payload, { headers });
  }
}
