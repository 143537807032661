import { Component, OnInit } from '@angular/core';
import { ApprovalPageService } from './approval-page.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from '../translation.service';

@Component({
  selector: 'app-approval-page',
  templateUrl: './approval-page.component.html',
  styleUrls: ['./approval-page.component.scss'],
})
export class ApprovalPageComponent implements OnInit {
  approvedRegistrations: any;
  pendingRegistrations:any
  declinedRegistrations:any
  allClients:any
  selectedData:any
  constructor(private approvalService: ApprovalPageService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private translationService: TranslationService
  ) {
    //this.setLang();
  }

  setLang(){
    let selectedLang =  this.translationService.urlLang || sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";
    this.translate.use(selectedLang);
  }

  ngOnInit() {
    this.getAllPendingRegistration();
  }

  getAllPendingRegistration() {
    this.approvalService.masterUserRegistration().subscribe({
      next: (data) => {
        if (data) {
          this.pendingRegistrations=data.filter((item: any) => item.status === 'pending');
          this.declinedRegistrations=data.filter((item: any) => item.status === 'inactive');
          this.approvedRegistrations=data.filter((item: any) => item.status === 'active');

          this.approvalService.clientsRegisters().subscribe({
            next: (resdata) => {
              this.allClients=resdata
            },
            error: (error:any) =>{
              this.toastr.success('Error', 'Unable to retrieve client data!');      
            }
          });
        }
      },
      error: (error:any) => {}
    });
  }

  declineRegistration(id: number, data: any) {
    data.status="inactive"
    this.approvalService.changeRegistrationStatus(id, data).subscribe({
      next: (data) => {
        this.toastr.success('Success', 'Status changed!');
        this.getAllPendingRegistration();
      },
      error: (error:any) => {
        this.toastr.success('Error', 'Unable to set new status!');
      }
    });

  }

  revertRegistration(id: number, data: any) {
    data.status="pending"
    this.approvalService.changeRegistrationStatus(id, data).subscribe({
      next: (data) => {
        this.toastr.success('Success', 'Status changed!');
        this.getAllPendingRegistration();
      },
      error: ( error:any) => {
        this.toastr.success('Error', 'Unable to set new status!');
      }
    });

  }

  approveRegistration(id: number, data: any) {
    data.status="active"
    this.approvalService.changeRegistrationStatus(id, data).subscribe({
      next: (data) => {
        this.toastr.success('Success', 'Status changed!');
        this.getAllPendingRegistration();
      },
      error: ( error:any) =>{
        this.toastr.success('Error', 'Unable to set new status!');
      }
    });

    let clientPayload = {
      id: null,
      name: data.officialName,
      address: data.officialAddress,
      region: data.country,
      shortName:data.shortName,
      webpage: data.website,
      phone: data.phone,
      status: 'active',
      created_at: null,
      updated_at: null,
    };

    let userPayload = {
      id: null,
      name: data.officialName,
      client_id: 4,
      login: data.email,
      email: data.email,
      password: data.password,
      fullName:
        data.userFirstName + ' ' + data.userLastName,
      token: '111222',
      role: 'master user',
      created_at: null,
      updated_at: null,
    };

    this.approvalService.addClients(clientPayload).subscribe({
      next: (data) => {
        if (data) {
          userPayload.client_id = data.id;
          this.approvalService.addUser(userPayload).subscribe({
            next: (resdata) => {
             if (resdata) {
                this.toastr.success('Success', 'Master Client Registartion is Approved!');
              }
            },
            error: ( error:any) => {
              this.toastr.success('Error', 'Unable to approve Master Cliet!');
            }
          });          
        }
      },
      error: ( error: any) => {
        this.toastr.success('Error', 'Unable to add client!');
      }
    });
  }

  viewModal(data:any){
    this.selectedData=data
  }

  getInitials(name: string) {
    let initials = name.split(' ').map((n)=>n[0]).join('');
    return initials.toUpperCase();
  }
}
