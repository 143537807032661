import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Backcast1weekComponent } from './backcast1week/backcast1week.component';
import { Backcast1dayComponent } from './backcast1day/backcast1day.component';
import { RouterModule } from '@angular/router';
import { NgxUplotModule } from 'ngx-uplot';
import { UpdateComponent } from './update/update.component';
import { AppStatusComponent } from '../shared/app-status.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    Backcast1weekComponent,
    Backcast1dayComponent,
    UpdateComponent,
  ],
  imports: [
    CommonModule, RouterModule, NgxUplotModule
  ],
  exports: [
    UpdateComponent
  ]
})
export class BackcastModule { }
