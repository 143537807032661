import { BusinessUnitLocationsService } from './business-unit-locations.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-business-unit-locations',
  templateUrl: './business-unit-locations.component.html',
  styleUrls: ['./business-unit-locations.component.scss']
})
export class BusinessUnitLocationsComponent {
  @ViewChild('form1') form1: FormGroup;  
  @ViewChild('form2') form2: FormGroup;

  identifiers: any;
  selectedIdentifier: any;
  loggedMasterUser: any;
  clientData: any;

  businessUnits: any = [];
  locations: any;

  selectedBusinessUnit: any = '';
  selectedLocation: any = '';

  selectedBusinessUnitForEdit: any = {};
  selectedLocationForEdit: any = {};

  tree: any;
  isLoading: boolean;

  constructor(private adminService: AdminService, private businessUnitLocationsService: BusinessUnitLocationsService, private toastr: ToastrService, private translate: TranslateService, private elementRef: ElementRef,) {
    this.isLoading = true
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data) => {
        this.clientData = data;
        this.tree = {}
        this.tree.name = this.clientData?.name + " ( " + this.clientData?.shortName + " ) "
        this.tree.css = "background-color: #00bf63 !important;color: #fff !important;box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer"
        this.tree.onClick = () => this.resetOrgCHart()
      });
    }
  }

  ngOnInit() {
    this.getBusinessUnits()
    this.getLocations()
  }

  ngAfterViewInit() {
    this.getBusinessUnits()
    this.getLocations()
  }

  getBusinessUnits() {
    this.businessUnitLocationsService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data
    })

  }
  getLocations() {
    this.businessUnitLocationsService.getLocations().subscribe((data) => {
      this.locations = data
      setTimeout(() => {
        this.getOrgChart()
      }, 500)
    })
  }

  getOrgChart() {
    let businessUnit: any = [];
    let textLocation: any ;
    let textBusinessUnit: any;

    this.translate.get('business-units-locations.location').subscribe((data1: string) => {
      this.translate.get('business-units-locations.businessUnit').subscribe((data2: string) => {
        textBusinessUnit = data2;
        textLocation = data1;

        for (let item of this.businessUnits) {
          let locations = []
          for (let location of this.locations) {
            if (item.id === location.businessunit_id) {
              locations.push({
                id:location.id,
                type:'location',
                businessunitId:location.businessunit_id,
                name: this.capitalizeFirstLetter(location.identifier) + " (" + textLocation + ")",
                description: location.description,
                children: [],
                css: "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer",
                onClick : () => this.setLocation(location.id)
              })
            }
          }
          businessUnit.push({
            id:item.id,
            type:'businessUnit',
            name: this.capitalizeFirstLetter(item.identifier) + " (" + textBusinessUnit + ")",
            description: item.description,
            children: locations,
            css: "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer",
            onClick : () => this.setBusinessUnit(item.id)
          })
        }
        this.isLoading = false
        this.tree.children = businessUnit
      });     
    });     
  }

  resetOrgCHart() {
    this.tree.css = "background-color: #00bf63 !important;color: #fff !important;box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer";
    this.getOrgChart();
  }

  setLocation(event: any) {
    this.selectedBusinessUnit = '';
    this.selectedBusinessUnitForEdit = {};
    this.selectedLocation = '';
    this.selectedLocationForEdit = {};

    if (event){
      this.resetOrgCHart()
      this.selectedLocationForEdit = this.locations.find((item: any) => item.id === event)
      this.selectedLocation        = this.selectedLocationForEdit.id; 
      this.selectedBusinessUnit    = this.selectedLocationForEdit.businessunit_id;

      if ( this.selectedBusinessUnit ) this.setBusinessUnitOnly(this.selectedBusinessUnit);

      this.tree.css = "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;"

      for(let businessUnit of this.tree.children){
        if( businessUnit.id === this.selectedBusinessUnit ){
          for(let location of businessUnit.children){
            if(location.id===event){
              location.css = "background-color: #00bf63 !important;color: #fff !important;box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer"
            }
          }
        }
      }
    }
    else {
      this.resetOrgCHart()
      this.selectedLocation = ''
      this.selectedLocationForEdit = {}
    }
  }

  setBusinessUnitLocation(event: any){
    this.setBusinessUnit(event);
  }

  setBusinessUnitOnly(event: any) {
    this.selectedBusinessUnit = '';
    this.selectedBusinessUnitForEdit = {};

    if (event){
      this.selectedBusinessUnitForEdit = this.businessUnits.find((item: any) => item.id === event);
      this.selectedBusinessUnit        = this.selectedBusinessUnitForEdit.id;
    }
  }

  setBusinessUnit(event: any) {
    this.selectedBusinessUnit = '';
    this.selectedBusinessUnitForEdit = {};
    this.selectedLocation = '';
    //this.selectedLocationForEdit = {};

    if (event){
      this.resetOrgCHart()
      this.selectedBusinessUnitForEdit = this.businessUnits.find((item: any) => item.id === event);
      this.selectedBusinessUnit        = this.selectedBusinessUnitForEdit.id;

      //if ( this.selectedBusinessUnit ) this.selectedLocationForEdit.businessunit_id = this.selectedBusinessUnit;

      this.tree.css = "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;";

      for(let businessUnit of this.tree.children){
        if(businessUnit.id === event){
          businessUnit.css = "background-color: #00bf63 !important;color: #fff !important;box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;cursor:pointer"
        }
      }
    }
    else {
      this.selectedBusinessUnit = '';
      this.selectedBusinessUnitForEdit = {};
      this.resetOrgCHart();
    }
  }

  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  cancelBusinessUnit(){
    this.selectedBusinessUnit = '';
    this.selectedBusinessUnitForEdit = {};
    this.businessDescInvalid = '';
    this.BusinessNameInvalid = '';
  }

  cancelLocation(){
    this.selectedLocation = '';
    this.selectedLocationForEdit = {};
    console.log("5. setLocation nuluje sa: ",this.selectedLocationForEdit );

    this.locationDescInvalid = '';
    this.locationNameInvalid = ''
  }

  deleteBusinessUnit() {
    if ( ! this.selectedBusinessUnitForEdit.identifier ){
      this.translate.get('business-units-locations.validator.toastr.error1').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      

      return;
    }

    if ( this.selectedBusinessUnitForEdit.status === 'active' ){
      this.translate.get('business-units-locations.validator.toastr.error2').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    } 

    if (this.selectedBusinessUnitForEdit.status === 'active') return
    this.businessUnitLocationsService.deleteBusinessUnits(this.selectedBusinessUnitForEdit).subscribe({
      next: (data) => {
        if (data) {
          this.ngAfterViewInit();

          this.translate.get('business-units-locations.validator.toastr.success1').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
              this.toastr.success(data1,data2);
            });      
          });      

          this.selectedBusinessUnitForEdit = {};
          this.selectedBusinessUnit = '';
          this.selectedLocationForEdit = {};
        }
      },
      error: (err) => {
        this.translate.get('business-units-locations.validator.toastr.error6').subscribe((data1: string) => {
          this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
            this.toastr.error(data1,data2);
          });      
        });      
      }
    })
  }

  deleteLocation() {
    if ( ! this.selectedLocationForEdit.identifier ){

      this.translate.get('business-units-locations.validator.toastr.error4').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    }

    if (this.selectedLocationForEdit.status === 'active' ){
      this.translate.get('business-units-locations.validator.toastr.error5').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    } 

    this.businessUnitLocationsService.deleteLocation(this.selectedLocationForEdit).subscribe({
      next: (data) => {
        if (data) {
          this.selectedBusinessUnitForEdit = {};
          this.selectedBusinessUnit = '';
          this.selectedLocation = '';
          this.selectedLocationForEdit = {};
      
          this.ngAfterViewInit();

          this.translate.get('business-units-locations.validator.toastr.success2').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
              this.toastr.success(data1,data2);
            });      
          });      
        }
      },
      error: (err) => {
        this.translate.get('business-units-locations.validator.toastr.error6').subscribe((data1: string) => {
          this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
            this.toastr.error(data1,data2);
          });      
        });      
      }
    })
  }

  addUpdateLocation() {
    if ( ! this.selectedLocationForEdit.identifier ){
      this.translate.get('business-units-locations.validator.toastr.error4').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    }

    if ( ! this.selectedLocationForEdit.description ){
      this.translate.get('business-units-locations.validator.toastr.error7').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    }

    if ( ! this.form2.valid ) return;

    if (this.selectedLocation) {
      this.selectedLocationForEdit.businessunit_id = this.selectedBusinessUnit;
      this.businessUnitLocationsService.updateLocation(this.selectedLocationForEdit).subscribe({
        next: (data) => {
          if (data) {
            this.ngAfterViewInit();

            this.translate.get('business-units-locations.validator.toastr.success3').subscribe((data1: string) => {
              this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
                this.toastr.success(data1,data2);
              });      
            });      

            this.selectedLocationForEdit = {}
            this.selectedLocation = ''
          }
        },
        error: (err) => {
          this.translate.get('business-units-locations.validator.toastr.error8').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
              this.toastr.error(data1,data2);
            });      
          });      
        }
      })
    } else {
      this.selectedLocationForEdit.businessunit_id = this.selectedBusinessUnit;
      this.businessUnitLocationsService.addLocation(Object.assign(this.selectedLocationForEdit, { 'client_id': this.loggedMasterUser.client_id })).subscribe({
        next: (data) => {
          if (data) {
            this.ngAfterViewInit();

            this.translate.get('business-units-locations.validator.toastr.success4').subscribe((data1: string) => {
              this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
                this.toastr.success(data1,data2);
              });      
            });      

            this.selectedLocationForEdit = {}
            this.selectedLocation = ''
          }
        },
        error: (err) => {
          this.translate.get('business-units-locations.validator.toastr.error9').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
              this.toastr.error(data1,data2);
            });      
          });      
        }
      })
    }
  }

  addUpdateBusinessUnit() {
    if ( ! this.selectedBusinessUnitForEdit.identifier ){
      this.translate.get('business-units-locations.validator.toastr.error1').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    }

    if ( ! this.selectedBusinessUnitForEdit.description ){
      this.translate.get('business-units-locations.validator.toastr.error13').subscribe((data1: string) => {
        this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
          this.toastr.error(data1,data2);
        });      
      });      
      return;
    }

    if ( ! this.form1.valid ) return;

    this.cancelLocation();
      
    if (this.selectedBusinessUnit) {
      this.businessUnitLocationsService.updateBusinessUnits(this.selectedBusinessUnitForEdit).subscribe({
        next: (data) => {
          if (data) {
            this.selectedBusinessUnitForEdit = {};
            this.selectedBusinessUnit = '';
            this.selectedLocation = '';
            this.selectedLocationForEdit = {};
        
            this.ngAfterViewInit();

            this.translate.get('business-units-locations.validator.toastr.success5').subscribe((data1: string) => {
              this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
                this.toastr.success(data1,data2);
              });      
            });      
          }
        },
        error: (err) => {
          this.translate.get('business-units-locations.validator.toastr.error11').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
              this.toastr.error(data1,data2);
            });      
          });      
        }
      })
    } else {
      this.businessUnitLocationsService.addBusinessUnits(Object.assign(this.selectedBusinessUnitForEdit, { 'client_id': this.loggedMasterUser.client_id })).subscribe({
        next: (data) => {
          if (data) {
            this.ngAfterViewInit();

            this.translate.get('business-units-locations.validator.toastr.success6').subscribe((data1: string) => {
              this.translate.get('business-units-locations.validator.success').subscribe((data2: string) => {
                this.toastr.success(data1,data2);
              });      
            });      

            this.selectedBusinessUnitForEdit = {};
            this.selectedBusinessUnit = '';
            this.selectedLocation = '';
            this.selectedLocationForEdit = {};
          }
        },
        error: (err) => {
          this.translate.get('business-units-locations.validator.toastr.error12').subscribe((data1: string) => {
            this.translate.get('business-units-locations.validator.error').subscribe((data2: string) => {
              this.toastr.error(data1,data2);
            });      
          });      
        }
      })
    }
  }

  errBusinessNameX: number = 0;
  errBusinessNameY: number = 0;
  BusinessNameInvalid: string = "";
  isBusinessNameValid(event: any){    
    this.errBusinessNameX = 0;
    this.errBusinessNameY = 0;
    this.BusinessNameInvalid = "";
    this.form1.controls['businessName'].setErrors(null);
  
    var element = this.elementRef.nativeElement.querySelector("#businessName");
    
    if ( element?.offsetParent ){
      this.errBusinessNameX = element?.offsetParent.getBoundingClientRect().x;
      this.errBusinessNameY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errBusinessNameX = Math.abs(this.errBusinessNameX - element.getBoundingClientRect().x);
    this.errBusinessNameY = Math.abs(this.errBusinessNameY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 3 ){
      this.BusinessNameInvalid = "identifier";
      this.form1.controls['businessName'].setErrors({'incorrect': true});
    }

    return this.BusinessNameInvalid;
  }

  errBusinessDescX: number = 0;
  errBusinessDescY: number = 0;
  businessDescInvalid: string = "";
  isBusinessDescValid(event: any){
    this.errBusinessDescX = 0;
    this.errBusinessDescY = 0;
    this.businessDescInvalid = "";
    this.form1.controls['businessDesc'].setErrors(null);
  
    var element = this.elementRef.nativeElement.querySelector("#businessDesc");

    if ( element?.offsetParent ){
      this.errBusinessDescX = element?.offsetParent.getBoundingClientRect().x;
      this.errBusinessDescY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errBusinessDescX = Math.abs(this.errBusinessDescX - element.getBoundingClientRect().x);
    this.errBusinessDescY = Math.abs(this.errBusinessDescY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 3 ){
      this.businessDescInvalid = "description";
      this.form1.controls['businessDesc'].setErrors({'incorrect': true});
    }

    return this.businessDescInvalid;

  }

  errLocationNameX: number = 0;
  errLocationNameY: number = 0;
  locationNameInvalid: string = "";
  isLocationNameValid(event: any){    
    this.errLocationNameX = 0;
    this.errLocationNameY = 0;
    this.locationNameInvalid = "";
    this.form2.controls['locationName'].setErrors(null);
  
    var element = this.elementRef.nativeElement.querySelector("#locationName");
    
    if ( element?.offsetParent ){
      this.errLocationNameX = element?.offsetParent.getBoundingClientRect().x;
      this.errLocationNameY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errLocationNameX = Math.abs(this.errLocationNameX - element.getBoundingClientRect().x);
    this.errLocationNameY = Math.abs(this.errLocationNameY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 3 ){
      this.locationNameInvalid = "identifier";
      this.form2.controls['locationName'].setErrors({'incorrect': true});
    }

    return this.locationNameInvalid;
  }

  errLocationDescX: number = 0;
  errLocationDescY: number = 0;
  locationDescInvalid: string = "";
  isLocationDescValid(event: any){
    this.errLocationDescX = 0;
    this.errLocationDescY = 0;
    this.locationDescInvalid = "";
    this.form2.controls['locationDesc'].setErrors(null);
  
    var element = this.elementRef.nativeElement.querySelector("#locationDesc");

    if ( element?.offsetParent ){
      this.errLocationDescX = element?.offsetParent.getBoundingClientRect().x;
      this.errLocationDescY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errLocationDescX = Math.abs(this.errLocationDescX - element.getBoundingClientRect().x);
    this.errLocationDescY = Math.abs(this.errLocationDescY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 3 ){
      this.locationDescInvalid = "description";
      this.form2.controls['locationDesc'].setErrors({'incorrect': true});
    }

    return this.locationDescInvalid;
  }

  log(val: any){
    console.log(val);
  }
}
