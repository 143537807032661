import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { EChartsOption } from 'echarts';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HappenedYesterdayService } from './../happened-yesterday.service';
import { BusinessUnitLocationsService } from 'src/app/business-unit-locations/business-unit-locations.service';
import { BusinessUnitLocation } from '../../business-unit-locations/business-unit-location';
import { AdminService } from 'src/app/admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-happened-month',
  templateUrl: './happened-month.component.html',
  styleUrls: ['./happened-month.component.scss']
})
export class HappenedMonthComponent {
  dateFrom: string;
  dateUntil: string;
  //dateUntillData: string
  countDays: number;

  loggedMasterUser: any;
  clientData: any;
  isLoading = true;
  chartOption: EChartsOption;

  hourlyConsumption: any[] = [];       // = new Array(24).fill(0); pocet prvkov je pocet dni + 24 hodin
  hourlyPrices: number[];              // = new Array(24).fill(0); pocet prvkov je pocet dni + 24 hodin
  hourlyCalculatedPrice: any[] = [];   // = new Array(24).fill(0); pocet prvkov je pocet dni + 24 hodin

  pricesActualData: any;
  consumptionData: any;
  tableIndex: number = 0;
  xAxisLabels: any[] = [];
  totalWeekConsumption: any = []

  identifiers: any
  devices: any
  businessUnits: any;
  businessUnitsLocations: any;

  totalConsumption: any
  totalPrice: any
  totalCalculatedPrice: any

  totalLocationConsumption: any
  totalLocationPrice: any
  totalLocationCalculatedPrice: any

  highestPrice: any = { hour: 0, price: 0 };

  selectedIdentifier: any = 'All'
  selectedDevice: any = ''
  selectedBusinessUnit: any = '';

  exportType: any = 'xlsx';

  displayedColumns: string[] = [
    'time',
    'consumption',
    'actualPrice',
    'calculatedPrice',
  ]

  dataSource: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dialogTemplate2') dialogTemplate2: TemplateRef<any>;

  selectedTabIndex: number
  maxDate: any

  constructor(
    private happenedService: HappenedYesterdayService,
    private businessLocationsService: BusinessUnitLocationsService,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe(
      { next: (data: any) => {
          this.clientData = data;
        },
        error: (error:any) => {
          this.translate.get('happenedMonth.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedMonth.validator.toastr.error1').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        }
      });
    }

    this.setInitialDates();
    this.setInitialValues();
    this.getFullData();      
  }

  getBusinessUnits(){
    this.businessLocationsService.getBusinessUnits().subscribe(
      { next: (data: any) => {
              this.businessUnits = data;              
    
              if ( ! this.selectedBusinessUnit ){
                this.selectedBusinessUnit = this.businessUnits[0];
              }

              //console.log("businessUnits :", this.businessUnits );          
        },
        error: (error: any) => {
              this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                this.translate.get('happenedYesterday.validator.toastr.error5').subscribe({ 
                  next: (data2: string) => {
                    this.toastr.error(data2,data1);
                  }
                });      
              });        
        }
      });  
  }

  getLocations(businessUnitId:any){
    let payload = { };

    if ( businessUnitId ){
      payload = { businessUnit: businessUnitId};
    }

    this.businessLocationsService.getBusinessUnitLocations(payload).subscribe(
      { next: (data: any) => {
              this.businessUnitsLocations = [];
              this.selectedIdentifier = {businessunit_id: data[0].businessunit_id,
                client_id: data[0].client_id,
                identifier: "All",
                id: 999999,
                table: 0
              };
              this.businessUnitsLocations.push(this.selectedIdentifier);
              this.tableIndex = 0;

              data.forEach( (element:any) => {
                this.businessUnitsLocations.push(element);
                let tableIndex = this.businessUnitsLocations.length -1;
                this.businessUnitsLocations[tableIndex].table = tableIndex;
              });

              //console.log("businesUnitLocations :", this.businessUnitsLocations );
        },
        error: (error: any) => {
              this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                this.translate.get('happenedYesterday.validator.toastr.error6').subscribe({ 
                  next: (data2: string) => {
                    this.toastr.error(data2,data1);
                  }
                });      
              });        
        }
      });        

  }

  setInitialDates() {
    const date = new Date();
    let toDay = formatDate(date, 'yyyy-MM-dd', 'en');
    let curentYear  = date.getFullYear();
    let curentMonth = date.getMonth();

    //console.log(curentYear, curentMonth);

    if ( curentMonth === 0){
       curentMonth = 11;
       curentYear = curentYear-1;
    }

    //console.log(curentYear, curentMonth);

    let firstDayPreviousMonth = new Date(curentYear, curentMonth, 1);
    let lastDayPreviousMonth  = new Date(curentYear, curentMonth + 1 , 0);

    this.dateFrom = formatDate(firstDayPreviousMonth, 'yyyy-MM-dd', 'en');
    this.dateUntil = formatDate(lastDayPreviousMonth, 'yyyy-MM-dd', 'en');
    //this.dateUntillData = formatDate(firstDayCurrentMonth, 'yyyy-MM-dd', 'en');
    this.maxDate = this.dateUntil; // Set maxDate as the last day of the previous month
    this.maxDate = toDay;          // Set maxDate as the last day of the previous month

    this.countDays = this.calculateDaysCount(this.dateFrom, this.dateUntil);
    //console.log("dateFrom :", this.dateFrom);
    //console.log("dateUntil :", this.dateUntil);
    //console.log("countDays :", this.countDays);
  }

  setInitialValues(){
    // Parse the dateFrom string to create a Date object
    let startDate = new Date(this.dateFrom);
    let endDate = new Date(this.dateUntil);
    endDate.setDate(endDate.getDate()+1);

    // Ensure the start date is set to exactly midnight (00:00:00)
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    //console.log(startDate, endDate);
    // Generate x-axis labels for count days * 24 hours
    this.xAxisLabels = [];
    let hours = (endDate.getTime() - startDate.getTime())/1000/60/60;

    for (let i = 0; i < hours; i++) {
      const currentDate = new Date(startDate.getTime() + i * 3600000); // Add hours
      const formattedDate = this.formatDatePlot(currentDate);
      this.xAxisLabels.push(formattedDate);
    }

    this.hourlyConsumption        = [];
    this.hourlyConsumption[0]     = new Array(this.xAxisLabels.length).fill(0);

    this.hourlyPrices             = new Array(this.xAxisLabels.length).fill(0);
    this.hourlyCalculatedPrice    = [];
    this.hourlyCalculatedPrice[0] = new Array(this.xAxisLabels.length).fill(0);
  
    this.pricesActualData = [];
    this.consumptionData  = [];
    this.tableIndex       = 0;
  
    this.totalConsumption     = 0;
    this.totalPrice           = 0;
    this.totalCalculatedPrice = 0;
  
    this.totalLocationConsumption     = 0;
    this.totalLocationPrice           = 0;
    this.totalLocationCalculatedPrice = 0;

    this.dataSource = [];
  }
  
  ngOnInit(): void {
    this.isLoading = true;
  }

  calculateDaysCount(dateFrom:any, dateUntil:any){
    let days = 0;

    let day1 = new Date(dateFrom);
    let day2 = new Date(dateUntil);

    //let date = new Date(data.sent);
    //let currentDate = new Date();

    days = Math.floor((day2.getTime() - day1.getTime()) / 1000 / 60 / 60 / 24) +1;
    return days;
  }  

  async getFullData(){
    let payload = {dateFrom: this.dateFrom, dateUntil: this.dateUntil};
    this.happenedService.getWeekActualPrices(payload).subscribe(   //  nacitanie aktualnych cien
      { next: async (data:any ) => {      
          this.pricesActualData = data
          data.forEach((price: any, index: number) => {
            let priceValue = parseFloat(price.price) / 100000;
            this.hourlyPrices[index] = parseFloat(priceValue.toFixed(2));
          });
          //console.log("hourlyPrices :", this.hourlyPrices);
  
          this.businessLocationsService.getBusinessUnits().subscribe(   // nacitanie businessUnits
            { next: (data: any) => {      
                    this.businessUnits = data;              
                    this.selectedBusinessUnit = this.businessUnits[0];
                    //console.log("businessUnits :", this.businessUnits );          

                    let payload = { businessUnit: this.businessUnits[0].id};

                    this.businessLocationsService.getBusinessUnitLocations(payload).subscribe(  //nacitanie businessUnitLocations
                      { next: (data: any) => {
                              this.businessUnitsLocations = [];
                              this.selectedIdentifier = {businessunit_id: data[0].businessunit_id,
                                client_id: data[0].client_id,
                                identifier: "All",
                                id: 999999,
                                table: 0
                              };
                              this.businessUnitsLocations.push(this.selectedIdentifier);
                              this.tableIndex = 0;
                
                              data.forEach( (element:any) => {
                                this.businessUnitsLocations.push(element);
                                let tableIndex = this.businessUnitsLocations.length -1;
                                this.businessUnitsLocations[tableIndex].table = tableIndex;
                              });
                
                              this.selectedIdentifier = this.businessUnitsLocations[0];
                              //console.log("businessUnitLocations :", this.businessUnitsLocations);

                              this.happenedService.getHappenedYesterday({ dateFrom: this.dateFrom, dateUntil: this.dateUntil, businessUnit: this.selectedBusinessUnit.id }).subscribe(
                                { next: async (data: any) => {
                    
                                    this.consumptionData = data;
                                    //console.log("consumptionData:",this.consumptionData);
                                    this.getOverallHappened(this.consumptionData, this.selectedIdentifier);
                                    this.updateChartOption();
                                    this.isLoading = false;
                                },
                                error: (error: any) => {
                                  this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                                    this.translate.get('happenedYesterday.validator.toastr.error2').subscribe({ 
                                      next: (data2: string) => {
                                        this.toastr.error(data2,data1);
                                      }
                                    });      
                                  });        
                                }
                            });
                      },
                      error: (error: any) => {
                          this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                            this.translate.get('happenedYesterday.validator.toastr.error6').subscribe({ 
                              next: (data2: string) => {
                                this.toastr.error(data2,data1);
                              }
                            });      
                          });        
                      }
                    });        
                
              },
              error: (error: any) => {
                    this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                      this.translate.get('happenedYesterday.validator.toastr.error5').subscribe({ 
                        next: (data2: string) => {
                          this.toastr.error(data2,data1);
                        }
                      });      
                    });        
              }
            });  
      
        },
        error: (error:any) => {
          this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedYesterday.validator.toastr.error3').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        } 
      });
  }

  async getData( selectedIdentifier: BusinessUnitLocation|any ){
    //console.log(this.dateFrom, this.dateUntil);
    let payload = { dateFrom: this.dateFrom, dateUntil: this.dateUntil };
      this.happenedService.getWeekActualPrices(payload).subscribe(
        { next: async (data:any ) => {      
          this.pricesActualData = data
          data.forEach((price: any, index: number) => {
            let priceValue = parseFloat(price.price) / 100000;
            this.hourlyPrices[index] = parseFloat(priceValue.toFixed(2));
          });
          //console.log("hourlyPrices :", this.hourlyPrices);
  
        let payload = { businessUnit: this.selectedBusinessUnit.id};
        this.businessLocationsService.getBusinessUnitLocations(payload).subscribe(  //nacitanie businessUnitLocations
        { next: (data: any) => {
              this.businessUnitsLocations = [];
              this.businessUnitsLocations.push(
                {businessunit_id: data[0].businessunit_id,
                  client_id: data[0].client_id,
                  identifier: "All",
                  id: 999999,
                  table: 0
                }
              );

              data.forEach( (element:any) => {
                this.businessUnitsLocations.push(element);
                let tableIndex = this.businessUnitsLocations.length -1;
                this.businessUnitsLocations[tableIndex].table = tableIndex;
              });

              this.selectedIdentifier = selectedIdentifier ? selectedIdentifier : this.businessUnitsLocations[0];
              //console.log("selectedIdentifier OUT :", selectedIdentifier);

              this.tableIndex = this.selectedIdentifier.table ? this.selectedIdentifier.table : 0;
              //console.log("selectedIdentifier :", this.selectedIdentifier);
              //console.log("tableIndex :", this.tableIndex);

              this.happenedService.getHappenedYesterday({ dateFrom: this.dateFrom, dateUntil: this.dateUntil, businessUnit: this.selectedBusinessUnit.id }).subscribe(
                { next: async (data: any) => {
    
                    this.consumptionData = data;
                    //console.log("consumptionData:",this.consumptionData);
                    this.getOverallHappened(this.consumptionData, this.selectedIdentifier);
                    this.updateChartOption();
                    this.isLoading = false;
                },
                error: (error: any) => {
                  this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                    this.translate.get('happenedYesterday.validator.toastr.error2').subscribe({ 
                      next: (data2: string) => {
                        this.toastr.error(data2,data1);
                      }
                    });      
                  });        
                }
            });
          }
        });
      },
      error: (error:any) => {
        this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
          this.translate.get('happenedYesterday.validator.toastr.error3').subscribe({ 
            next: (data2: string) => {
              this.toastr.error(data2,data1);
            }
          });      
        });      
      } 
    });
  }

  generateChart() {
    const date = new Date(this.dateFrom);
    let curentYear  = date.getFullYear();
    let curentMonth = date.getMonth();

    //console.log(curentYear, curentMonth);

    let firstDayMonth = new Date(curentYear, curentMonth, 1);
    let lastDayMonth  = new Date(curentYear, curentMonth + 1 , 0);

    this.dateFrom = formatDate(firstDayMonth, 'yyyy-MM-dd', 'en');
    this.dateUntil = formatDate(lastDayMonth, 'yyyy-MM-dd', 'en');

    this.countDays = this.calculateDaysCount(this.dateFrom, this.dateUntil);
      
      this.setInitialValues();
      //console.log(this.dateFrom, this.dateUntil, this.maxDate);
      this.getData(this.selectedIdentifier);
  }

  extractIdentifiers() {
    const identifiers: any = [];
    const devices: any = [];

    identifiers.push('All')
    this.consumptionData.forEach((location: any) => {
      if (location.identifier) {
        identifiers.push(location.identifier);
      }
      location.devices.forEach((device: any, index: number) => {
        if (device) {
          devices.push({ label: `Device (${devices.length + 1}) - ${device.description}`, value: device.id, location: device.location_id });
        }
      });
    });
    this.identifiers = identifiers;
    this.devices = devices
  }

  getOverallHappened(data: any, location: BusinessUnitLocation|any ) {
    this.dataSource[0] = new MatTableDataSource();
    this.hourlyConsumption[0]     = new Array(this.xAxisLabels.length).fill(0);
    this.hourlyCalculatedPrice[0] = new Array(this.xAxisLabels.length).fill(0);

    let dataSource: any = []
    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

    data.forEach((region: any) => {
      let id = region.id;
      let tabIndex: any = 0;

      let locationIndex = this.businessUnitsLocations.findIndex( (location: any) => { return location.id === id });
   
      if ( locationIndex >= 0 ){ 
        if ( this.businessUnitsLocations[locationIndex] && this.businessUnitsLocations[locationIndex].table ){
          tabIndex = this.businessUnitsLocations[locationIndex]?.table ? this.businessUnitsLocations[locationIndex]?.table : 0;
        };
      }

      if ( ! this.hourlyConsumption[tabIndex] ){
        this.hourlyConsumption[tabIndex]     = new Array(this.xAxisLabels.length).fill(0);
      }

      if ( ! this.hourlyCalculatedPrice[tabIndex] ){
        this.hourlyCalculatedPrice[tabIndex] = new Array(this.xAxisLabels.length).fill(0);
      }

      let hourlyConsumption = new Array(this.xAxisLabels.length).fill(0);
      dataSource = [];
      region.devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let year = datetime.getUTCFullYear();
          let month = datetime.getUTCMonth()+1;
          let day   = datetime.getUTCDate();
          let hour = datetime.getUTCHours();
          let consumption = parseFloat(reading.consumption);

          //console.log("" + day + "." + month + "." + year + " " + hour + ":00");
          let index = this.xAxisLabels.findIndex((date: any) => { return date === "" + (day < 10 ? "0" + day : day) + "." + (month < 10 ? "0" + month : month ) + "." + year + " " + ( hour < 10 ? "0" + hour : hour) + ":00" });   // datetime.getUTC

          if (! hourlyConsumption[index]) hourlyConsumption[index] = 0;
          if ( reading.unit.toUpperCase() === "KWH" ) hourlyConsumption[index] += consumption;
          if ( reading.unit.toUpperCase() === "KW" )  hourlyConsumption[index] += consumption;
          if ( reading.unit.toUpperCase() === "WH" )  hourlyConsumption[index] += (consumption / 1000);
          if ( reading.unit.toUpperCase() === "W" )   hourlyConsumption[index] += (consumption / 1000);
          if ( reading.unit.toUpperCase() === "MWH" ) hourlyConsumption[index] += (consumption * 1000);
          if ( reading.unit.toUpperCase() === "MW" )  hourlyConsumption[index] += (consumption * 1000);
        });
      });

      hourlyConsumption = hourlyConsumption.map(value => parseFloat((value).toFixed(2)));

      let consumption = 0;
      let dateDatasource = new Date(this.xAxisLabels[0].replace(pattern,'$3-$2-$1'));
      dateDatasource.setHours(0, 0, 0, 0);
      let testZapisu = 1;
      let lastObj: any;

      for ( let index = 0; index < this.xAxisLabels.length; index++ ){
        testZapisu = 1;
        this.hourlyConsumption[0][index]            += hourlyConsumption[index];
        this.hourlyConsumption[tabIndex][index]     += hourlyConsumption[index];
        this.hourlyCalculatedPrice[0][index]        += parseFloat((this.hourlyConsumption[0][index] * (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0)).toFixed(2));
        this.hourlyCalculatedPrice[tabIndex][index] += parseFloat((this.hourlyConsumption[tabIndex][index] * (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0)).toFixed(2));

        let day = new Date(this.xAxisLabels[index].replace(pattern,'$3-$2-$1'));
        day.setHours(0, 0, 0, 0);

        if ( this.datePipe.transform(day,'dd.MM.yyyy') === this.datePipe.transform(dateDatasource, 'dd.MM.yyyy') ){
          //console.log(consumption);
          consumption += hourlyConsumption[index];
          lastObj = {
            hour: this.datePipe.transform(dateDatasource, 'dd.MM.yyyy'),
            consumption: consumption.toFixed(2),
            actualPrice: (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0).toFixed(2),
            calculatedPrice: parseFloat((consumption * (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0)).toFixed(2))
          }
        }else {
          //console.log("this.xAxisLabels[index] :", this.xAxisLabels[index]);
          let obj = {
            hour: this.datePipe.transform(dateDatasource, 'dd.MM.yyyy'),
            consumption: consumption.toFixed(2),
            actualPrice: (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0).toFixed(2),
            calculatedPrice: parseFloat((consumption * (this.hourlyPrices[index] ? this.hourlyPrices[index]: 0)).toFixed(2))
          }
          dataSource.push(obj)
          //console.log(dataSource);

          consumption = hourlyConsumption[index];
          dateDatasource = new Date(this.xAxisLabels[index].replace(pattern,'$3-$2-$1'));
          dateDatasource.setHours(0, 0, 0, 0);
          testZapisu = 0;
        }
      }

      if ( testZapisu === 1 ){
          dataSource.push(lastObj)
          testZapisu = 0;
      }

      this.dataSource[tabIndex] = new MatTableDataSource(dataSource);
      this.dataSource[tabIndex].sort = this.sort;
      dataSource = [];
    });

    //console.log("tu sa pocita dataSorce[0]");
    //console.log("dataSource :", this.dataSource);
    dataSource = [];
    for ( let ind1 = 0; ind1 < this.dataSource[1]._data._value.length; ind1++){
      let hour = this.dataSource[1]._data._value[ind1].hour;
      let actualPrice = this.dataSource[1]._data._value[ind1].actualPrice;
      let consumption = 0;
      for ( let ind2 = 1; ind2 < this.dataSource.length; ind2++ ){
        consumption += parseFloat(this.dataSource[ind2]._data._value[ind1].consumption);
        //console.log(consumption);
      }

      let obj = {
          hour: hour,
          consumption: consumption.toFixed(2),
          actualPrice: this.dataSource[1]._data._value[ind1].actualPrice,
          calculatedPrice: parseFloat((consumption * this.dataSource[1]._data._value[ind1].actualPrice).toFixed(2))
      }
      
      //console.log(obj);
      dataSource.push(obj);
    }

    this.dataSource[0] = new MatTableDataSource(dataSource);
    this.dataSource[0].sort = this.sort;

    //console.log("koniec vypoctu dataSorce[0]");
    //console.log("hourlyConsumption :", this.hourlyConsumption);
    //console.log("hourlyPrices :", this.hourlyPrices);
    //console.log("hourlyCalculatedPrice :", this.hourlyCalculatedPrice);
    //console.log("dataSource :", this.dataSource);
    
    if ( location ){
      this.updateTableData(location);
      this.tableIndex = location.table;
    } else{
      this.updateTableData(this.businessUnitsLocations[0]);
      this.tableIndex = this.businessUnitsLocations[0].table ? this.businessUnitsLocations[0].table : 0;
    }
  }

  getLocation(id: number): BusinessUnitLocation{
    let locationId = this.businessUnitsLocations.findIndex( (location: any) => { return location.id === id });
    return this.businessUnitsLocations[locationId];
  }

  getLocationByTableIndex(tabIndex: number): BusinessUnitLocation{
    let locationId = this.businessUnitsLocations.findIndex( (location: any) => { return location.table === tabIndex });
    return this.businessUnitsLocations[locationId];
  }

  setBusinessUnit() {
    //console.log("this.selectedBusinessUnit", this.selectedBusinessUnit);
    this.isLoading = true;
    this.setInitialValues();
    this.getData(null);
  }

  setLocation() {
    this.isLoading = true;
  
    if ( this.selectedIdentifier ){
      this.tableIndex = this.selectedIdentifier.table ? this.selectedIdentifier.table : 0;
    }else{
      console.log("this.selectedIdentifier je nedefinovany");
    }
    //console.log("selectedIdentifier :", this.selectedIdentifier);
    this.updateTableData(this.selectedIdentifier);
    this.updateChartOption();
    this.isLoading = false;
  }

  onLocationChange() {
    this.selectedIdentifier = this.getLocationByTableIndex(this.tableIndex);
    this.updateTableData(this.selectedIdentifier);
  }

  updateTableData(location: BusinessUnitLocation) {
    this.totalLocationConsumption     = 0;
    this.totalLocationPrice           = 0;
    this.totalLocationCalculatedPrice = 0;
  
    //console.log("location :", location);

    if ( location ){
      let index = location.table ? location.table : 0;

      //console.log ("index :", index, typeof(index));
      //console.log("dataSource :", this.dataSource);
      
      if ( this.dataSource[index] ){
        this.totalLocationConsumption     = this.dataSource[index]._data._value.reduce((acc: any, curr: any) => acc + parseFloat(curr.consumption), 0);
        this.totalLocationPrice           = this.dataSource[index]._data._value.reduce((acc: any, curr: any) => acc + parseFloat(curr.actualPrice), 0);
        this.totalLocationCalculatedPrice = this.dataSource[index]._data._value.reduce((acc: any, curr: any) => acc + parseFloat(curr.calculatedPrice), 0);
      }else{
        console.log("dataSource[" + index + "] je nedefinovany");
      }
    }
  }
  
  formatDate(dateFrom: any) {
    return this.getFormattedDateWithSuffix(dateFrom);  // Format date
  }

  getFormattedDateWithSuffix(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = this.getDaySuffix(day);
    const monthYear = this.datePipe.transform(date, 'MMM yyyy');
    return `${this.padZero(day)}${suffix} ${monthYear}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  padZero(day: number): string {
    return day < 10 ? '0' + day : day.toString();
  }

  getHourName(hour: number) {
    let hourName;
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00 hour`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00 hour`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00 hour`;
    }
    return `${hourName}`;
  }

  getHourNameForTable(hour: number) {
    let hourName;
    let date = this.dateFrom.split("-").reverse().join('.')
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00`;
    }
    return `${date} ${hourName}`;
  }

  processPrices(data: any) {
    data.forEach((price: any, index: number) => {
      let priceValue = parseFloat(price.price) / 100000;
      this.hourlyPrices[index] = priceValue;
    });
    this.hourlyPrices = this.hourlyPrices.map(value => parseFloat(value.toFixed(2)));
  }

  capitalizeFirstLetter(string: string) {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  }

  updateChartOption() {
    this.totalPrice = 0;
    this.highestPrice = {
      hour: 0,
      price: 0
    };

    for (let i = 0; i < this.hourlyPrices.length; i++) {
      this.totalPrice += this.hourlyPrices[i];
      if (this.hourlyPrices[i] > this.highestPrice.price) {
        this.highestPrice.price = this.hourlyPrices[i];
        this.highestPrice.hour = i;
      }
    }

    //console.log("this.totalWeekConsumption", this.totalWeekConsumption);
    //this.totalConsumption = this.totalWeekConsumption.reduce((acc:any, curr:any) => acc + curr, 0);
    //this.totalCalculatedPrice = this.hourlyCalculatedPrice.reduce((acc, curr) => acc + curr, 0);
    this.totalConsumption     = this.hourlyConsumption[this.tableIndex].reduce((acc: any, curr: any) => acc + curr, 0);
    this.totalCalculatedPrice = this.hourlyCalculatedPrice[this.tableIndex].reduce((acc: any, curr: any) => acc + curr, 0);

    //console.log("xAxisLabels :", this.xAxisLabels);
    //console.log("this.totalConsumption :",this.totalConsumption);
    //console.log("this.totalCalculatedPrice :", this.totalCalculatedPrice);
    //console.log("this.hourlyCalculatedPrice :", this.hourlyCalculatedPrice);

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Consumption', 'Actual Price', 'Consumption Cost'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        // {
        //   show: true,
        //   type: 'slider',
        //   top: '90%',
        //   start: 0,
        //   end: 100,
        // },
      ],
      xAxis: {
        name: '',
        type: 'category',
        data: this.xAxisLabels,
        axisLabel: {
          formatter: (value: string) => value,
          interval: 23, // Show label every 24 hours
          rotate: 35
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Consumption (kWh)',
          position: 'left',
        },
        {
          type: 'value',
          name: 'EUR / kWh',
          position: 'right',
          axisLine: {
            lineStyle: { color: '#0083cf' },
          },
          axisLabel: {
            formatter: '{value} €',
          },
        },
      ],
      series: [
        {
          name: 'Consumption Cost',
          type: 'line',
          data: this.hourlyCalculatedPrice[this.tableIndex],
          lineStyle: { color: '#00bf63', width: 2 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
        {
          name: 'Consumption',
          type: 'line',
          data: this.hourlyConsumption[this.tableIndex],
          lineStyle: { color: '#000000', width: 2 },
          itemStyle: { color: '#000000', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 0, // Linked to the first y-axis
        },
        {
          name: 'Actual Price',
          type: 'line',
          data: this.hourlyPrices,
          lineStyle: { color: '#777777', width: 2 },
          itemStyle: { color: '#777777' },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
      ],
    };
  }

  updateChartOptionOld() {
    // Parse the dateFrom string to create a Date object
    let startDate = new Date(this.dateFrom);
    let endDate = new Date(this.dateUntil)
    // Ensure the start date is set to exactly midnight (00:00:00)
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    this.totalPrice = 0;
    this.highestPrice = {
      hour: 0,
      price: 0
    };

    for (let i = 0; i < this.hourlyPrices.length; i++) {
      this.totalPrice += this.hourlyPrices[i];
      if (this.hourlyPrices[i] > this.highestPrice.price) {
        this.highestPrice.price = this.hourlyPrices[i];
        // this.highestPrice.hour = i;
      }
    }
    this.totalConsumption = this.totalWeekConsumption.reduce((acc: any, curr: any) => acc + curr, 0);
    this.totalCalculatedPrice = this.hourlyCalculatedPrice.reduce((acc, curr) => acc + curr, 0);

    // Generate x-axis labels for 168 hours
    const xAxisLabels = [];
    //console.log("endDate :", endDate);
    for (let i = 0; i < endDate.getDate() * 24; i++) {
      const currentDate = new Date(startDate.getTime() + i * 3600000); // Add hours
      const formattedDate = this.formatDatePlot(currentDate);
      xAxisLabels.push(formattedDate);
    }

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Consumption', 'Actual Price', 'Consumption Cost'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        // {
        //   show: true,
        //   type: 'slider',
        //   top: '90%',
        //   start: 0,
        //   end: 100,
        // },
      ],
      xAxis: {
        name: '',
        type: 'category',
        data: xAxisLabels,
        axisLabel: {
          formatter: (value: string) => value,
          interval: 23, // Show label every 24 hours
          rotate: 35,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Consumption (kWh)',
          position: 'left',
        },
        {
          type: 'value',
          name: 'EUR / kWh',
          position: 'right',
          axisLine: {
            lineStyle: { color: '#0083cf' },
          },
          axisLabel: {
            formatter: '{value} €',
          },
        },
      ],
      series: [
        {
          name: 'Consumption Cost',
          type: 'line',
          data: this.hourlyCalculatedPrice,
          lineStyle: { color: '#00bf63', width: 2 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
        {
          name: 'Consumption',
          type: 'line',
          data: this.totalWeekConsumption,
          lineStyle: { color: '#000000', width: 2 },
          itemStyle: { color: '#000000', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 0, // Linked to the first y-axis
        },
        {
          name: 'Actual Price',
          type: 'line',
          data: this.hourlyPrices,
          lineStyle: { color: '#777777', width: 2 },
          itemStyle: { color: '#777777' },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
      ],
    };

  }

  // Helper function to format date
  private formatDatePlot(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  formatNumber(value: any) {
    let roundedValue = value.toFixed(2).replace('.', '.');
    return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  openExportDailog() {
    this.dialog.open(this.dialogTemplate2);
  }
  
  exportPlans() {
    let payload: any = {
      dateFrom: this.dateFrom,
      dateUntil: this.dateUntil,
      format: this.exportType
    };
    let requestOptions = {
      responseType: 'blob' as 'json',
    };
    this.happenedService.exportData(payload, requestOptions).subscribe(
    {  next: (response: Blob) => {
        this.dialog.closeAll();
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Happened Last Week.${this.exportType}`;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
        this.exportType = '';
      },
      error: (error:any ) => {
        console.error('Error exporting Happened Last Month:', error);
        this.translate.get('happenedMonth.validator.toastr.error').subscribe((data1: string) => {
          this.translate.get('happenedMonth.validator.toastr.error4').subscribe({ 
            next: (data2: string) => {
              this.toastr.error(data2,data1);
            }
          });      
        });      
      }
    });
  }
}
