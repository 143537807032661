<app-header *ngIf="showHeaderBar"></app-header>
<div>
  <!-- Tu je hlavicka komponent -->

  <!-- Tu su vsetky hlasky aplikacie  -->
  <app-messages></app-messages>

  <!-- Navigacia aplikacie po prihlaseni  -->
  <!-- asi uz nepotrebne  -->

  <!-- 
    <h2 *ngIf="!isLoggedIn">
        Neprihlaseny a prihlas sa <a routerLink="/login" routerLinkActive="router-link-active"> login </a>

    </h2>
-->

  <!-- Samotna aplikacia   -->
  <router-outlet></router-outlet>

  <!-- Tu je peticka komponent -->
  <app-footer></app-footer>
</div>
