import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdminService } from 'src/app/admin/admin.service';
import { MasterClient } from 'src/app/admin/client';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { UserService } from '../user.service';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { jsDocComment } from '@angular/compiler';
import { HeaderComponent } from '../../header.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})

export class SignUpComponent {
  @ViewChild('form') form: FormGroup;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  possibleRegions: string[] = ['Slovakia', 'Czech Republic', 'Hungary'];
  newMasterClient: any;
  submitted = false;
  newClientMenuOpen: boolean = true;
  loadingFinished: boolean = true;
  clientName: boolean = false;
  clientAddress: boolean = false;
  clientRegion: boolean = false;
  clientPhone: boolean = false;
  clientWebpage: boolean = false;
  termsCondition: boolean = false;
  emailPattern = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  captchaResponse!: string;
  passwordStrength = '';
  emailTakenError = false;
  emailError: string = '';

  text = '';
  showMessage: boolean = false;
  radioBtnValue:string

  public content: string = 'contentToConvert';

  registered:any;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private elementRef: ElementRef, private headerComponent: HeaderComponent, private translate: TranslateService
  ) {
    }

  ngOnInit() {

    this.registered = this.userService.getRegistered();

    if ( this.registered ){ this.routeChecker('specific-conditions')};

    this.newMasterClient = {
      clientshortName: '',
      clientOfficialName: '',
      clientOfficialAddress: '',
      countryOfRegisteredSeat: '',
      webpage: '',
      AvgElectricity: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      status: '',
      clientAgreement: false,
      specificConditions: false,
    };
  }

  ngAfterViewInit() {
  }
  
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  createClientMasterUser() {
    this.checkEmail();

    if (this.emailTakenError) {

      this.translate.get('signup.validator.toastrErrorEmail').subscribe((data1: string) => {
        this.translate.get('signup.validator.toastrError3').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });
      });

      return;
    }

    //for (const field in this.form.controls) {
    //  console.log(field, this.form.controls[field].valid);
    //  console.log(this.form.controls[field].getError.toString());
    //}

    if (!this.form.valid) {
      this.translate.get('signup.validator.toastrError').subscribe((data1: string) => {
        this.translate.get('signup.validator.toastrError3').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });
      });

      return;
    }

    this.termsCondition = !this.radioBtnValue ? true : false;
    if (this.termsCondition) return;
    this.newMasterClient.clientAgreement = this.radioBtnValue == "standard" ? true : false
    this.newMasterClient.specificConditions = this.radioBtnValue == "specific" ? true : false

    if (this.newMasterClient.password !== this.newMasterClient.confirmPassword){
      this.translate.get('signup.validator.toastrErrorCoPassword').subscribe((data1: string) => {
        this.translate.get('signup.validator.toastrError3').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });
      });

      return;
    }

    if (!this.captchaResponse) {
      this.translate.get('signup.validator.toastrErrorCaptcha').subscribe((data1: string) => {
        this.translate.get('signup.validator.toastrError3').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });
      });

      return;
    }

    let registerpayload = {
      id: null,
      login: this.newMasterClient.email,
      email: this.newMasterClient.email,
      password: this.newMasterClient.password,
      shortName: this.newMasterClient.clientshortName,
      officialName: this.newMasterClient.clientOfficialName,
      officialAddress: this.newMasterClient.clientOfficialAddress,
      country: this.newMasterClient.countryOfRegisteredSeat,
      website: this.newMasterClient.webpage,
      avgConsumption: this.newMasterClient.AvgElectricity,
      phone: this.newMasterClient.phone,
      token: null,
      phoneNumber: this.newMasterClient.phone,
      fullname:
        this.newMasterClient.firstName + ' ' + this.newMasterClient.lastName,
      userFirstName: this.newMasterClient.firstName,
      userLastName: this.newMasterClient.lastName,
      role: 'master user',
      status: 'pending',
      created_at: null,
      updated_at: null,
    };
    this.userService.masterUserRegistration(registerpayload).subscribe(
      (data) => {
        if (data) {
          this.translate.get('signup.validator.toastrSuccess').subscribe((data1: string) => {
            this.translate.get('signup.validator.toastrSuccess2').subscribe((data2: string) => {
              this.toastr.success(data2,data1);
            });
          });

          if (this.newMasterClient.specificConditions) {
            localStorage.setItem('registered', JSON.stringify(data));
            this.routeChecker('specific-conditions')
          } else {
            this.showMessage = true;
          }
        }
      },
      (error) => {
        console.error(error);
        this.translate.get('signup.validator.toastrError2').subscribe((data1: string) => {
          this.translate.get('signup.validator.toastrError3').subscribe((data2: string) => {
            this.toastr.error(data2,data1);
          });
        });
      }
    );
  }

  checkEmail() {
    this.emailError="";

    let payload = {
      email: this.newMasterClient.email,
    };

    this.userService.checkEmailMasterClient(payload).subscribe((data) => {
      if (data.exists) {
        this.emailTakenError = true;
        this.emailError="signup.validator.toastrErrorEmail";
      } else{
        this.emailTakenError = false;
      }
    });
  }

  validateEmail() {
    const emailInput = this.newMasterClient.email;
    if (!emailInput) {
      this.emailError = "signup.validator.emailRequired";
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(emailInput)) {
        this.emailError = "signup.validator.emailValid";
      } else {
        this.emailError = ''; // Reset error message if email is valid
      }
    }
  }

  redirectToHome() {
    this.routeChecker('home')
  }

  handleSpace(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart || 0;

    if (cursorPosition === 0 && event.key === ' ') {
      event.preventDefault();
    }
  }

  space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  letterOnly(event: any) {
    var charCode = event.keyCode;
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      charCode === 8 ||
      charCode === 32 ||
      charCode === 63
    ) {
      return true;
    } else {
      return false;
    }
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  checkPasswordStrength(password: string): void {
    let strength = 'Weak';
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(
      password
    );
    const hasMixedCase =
      password !== password.toLowerCase() ||
      password !== password.toUpperCase();

    if (password.length >= 8 && (hasNumber || hasSpecialChar) && hasMixedCase) {
      strength = 'Strong';
    } else if (
      password.length >= 8 &&
      (hasNumber || hasSpecialChar || hasMixedCase)
    ) {
      strength = 'Moderate';
    }
    this.passwordStrength = strength;
  }

  public captureScreen() {
    let data = document.getElementById(this.content);
    if (data) {
      // Get the print button and hide it
      let printButton = data.querySelector('.button') as HTMLElement;
      if (printButton) {
        printButton.style.display = 'none';
      }

      html2canvas(data).then((canvas) => {
        // Few necessary setting options
        let imgWidth = 208;
        let pageHeight = 295;

        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

        let topMargin = 10; // Set the value of the top margin you want
        let bottomMargin = 15; // Set the value of the bottom margin you want
        let leftMargin = 30; // Set the value of the left margin you want
        let rightMargin = 30; // Set the value of the right margin you want

        let widthWithMargin = imgWidth - leftMargin - rightMargin;
        let heightWithMargin = pageHeight - topMargin - bottomMargin;

        pdf.addImage(
          contentDataURL,
          'PNG',
          leftMargin,
          topMargin,
          widthWithMargin,
          heightWithMargin
        );

        pdf.save('MyEnergy_TermsofService.pdf');

        // Show the print button again after generating the PDF
        if (printButton) {
          printButton.style.display = 'block';
        }
      });
    } else {
      console.error('Element with id "contentToConvert" not found');
    }
  }

  openDialog(event: any) {
    if (event.target.checked) this.dialog.open(this.dialogTemplate);
  }

  onCheckboxChange(option: string) {
    if (option === 'clientAgreement') {
      this.newMasterClient.specificConditions = false;
    } else if (option === 'specificConditions') {
      this.newMasterClient.clientAgreement = false;
    }
  }

  calcX(start: number, element: ElementRef| any): number{
    var x: number = start;
    x = x + element?.getBoundingClientRect().x;

    //console.log(element, element?.getBoundingClientRect().x);

    if ( element?.offsetParent ){
      x = this.calcX(x, element.offsetParent);
    }

    return x;
  }

  shortNameInvalid: string = "";
  errSNX:  number = 0;
  errSNY:  number = 0;

  isShortNameValid(event: any): string{
    this.shortNameInvalid = "";
    this.errSNX = 0;
    this.errSNY = 0;

    var element = this.elementRef.nativeElement.querySelector("#clientshortName");

    if ( element?.offsetParent ){
      this.errSNX = element?.offsetParent.getBoundingClientRect().x;
      this.errSNY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errSNX = Math.abs(this.errSNX - element.getBoundingClientRect().x);
    this.errSNY = Math.abs(this.errSNY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 4 ){
      this.shortNameInvalid = "signup.validator.shortName";
    }

    if ( event.length > 6 ){
      this.shortNameInvalid = "signup.validator.shortName";
    }

    return this.shortNameInvalid;
  }

  officialNameInvalid: string = "";
  errONX:  number = 0;
  errONY:  number = 0;

  isOfficialNameValid(event: any): string{
    this.officialNameInvalid = "";
    this.errONX = 0;
    this.errONY = 0;

    var element = this.elementRef.nativeElement.querySelector("#clientofficialName");

    if ( element?.offsetParent ){
      this.errONX = element?.offsetParent.getBoundingClientRect().x;
      this.errONY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errONX = Math.abs(this.errONX - element.getBoundingClientRect().x);
    this.errONY = Math.abs(this.errONY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 3 ){
      this.officialNameInvalid = "signup.validator.officialName";
    }

    if ( event.length > 100 ){
      this.officialNameInvalid = "signup.validator.officialName";
    }

    return this.shortNameInvalid;
  }

  officialAddressInvalid: string = "";
  errOAX:  number = 0;
  errOAY:  number = 0;

  isOfficialAddressValid(event: any): string{
    this.officialAddressInvalid = "";
    this.errOAX = 0;
    this.errOAY = 0;

    var element = this.elementRef.nativeElement.querySelector("#clientOfficialAddress");

    if ( element?.offsetParent ){
      this.errOAX = element?.offsetParent.getBoundingClientRect().x;
      this.errOAY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errOAX = Math.abs(this.errOAX - element.getBoundingClientRect().x);
    this.errOAY = Math.abs(this.errOAY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 3 ){
      this.officialAddressInvalid = "signup.validator.officialAddress";
    }

    if ( event.length > 50 ){
      this.officialAddressInvalid = "signup.validator.officialAddress";
    }

    return this.officialAddressInvalid;
  }

  webpageInvalid: string = "";
  errWX:  number = 0;
  errWY:  number = 0;

  isWebpageValid(event: any): string{
    this.webpageInvalid = "";
    this.errWX = 0;
    this.errWY = 0;

    var element = this.elementRef.nativeElement.querySelector("#webpage");

    if ( element?.offsetParent ){
      this.errWX = element?.offsetParent.getBoundingClientRect().x;
      this.errWY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errWX = Math.abs(this.errWX - element.getBoundingClientRect().x);
    this.errWY = Math.abs(this.errWY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 3 ){
      this.webpageInvalid = "signup.validator.webpage"
    }

    if ( event.length > 100 ){
      this.webpageInvalid = "signup.validator.webpage"
    }

    return this.webpageInvalid;
  }

  fnInvalid: string = "";
  errFNX:  number = 0;
  errFNY:  number = 0;

  isFNValid(event: any): string{
    this.fnInvalid = "";
    this.errFNX = 0;
    this.errFNY = 0;

    var element = this.elementRef.nativeElement.querySelector("#firstName");

    if ( element?.offsetParent ){
      this.errFNX = element?.offsetParent.getBoundingClientRect().x;
      this.errFNY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errFNX = Math.abs(this.errFNX - element.getBoundingClientRect().x);
    this.errFNY = Math.abs(this.errFNY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 3 ){
      this.fnInvalid = "signup.validator.firstName";
    }

    if ( event.length > 50 ){
      this.fnInvalid = "signup.validator.firstName";
    }

    return this.fnInvalid;
  }

  lnInvalid: string = "";
  errLNX:  number = 0;
  errLNY:  number = 0;

  isLNValid(event: any): string{
    this.lnInvalid = "";
    this.errLNX = 0;
    this.errLNY = 0;

    var element = this.elementRef.nativeElement.querySelector("#lastName");

    if ( element?.offsetParent ){
      this.errLNX = element?.offsetParent.getBoundingClientRect().x;
      this.errLNY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errLNX = Math.abs(this.errLNX - element.getBoundingClientRect().x);
    this.errLNY = Math.abs(this.errLNY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 3 ){
      this.lnInvalid = "signup.validator.lastName";
    }

    if ( event.length > 100 ){
      this.lnInvalid = "signup.validator.lastName";
    }

    return this.lnInvalid;
  }

  phoneInvalid: string = "";
  errPhoneX:  number = 0;
  errPhoneY:  number = 0;
  isPhoneValid(event: any){
    this.phoneInvalid = "";
    this.errPhoneX = 0;
    this.errPhoneY = 0;

    this.form.controls['phone'].setErrors(null);
    var element = this.elementRef.nativeElement.querySelector("#phone");

    if ( element?.offsetParent ){
      this.errPhoneX = element?.offsetParent.getBoundingClientRect().x;
      this.errPhoneY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errPhoneX = Math.abs(this.errPhoneX - element.getBoundingClientRect().x);
    this.errPhoneY = Math.abs(this.errPhoneY - element.getBoundingClientRect().y) - 25;

    //console.log(event);
    if ( event.length < 3 ){
      this.phoneInvalid = "signup.validator.phone";
      this.form.controls['phone'].setErrors({'incorrect': true});
    }

    if ( event.length > 50 ){
      this.phoneInvalid = "signup.validator.phone";
      this.form.controls['phone'].setErrors({'incorrect': true});
    }

    if ( ! /^\+*[\d \-]*$/.test(event) ) {
      this.phoneInvalid = "signup.validator.phoneRegex";
      this.form.controls['phone'].setErrors({'incorrect': true});
    }

    return this.phoneInvalid;
  }

  errEmailX:  number = 0;
  errEmailY:  number = 0;
  isEmailValid(event: any){
    this.emailError = "";
    this.errEmailX = 0;
    this.errEmailY = 0;

    this.form.controls['email'].setErrors(null);
    var element = this.elementRef.nativeElement.querySelector("#email");

    if ( element?.offsetParent ){
      this.errEmailX = element?.offsetParent.getBoundingClientRect().x;
      this.errEmailY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errEmailX = Math.abs(this.errEmailX - element.getBoundingClientRect().x);
    this.errEmailY = Math.abs(this.errEmailY - element.getBoundingClientRect().y) - 25;

    //console.log(event);
    if ( event.length < 3 ){
      this.emailError = "signup.validator.email";
      this.form.controls['email'].setErrors({'incorrect': true});
    }

    if ( event.length > 100 ){
      this.emailError = "signup.validator.email";
      this.form.controls['email'].setErrors({'incorrect': true});
    }

    if ( ! /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(event) ) {
      this.emailError = "signup.validator.emailRegex";
      this.form.controls['email'].setErrors({'incorrect': true});
    }

    return this.emailError;
  }

  psswdInvalid: string = "";
  errPsswdX:  number = 0;
  errPsswdY:  number = 0;
  isPasswordValid(event: any){
    this.psswdInvalid = "";
    this.errPsswdX = 0;
    this.errPsswdY = 0;

    this.form.controls['password'].setErrors(null);
    var element = this.elementRef.nativeElement.querySelector("#password");

    if ( element?.offsetParent ){
      this.errPsswdX = element?.offsetParent.getBoundingClientRect().x;
      this.errPsswdY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errPsswdX = Math.abs(this.errPsswdX - element.getBoundingClientRect().x);
    this.errPsswdY = Math.abs(this.errPsswdY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 8 ){
      this.psswdInvalid = "signup.validator.password";
      this.form.controls['password'].setErrors({'incorrect': true});
      return;
    }

    if ( event.length > 100 ){
      this.psswdInvalid = "signup.validator.password";
      this.form.controls['password'].setErrors({'incorrect': true});
      return;
    }

    this.checkPasswordStrength(event);

    if ( this.passwordStrength !== 'Strong' && this.passwordStrength !== '' ){
      //console.log(this.passwordStrength);
      this.psswdInvalid = "signup.validator.passwordStrength";
      this.errPsswdY = this.errPsswdY - 25;
      this.form.controls['password'].setErrors({'incorrect': true});
    }

    return this.psswdInvalid;
  }

  confirmPsswdInvalid: string = "";
  errCPsswdX:  number = 0;
  errCPsswdY:  number = 0;
  isConfirmPasswordValid(event: any){
    this.confirmPsswdInvalid = "";
    this.errCPsswdX = 0;
    this.errCPsswdY = 0;
    this.form.controls['confirmPassword'].setErrors(null);

    var element = this.elementRef.nativeElement.querySelector("#confirmPassword");

    if ( element?.offsetParent ){
      this.errCPsswdX = element?.offsetParent.getBoundingClientRect().x;
      this.errCPsswdY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errCPsswdX = Math.abs(this.errCPsswdX - element.getBoundingClientRect().x);
    this.errCPsswdY = Math.abs(this.errCPsswdY - element.getBoundingClientRect().y) - 25;

    if ( this.newMasterClient.password != this.newMasterClient.confirmPassword ){
      this.confirmPsswdInvalid =  "signup.validator.coPassword";
      this.form.controls['confirmPassword'].setErrors({'incorrect': true});
    }

    return this.confirmPsswdInvalid;
  }
}
