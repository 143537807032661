import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HightLighterDirective } from './hight-lighter.directive';
import { AppStatusComponent } from './app-status.component';
import { MessagesComponent } from './messages.component';
import { ForecastModule } from '../forecast/forecast.module';
import { BackcastModule } from '../backcast/backcast.module';
import { AnalysisModule } from '../analysis/analysis.module';
import { NgxUplotModule } from 'ngx-uplot';
import { OrderModule } from '../order/order.module';
import { PlanModule } from '../plan/plan.module';
import { AdminModule } from '../admin/admin.module';

@NgModule({
  declarations: [HightLighterDirective, AppStatusComponent, MessagesComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    NgxUplotModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    HightLighterDirective,
    AppStatusComponent,
    MessagesComponent,
    ForecastModule,
    BackcastModule,
    AnalysisModule,
    OrderModule,
    PlanModule,
    AdminModule,
    NgxUplotModule,
  ],
})
export class SharedModule {}
