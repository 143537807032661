<title>Reset your password | MyEnergy</title>
<meta name="description" content="If you forgot or lost your password, you can change or reset it.">

<section class="custom_login_section">
  <div class="login_card_wrapper">
    <div class="custom_logo_section">
      <div class="logo-section">
        <img class="img-responsive" src="../../assets/logo/logo-green.png" alt="" />
      </div>
      <h3 class="logo_title">
        I Control <br />
        <span> MyEnergy </span>
      </h3>
    </div>

    <form (ngSubmit)="resetPassword()" #form="ngForm" ngNativeValidate >
      <div class="">
        <div class="row">
          <div class="col">
            <div class="form_title">
              <h5>Please setup your new password below:</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input type="password" class="form-control" placeholder="New password" minlength="8" maxlength="100"
                required="true" name="password" (keydown)="space($event)" [(ngModel)]="newClientUser.password"
                #password="ngModel" (input)="checkPasswordStrength(password.value)" />
            </div>
            <!-- <div class="validate_info" *ngIf="passwordStrength">
                *Password Strength: {{ passwordStrength }}
              </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <input type="password" class="form-control" placeholder="Confirm new password" minlength="8" maxlength="100"
              required="true" name="confirmPassword" (keydown)="space($event)"
              [(ngModel)]="newClientUser.confirmPassword" #confirmPassword="ngModel" />
          </div>
        </div>

        <div class="row">
          <div class="validate_info" *ngIf="passwordStrength !== 'Strong' && passwordStrength !== ''">
            {{'oneTimePassSetup.form.validationMessages.passwordStrength' | translate}}
          </div>
        </div>



        <div *ngIf="password.touched && confirmPassword.touched" style="display: flex; justify-content: center">
          <p class="validate_info" *ngIf="password.value != confirmPassword.value">
            {{'oneTimePassSetup.form.validationMessages.passwordMismatch' | translate}}
          </p>
        </div>

        <div class="row">
          <div class="col text-start">
            <button class="subBtn" type="submit">{{'registrationPage.submitButton' | translate}}</button>
          </div>
        </div>
        <div class="login_footer_wrapper">
          <p>
            {{'oneTimePassSetup.form.messages.forAnyQuery' | translate}}<a (click)="routeChecker('contactUs')"> {{'oneTimePassSetup.form.messages.expiredLink.contactUs' | translate}}</a> {{'oneTimePassSetup.form.messages.expiredLink.orUse' | translate}} <a  (click)="routeChecker('helpdesk')" class="helpdesk"> {{'oneTimePassSetup.form.messages.expiredLink.helpdesk' | translate}}.</a>
            <!-- <button (click)="signUp()">signup</button> -->
          </p>
        </div>
      </div>
    </form>

  </div>
</section>
